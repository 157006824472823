import React, { useEffect, useState } from 'react'
import { addRequirementComment, getAllComments } from '../../../../../../data/SelectionPortalRequests';
import MessageBubble from '../../requirement-manager/components/MessageBubble';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { useOutletContext } from 'react-router-dom';
import MainButton from '../../../../../../components/MainButton';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';

const ActivityWindow = () => {
    const [messages, setMessages] = useState<any[]>();
    const [newMessage, setNewMessage] = useState("");
    const email = useSelector((state: RootState) => state.user.email);
    const { selectionId } = useOutletContext<any>();

    const handleFetchMessages = async () => {
        const resp = await getAllComments(selectionId);
        console.log(resp)
        setMessages(resp.data.data);
    }
    
      const handleSendMessage = async (requirementId: string) => {
        console.log(requirementId)
        const response = await addRequirementComment(requirementId, email, newMessage);
        if (response.ok) {
            setNewMessage("");
            // setScroll_temporary(true);
            await handleFetchMessages();
        }
    }
    
    useEffect(() => {handleFetchMessages();}, []);
    
    return (
    <div className='w-full px-4 pb-6 overflow-y-auto space-y-4'>
        {(typeof messages === "undefined") ? (<LoadingSpinner />) : (
        <>
            {
                Object.keys(messages).map((requirementId: any, index: number) => {
                    const categories = JSON.parse(messages[requirementId][0].selectionRequirement.category);
                    let requirement = messages[requirementId][0].selectionRequirement.requirement;
                    if (requirement.slice(-1) == ",") { requirement = String(requirement).slice(0,-1)}

                    return (
                    <div className="flex flex-col px-5 pt-5 pb-2.5 mt-5 bg-white rounded-lg shadow max-md:max-w-full">
                        <div className="text-md font-normal tracking-tight leading-9 text-gray-900 border-b max-md:max-w-full">
                            <div className="w-full h-12 flex content-center w-full text-sm font-medium leading-5 text-gray-900 bg-white">
                                <div className="flex gap-5 rounded-md max-md:flex-wrap max-md:max-w-full">
                                    
                                    {
                                    (categories[0] != "") ? (
                                        <div
                                        className='flex items-center'>
                                        <div className="flex-auto">{categories[0]}</div>
                                        </div>
                                    ) : (<></>)
                                    }
                                    {
                                    (categories[1] != "" && categories[1] != null) ? (
                                        <div
                                        className='flex items-center'>
                                        <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                        <div className="flex-auto ml-4">{categories[1]}</div>
                                        </div>
                                    ) : (<></>)
                                    }
                                    {
                                    (categories[2] != "" && categories[2] != null) ? (
                                        <div
                                        className='flex items-center'>
                                        <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                        <div className="flex-auto ml-4">{categories[2]}</div>
                                        </div>
                                    ) : (<></>)
                                    }
                                </div>
                            </div>
                            <div className="text-xl font-bold line-clamp-2 tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                                {requirement} 
                            </div>
                        </div>
                        <div className="w-full relative gap-5 justify-between mt-5 max-md:flex-wrap max-md:max-w-full">
                        <div id="CommentWindow" className='flex flex-col h-full'>
                            <div className='grow py-12 w-full overflow-y-auto space-y-4 px-4'>
                                { 
                                messages[requirementId].map((message: any, index: number) => {
                                    return (
                                        <MessageBubble messageEmail={message.user.email} ownEmail={email} text={message.comment} date={message.updatedDate} />
                                    )
                                })
                                }
                            </div>
                            {/* <div ref={ref}></div> */}
                            <div className='flex items-center'>
                                <div className='p-4 w-10/12'>   
                                <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                                <div className="relative">
                                    <input 
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    onKeyDown={(e) => (e.key === 'Enter') ? (handleSendMessage(messages[requirementId][0].selectionRequirement.id)) : (null)}
                                    type="search" id="search" value={newMessage}
                                    className="block w-full p-4 text-sm text-gray-900 border border-gray-300 focus:border-gray-500 rounded-lg bg-gray-50" placeholder="Comment..." required />
                                    <button onClick={() => handleSendMessage(messages[requirementId][0].selectionRequirement.id)}
                                    type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2">Send</button>
                                </div>
                            </div>
                            <div className='grow'><MainButton text={'Open requirement'} icon={<ArrowRightIcon />} link={`/user-area/selection-portal/${selectionId}/requirement-manager?requirementId=${messages[requirementId][0].selectionRequirement.id}`} /></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    )
                })
            }
        </>    
        )}
    </div>
  )
}

export default ActivityWindow