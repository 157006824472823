import { useOutletContext } from "react-router-dom";
import SelectionDetails from "./features/SelectionDetails"
import SelectionUsersTable from "./features/SelectionUsersTable"
import { useEffect, useState } from "react";
import SelectionPhaseSettings from "./features/SelectionPhaseSettings";
import { getSelectionDetails } from "../../../../../data/SelectionPortalRequests";
import { LoadingSpinner } from "swap-frontend-library";
import SelectionRankingSettings from "./features/SelectionRankingSettings";
import SelectionDeletion from "./features/SelectionDeletion";

const Index = () => {
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  useEffect(() => {setSelectedSelectionPhase("");}, [])
  const [selection, setSelection] = useState<any>();

  const handleGetSelectionDetails = async () => {
    const response = await getSelectionDetails(selectionId)
    if (response.ok) {
        setSelection(response.data.data)
    }
  }

  useEffect(() => {
      handleGetSelectionDetails();
  }, [])

  return (
    <div className='p-4 h-full overflow-y-auto'>
        <div className="mb-4 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Selection settings
            </h1>
        </div>

        {
          typeof selection === "undefined" ? <LoadingSpinner /> : (
            <>
              <SelectionDetails selection={selection} handleGetSelectionDetails={handleGetSelectionDetails} />
              <SelectionPhaseSettings selection={selection} handleGetSelectionDetails={handleGetSelectionDetails} />
              <SelectionRankingSettings />
            </>
          )
        }

        <SelectionUsersTable />        
        
        {
          typeof selection === "undefined" ? <LoadingSpinner /> : (
            <>
              <SelectionDeletion selection={selection} />
            </>
          )
        }
    </div>
  )
}

export default Index