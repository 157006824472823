import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { deleteVendorDetails, fetchVendorData } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import DeleteModal from '../../../../../../components/DeleteModal';
import InfoIcon from '../../../../../../assets/img/icons/InfoIcon';

interface props {
    openAdd : React.Dispatch<React.SetStateAction<boolean>>;
}

const VendorDetailsTable = ({openAdd} : props) => {
    const { t } = useTranslation();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const [vendorData, setVendorData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const handleFetchVendorData = () => {
        setVendorData([]);
        fetchVendorData().then(data => {setVendorData(data.data); setFilteredData(data.data); setLoaded(true);});
    }

    useEffect(() => {
        handleFetchVendorData();
    }, [])

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalValidation, setDeleteModalValidation] = useState("");
    const [deleteModalValidationError, setDeleteModalValidationError] = useState(false);
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteVendorAccount = async (id: string) => {
        if (deleteModalValidation == deleteItem.company) {
            await deleteVendorDetails(id);
            handleFetchVendorData();
            setDeleteModalOpen(false);
            setDeleteItem(undefined);
        } else {
            setDeleteModalValidationError(true);
        }
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteVendorAccount(deleteItem.id)} content={
            <div>
                <p className='mb-3'>Soll der Anbieter {deleteItem.company} wirklich gelöscht werden?</p>
                <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                    <InfoIcon iconColor='currentColor' />
                    <div className='ml-3'>
                        <span className="font-medium">Achtung! </span>Damit werden auch zugehörige <span className="font-medium">Accounts</span>, <span className="font-medium">Invites</span> und <span className="font-medium">Leads</span> gelöscht.
                        Zugehörige <span className="font-medium">Softwareprodukte</span> müssen manuell gelöscht oder neu zugeordnet werden.
                    </div>
                </div>
                <p className='mb-3'>Im den Löschvorgang zu bestätigen, geben Sie unten den Anbieternamen ein ({deleteItem.company}).</p>
                <input placeholder={deleteItem.company} onChange={(event) => {setDeleteModalValidation(event.target.value)}} type="text" name="input" 
                className={`${(deleteModalValidationError) ? ("border-red-500") : ("border-secondary6")} mt-3 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} />
            </div>
        } /> : <></>}
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            {t("vendor")}
            <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length: vendorData.length, itemType: `${t("vendor")}`})}</p>
        </div>
        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    <SearchBar translatedPlaceholderItem={`${t("vendor")}`} originalData={vendorData} setFilteredData={setFilteredData} filterKey={"company"} />
                </div>
            
            <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
                <button
                    onClick={() => handleFetchVendorData()}
                    type="button"
                    data-refresh
                    className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                    >
                    <RefreshIcon iconColor='white' />
                    {t("refresh")}
                </button>
                <button
                    onClick={() => openAdd((prev) : boolean => !prev)}
                    id="createProductButton"
                    className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                    type="button"
                    >
                    <PlusIcon iconColor='white' />
                    {t("add")}
                </button>
            </div>
            </div>
        </div>
        </div>
        {
            (!loaded) ? (<LoadingSpinner />) : (
                <>
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <TableHeader header={[`${t("vendorCompany")}`, `${t("companyUrl")}`, `${t("accounts")}`, `${t("softwareProducts")}`, `${t("leads")}`, `${t("createdOn")}`, ""]} />
                    </thead>
                    {
                        (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={6} className='h-16'>No vendor details available...</td></tr></tbody>) : (
                            filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, company, numUsers, numSoftwareProducts, numLeads, vendorUrl, createdDate} : any, index : number) => {
                                const date : Date = new Date(Date.parse(createdDate));
                                const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                                return (
                                    <tbody>
                                        <TableRow 
                                            rowItems={[index, company, vendorUrl, String(numUsers), String(numSoftwareProducts), String(numLeads), dateString]} 
                                            deleteHandler={() => handleOpenDeleteModal({id, company})}
                                        />
                                    </tbody>
                                )
                            })
                        )
                    }
                </table>
                {/* <NavPagination totalItems={1000} visibleRows={[0,10]} setVisibleRows={undefined} handleChange={() => {}} /> */}
                <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
                </>
            )
        }
    </div>
  )
}

export default VendorDetailsTable