import React, { useEffect, useState } from 'react'
import { ArrowRightIcon, CheckmarkIcon, CrossIcon, LoadingSpinner, MainButton, PlusIcon } from 'swap-frontend-library'
import AppointmentRow from './components/AppointmentRow'
import { getSelectionPresentations } from '../../../../../../../data/SelectionPortalRequests'
import AddPresentationModal from './components/AddPresentationModal'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/store'
import SelectionGuideInjector from '../../../components/SelectionGuideInjector'
import { useOutletContext } from 'react-router-dom'

const PresentationOverview = () => {
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const { selectionId } = useOutletContext<any>();
  const [ selectionPresentations, setSelectionPresentations ] = useState<any[]>();
  const [ addPresentationModalOpen, setAddPresentationModalOpen ] = useState(false);

  const handleGetSelectionPresentations = async () => {
    const response = await getSelectionPresentations(selectionId);
    if (response.ok) {
      setSelectionPresentations(response.data.data);
    }
  }
  
  useEffect(() => {
    handleGetSelectionPresentations();
  }, [])

  if (typeof selectionPresentations == "undefined") return <LoadingSpinner />

  return (
    <div className='flex flex-col gap-4 p-4 h-full overflow-y-auto'>
        {
          selectionPresentations.length > 0 ? (
            selectionPresentations.map((item:any, index:number) => (<AppointmentRow meetingItem={item} />))
          ) : (
            <div 
            className={`flex items-center justify-center cursor-pointer shadow h-32 py-8 mt-2 bg-white transitions-all duration-500 rounded-lg`}>
              No presentations scheduled yet.
            </div>
          )
        }

        {
          projectRole == "selection_admin" ? (
            <div 
            onClick={() => setAddPresentationModalOpen(true)}
            className={`flex items-center justify-center cursor-pointer shadow h-32 py-8 mt-2 bg-white opacity-50 hover:opacity-100 transitions-all duration-500 rounded-lg `}>
              <div className='text-gray-300 w-16 h-16 rotate-45'>
                <CrossIcon />
              </div>
            </div>
          ) : <></>
        }
        { addPresentationModalOpen ? <AddPresentationModal modalOpen={addPresentationModalOpen} setModalOpen={setAddPresentationModalOpen} selectionId={selectionId} handleGetSelectionPresentations={handleGetSelectionPresentations} /> : <></> }
        
        <div className='absolute right-5 bottom-5'>
          <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["pres-sche_pres", "pres-cond_pres", "pres-eval_pres"]} />
        </div>

    </div>
  )
}

export default PresentationOverview