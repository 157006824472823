import React from 'react'
import TransactionHistoryTable from './object-settings/tables/TransactionHistoryTable'

const SalesAdmin = () => {
  return (
	<div className='p-4'>
		<div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
            Sales
          </h1>
      </div>
		{/* <!-- Card header --> */}
		<TransactionHistoryTable />
	</div>
  )
}

export default SalesAdmin