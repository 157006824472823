import React, { useState } from 'react';
import Chart from "react-apexcharts";

interface props {
	title: string;
  }

const BigLineChartFilter = ({title} : props) => {
    const mainChartColors = {
        borderColor: '#F3F4F6',
        labelColor: '#6B7280',
        opacityFrom: 0.45,
        opacityTo: 0,
    };

    const [options, setOptions] = useState({
        chart: { toolbar: { show: false} },
		fill: {
			type: 'gradient',
			gradient: {
				enabled: true,
				opacityFrom: mainChartColors.opacityFrom,
				opacityTo: mainChartColors.opacityTo,
			},
		},
		dataLabels: {
			enabled: false,
		},
		tooltip: {
			style: {
				fontSize: '14px',
				fontFamily: 'Inter, sans-serif',
			},
		},
		grid: {
			show: true,
			borderColor: mainChartColors.borderColor,
			strokeDashArray: 1,
			padding: {
				left: 35,
				bottom: 15,
			},
		},
		markers: {
			size: 5,
			strokeColors: '#ffffff',
			hover: {
				size: undefined,
				sizeOffset: 3,
			},
		},
		xaxis: {
			categories: [
				'01 Feb',
				'02 Feb',
				'03 Feb',
				'04 Feb',
				'05 Feb',
				'06 Feb',
				'07 Feb',
			],
			labels: {
				style: {
					colors: [mainChartColors.labelColor],
					fontSize: '14px',
					fontWeight: 500,
				},
			},
			axisBorder: {
				color: mainChartColors.borderColor,
			},
			axisTicks: {
				color: mainChartColors.borderColor,
			},
			crosshairs: {
				show: true,
				position: 'back',
				stroke: {
					color: mainChartColors.borderColor,
					width: 1,
					dashArray: 10,
				},
			},
		},
		yaxis: {
			labels: {
				style: {
					colors: [mainChartColors.labelColor],
					fontSize: '14px',
					fontWeight: 500,
				},
				formatter(value : any) {
					return `$${value}`;
				},
			},
		},
		legend: {
			fontSize: '14px',
			fontWeight: 500,
			fontFamily: 'Inter, sans-serif',
			labels: {
				colors: [mainChartColors.labelColor],
			},
			itemMargin: {
				horizontal: 10,
			},
		},
		responsive: [
			{
				breakpoint: 1024,
				options: {
					xaxis: {
						labels: {
							show: false,
						},
					},
				},
			},
		],
    });
    const [series, setSeries] = useState([
        {
            name: 'Revenue',
            data: [6356, 6218, 6156, 6526, 6356, 6256, 6056],
            color: '#1A56DB',
        },
        {
            name: 'Revenue (previous period)',
            data: [6556, 6725, 6424, 6356, 6586, 6756, 6616],
            color: '#FDBA8C',
        },
    ]);

    return (
        <div
			className="p-4 bg-white border border-gray-200 rounded-lg shadow-md xl:col-span-2 sm:p-6"
		>
			<div className="flex items-center justify-between mb-4">
				<div className="flex-shrink-0">
					<span
						className="text-xl font-bold leading-none text-gray-900 sm:text-2xl"
						>$45,385</span
					>
					<h3 className="text-base font-light text-gray-500">
						{title}
					</h3>
				</div>
				<div
					className="flex items-center justify-end flex-1 text-base font-medium text-green-500"
				>
					12.5%
					<svg
						className="w-5 h-5"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
							clip-rule="evenodd"></path>
					</svg>
				</div>
			</div>

			{/* <!-- NOTE: Charts could be extracted to a generic UI component --> */}
			<div id="main-chart">
                <Chart 
                type='area'
                options={options}
                series={series}
            />
            </div>

			{/* <!-- Card Footer --> */}
			<div
				className="flex items-center justify-between pt-3 mt-4 border-t border-gray-200 sm:pt-6"
			>
				<div>
					<button
						className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900"
						type="button"
						data-dropdown-toggle="weekly-sales-dropdown"
						>Last 7 days <svg
							className="w-4 h-4 ml-2"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M19 9l-7 7-7-7"></path></svg
						></button
					>
					{/* <!-- Dropdown menu --> */}
					<div
						className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow"
						id="weekly-sales-dropdown"
					>
						<div className="px-4 py-3" role="none">
							<p
								className="text-sm font-medium text-gray-900 truncate"
								role="none"
							>
								Sep 16, 2021 - Sep 22, 2021
							</p>
						</div>
						<ul className="py-1" role="none">
							<li>
								<a
									href="#"
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem">Yesterday</a
								>
							</li>
							<li>
								<a
									href="#"
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem">Today</a
								>
							</li>
							<li>
								<a
									href="#"
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem">Last 7 days</a
								>
							</li>
							<li>
								<a
									href="#"
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem">Last 30 days</a
								>
							</li>
							<li>
								<a
									href="#"
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									role="menuitem">Last 90 days</a
								>
							</li>
						</ul>
						<div className="py-1" role="none">
							<a
								href="#"
								className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								role="menuitem">Custom...</a
							>
						</div>
					</div>
				</div>
				<div className="flex-shrink-0">
					<a
						href="#"
						className="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100"
					>
						Sales Report
						<svg
							className="w-4 h-4 ml-1"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							><path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M9 5l7 7-7 7"></path></svg
						>
					</a>
				</div>
			</div>
		</div>
  )
}

export default BigLineChartFilter;