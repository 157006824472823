import React, { useState } from 'react'
import SettingsFilledIcon from '../../../../../../../assets/img/icons/SettingsFilledIcon';
import CheckmarkIcon from '../../../../../../../assets/img/icons/CheckmarkIcon';
import WarningIcon from '../../../../../../../assets/img/icons/WarningIcon';
import { updateSelectionScenarioOrder } from '../../../../../../../data/SelectionPortalRequests';
import CrossIcon from '../../../../../../../assets/img/icons/CrossIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';

interface props {
    index: number
    scenario: any,
    handleOpenScenario: (id: string, title: string) => void,
    setModifyScenarioId: React.Dispatch<React.SetStateAction<string | undefined>>;
    handleFetchScenarios: () => void,
    isLastItem: boolean,
    setAddScenarioModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScenarioCard = ({index, scenario, handleOpenScenario, setModifyScenarioId, handleFetchScenarios, isLastItem, setAddScenarioModalOpen} : props) => {
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [dragOverActive, setDragOverActive] = useState(false);
    const [dragOverActiveLast, setDragOverActiveLast] = useState(false);

    // A: This scenario cards gets rearranged
    const dragStart = (e : any, id : string) => {
        console.log(id);
        e.dataTransfer.setData("id", id);
    }

    // B: Some other scenario card gets rearranged and dragged/dropped over this scenario card
    const enterDrop = (e : any, last?: boolean) => {
        e.preventDefault();
        if (last) { setDragOverActiveLast(true); return; }
        setDragOverActive(true);
    }
    
    const leaveDrop = (e : any, last?: boolean) => {
        e.preventDefault();
        if (last) { setDragOverActiveLast(false); return; }
        setDragOverActive(false);
    }
    
    const drop = async (e : any, last?: boolean) => {
        e.preventDefault();

        const id = e.dataTransfer.getData("id");
        setDragOverActive(false);
        setDragOverActiveLast(false);

        if (id != scenario.id) {
            let newCleanOrderNumber : number;
            if (last) {
                newCleanOrderNumber = scenario.cleanOrderNumber+1;
            } else {
                newCleanOrderNumber = scenario.cleanOrderNumber;
            }

            if ((await updateSelectionScenarioOrder(id, newCleanOrderNumber)).ok) {
                handleFetchScenarios();
            }
        }
        
    }

  return (
    <>

        <div draggable={projectRole == "selection_admin"}
        
        // A: Gets dragged
        onDragStart={(e) => dragStart(e, scenario.id)}
        
        // B: Is dropzone
        onDragOver={(e) => enterDrop(e)} 
        onDragLeave={(e) => leaveDrop(e)} 
        onDrop={(e) => drop(e)}
        onClick={() => handleOpenScenario(scenario.id, `Scenario ${index+1} - ${scenario.title}`)}
        className={`${dragOverActive ? "bg-gray-300 border-4 border-gray-100 border-dashed": ""} transition-[background-color] duration-500 relative flex flex-col h-64 items-center justify-center text-center rounded-lg max-md:max-w-full`}>
            <div
            className={`${dragOverActive ? "translate-x-20 translate-y-2 z-[100] shadow-xl": ""} transition-all duration-500 bg-white relative flex flex-col h-full w-full items-center justify-center text-center px-5 pt-5 pb-2.5 rounded-lg shadow max-md:max-w-full hover:bg-gray-100 cursor-pointer`}>
                <div className='font-light italic'>
                    Scenario {scenario.cleanOrderNumber}
                </div>
                <div className='font-semibold text-lg'>
                    {scenario.title}
                </div>
                <div className='font'>
                    {(scenario.scenarioTaskStatuses.completed||0)+(scenario.scenarioTaskStatuses.null||0)+(scenario.scenarioTaskStatuses.warning||0)} scenario tasks
                </div>

                <div className='absolute z-[2] right-5 bottom-3 flex items-center justify-center space-x-5'>
                    
                    <div className='group text-green-400 flex items-center justify-center'>
                        <div className='w-8 h-8 flex items-center'><CheckmarkIcon iconColor='currentColor' /></div>
                        <p className='w-2'>{scenario.scenarioTaskStatuses.completed || 0}</p>
                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                            Scenario was checked by user and is completed.
                        </div>
                    </div>
                    
                    <div className='group w-11 h-10 text-green-400 flex items-center justify-center'>
                        <div className='w-8 h-8 flex items-center'>(<CheckmarkIcon iconColor='currentColor' />)</div>
                        <p className='ml-1 w-2'>{scenario.scenarioTaskStatuses.null || 0}</p>
                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                            Scenario was created manually but not confirmed yet. Manual validation might be necessary.
                        </div>
                    </div>

                    <div className='group w-10 h-10 text-amber-400 flex items-center justify-center'>
                        <div className='w-8 h-8 flex items-center'><WarningIcon iconColor='currentColor' /></div>
                        <p className='w-2'>{scenario.scenarioTaskStatuses.warning || 0}</p>
                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                            A warning has been recognised or added manually. A manual review is necessary.
                        </div>
                    </div>

                    {
                        projectRole == "selection_admin" ? (
                            <div onClick={(e) => {e.stopPropagation(); setModifyScenarioId(scenario.id);}}
                            className='w-8 h-8 text-gray-300 hover:bg-white rounded-full p-1'>
                                <SettingsFilledIcon iconColor='currentColor'/>
                            </div>
                        ) : <></>
                    }
                </div>
            </div>
        </div>
        
        {
            (isLastItem && dragOverActiveLast) ? 
                (
                    <div 
                    onDragOver={(e) => enterDrop(e, true)} 
                    onDragLeave={(e) => leaveDrop(e, true)} 
                    onDrop={(e) => drop(e, true)}
                    className={`bg-gray-300 border-4 border-gray-100 border-dashed h-64 rounded-lg max-md:max-w-full transition-[background-color] duration-500`}>
                    </div>
                ): <></>
        }

        {
            isLastItem && projectRole == "selection_admin" ? 
                (
                    <div onClick={() => setAddScenarioModalOpen(true)}
                    className="relative flex flex-col h-64 items-center justify-center text-center px-5 pt-5 pb-2.5 bg-gray-100 rounded-lg shadow max-md:max-w-full hover:bg-gray-200 cursor-pointer">
                        <div className='text-gray-300 w-16 h-16 rotate-45'>
                            <CrossIcon iconColor='currentColor' />
                        </div>

                        <div
                            onDragOver={(e) => enterDrop(e, true)} 
                            onDragLeave={(e) => leaveDrop(e, true)} 
                            onDrop={(e) => drop(e, true)}
                            className={`absolute z-2 top-0 -left-10 w-16 h-64`}>
                        </div>
                    </div>
                ): <></>
        }
        
    </>
  )
}

export default ScenarioCard