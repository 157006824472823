import React, { useEffect, useState } from 'react'
import { getSelectionLogo } from '../../../../../data/SelectionPortalRequests';

const SelectionLogo = ({selection} : {selection: any}) => {
    const [imageSource, setImageSource] = useState<string | undefined>("");
    
  const handleFetchImage = async () => {
      getSelectionLogo(selection.id, undefined).then(frontendImageLocation => {
          setImageSource(frontendImageLocation);
      });
  }

  useEffect(() => {handleFetchImage()}, [])

  return (
      <>
        {
            typeof imageSource !== "undefined" ? <img className='h-auto' src={imageSource} /> :
            selection.customerSearchCustomerDetails != null ? (
                <>
                {
                selection.customerSearchCustomerDetails?.customerSearch.companyLogos.length > 0 ?
                <img className='h-auto' src={
                    selection.customerSearchCustomerDetails.customerSearch.companyLogos[0].startsWith("http") ?
                    selection.customerSearchCustomerDetails.customerSearch.companyLogos[0] : "https://"+selection.customerSearchCustomerDetails.customerSearch.companyLogos[0]
                } /> : <></>
                }
                </>
            ) : <div></div>
        }
      </>
  )
}

export default SelectionLogo