import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';
import { registerCustomerManager } from '../../../../../../data/UserCustomerRequests';

const AddVendorDetailsForm = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);

    const handleAddCustomerAccountManager = async () => {
        if ((await registerCustomerManager(email, password, firstname, lastname, companyName)).success) { setCreatedSuccessfully(true); }
        else {
            // TODO: Fehlerabfang und highlight der falschen felder
            console.log("Error: Creating customer accoung not successful.")
        }
    }

    const handleGeneratePassword = () => {
        const pw = Math.random().toString(36).substr(2, 8);
        setPassword(pw);
    }
   
    return (
    <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900">{t("createCustomerAccount")}</h3>

        {(createdSuccessfully) ? (<p>{t("createdSuccessfully")}</p>) : (
            <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-900">E-Mail</label>
                    <input placeholder="E-Mail" onChange={(event) => {setEmail(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("password")}</label>
                    <div className='flex w-full gap-4'>
                        <div className='w-full'>
                            <input placeholder={`${t("password")}`} onChange={(event) => {setPassword(event.target.value)}} type="text" name="input" value={password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                        </div>
                        <div>
                            <MainButton text='Generate' height='h-11' func={() => handleGeneratePassword()} />
                        </div>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("firstname")}</label>
                    <input placeholder={`${t("firstname")}`} onChange={(event) => {setFirstname(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("lastname")}</label>
                    <input placeholder={`${t("lastname")}`} onChange={(event) => {setLastname(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("companyName")}</label>
                    <input placeholder={`${t("companyName")}`} onChange={(event) => {setCompanyName(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                </div>
                <div className='w-full'><MainButton text={t("save")} link="" func={handleAddCustomerAccountManager} /></div>
            </div>
        )}
    </div>
  )
}

export default AddVendorDetailsForm