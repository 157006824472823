import React, { useEffect, useRef, useState } from 'react'
import TableHeader from '../../../../../components/TableHeader'
import TableRow from '../../../../../components/TableRow'
import SelectionRequirementsTable from './components/SelectionRequirementsTable'
import { Outlet, useLoaderData, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom'
import ArrowDownIcon from '../../../../../assets/img/icons/ArrowDownIcon'
import ArrowRightIcon from '../../../../../assets/img/icons/ArrowRightIcon'
import CrossIcon from '../../../../../assets/img/icons/CrossIcon'
import SelectionRequirementViewer from './components/SelectionRequirementViewer'
import { getSelectionRequirement } from '../../../../../data/SelectionPortalRequests'
import MainButton from '../../../../../components/MainButton'
import SearchBarFuzzy from '../../../../../components/SearchBarFuzzy'
import NextStepsWidget from '../selection-cockpit/features/NextStepsWidget'
import SelectionPhaseModal from '../selection-guide/SelectionPhaseModal'
import SelectionGuideInjector from '../components/SelectionGuideInjector'

const Index = () => {
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  const ref = useRef<any>();

  const [selectedTab, setSelectedTab] = useState("");
  const [queryRequirementId, setQueryRequirementId] = useState<string>();

  
  // 1. Necessary for linking to requirements (eg from activities)
  const [searchParams, setSearchParams] = useSearchParams();
  const handleGetSelectionRequirement = async (requirementId: string) => {
    const response = await getSelectionRequirement(requirementId);
    if (response.ok) {
      handleOpenRequirement(requirementId, response.data.data.requirement);
    }
  }

  useEffect(() => {
    const requirementId = searchParams.get("requirementId");

    if (requirementId) {
      setQueryRequirementId(requirementId);
    }

    setSelectedSelectionPhase("requirements");
  }, [])
  
  useEffect(() => {
    if (typeof queryRequirementId !== "undefined") {
      handleGetSelectionRequirement(queryRequirementId);
    }
  }, [queryRequirementId])
  // 1. until here

  const navigate = useNavigate();

  const [openRequirements, setOpenRequirements] = useState<any[]>([]);

  const handleOpenRequirement = (requirementId: string, requirementTitle: string) => {
    if (!openRequirements.map(item => item.id).includes(requirementId)) {
      setOpenRequirements(prev => [...prev, {title: requirementTitle, id: requirementId}]);
    }
    setSelectedTab(requirementId);
    navigate(`?requirementId=${requirementId}`);
  }
  
  const handleUpdateRequirementTab = (requirementId: string, requirementTitle: string) => {
    setOpenRequirements(openRequirements.map(item => {
      if (item.id == requirementId) return {id: item.id, title: requirementTitle};
      return {id: item.id, title: item.title};
    }));
  }
  
  const handleCloseRequirement = (requirementId: string) => {
    setOpenRequirements(prev => prev.filter(item => item.id != requirementId));
  }
  
  useEffect(() => {
    if (openRequirements.length == 0) {
      setSelectedTab("");
      navigate("");
    } else if (!openRequirements.map(item => item.id).includes(selectedTab)) {
      setSelectedTab("")
      navigate("")
    }
  }, [openRequirements]);
  
  useEffect(() => {
    if (typeof ref.current !== "undefined" && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedTab]);

  // console.log(selectedRequirementCategory1, selectedRequirementCategory2, selectedRequirementCategory3)

  return (
    <div className="relative flex flex-col pt-12 w-full h-full">
      
      {/* Breadcrumbs */}
      {/* <div className="w-full h-12 flex content-center w-full py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-5 px-3 rounded-md max-md:flex-wrap max-md:max-w-full">
          
          {
            (selectedRequirementCategory1 != "") ? (
              <div onClick={() => handleSetCategory1(selectedRequirementCategory1)}
              className='flex items-center cursor-pointer'>
                <div className="flex-auto">{selectedRequirementCategory1}</div>
              </div>
            ) : (<><div className="flex-auto">All categories</div></>)
          }
          {
            (selectedRequirementCategory2 != "") ? (
              <div onClick={() => handleSetCategory2(selectedRequirementCategory2)}
              className='flex items-center cursor-pointer'>
                <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                <div className="flex-auto ml-4">{selectedRequirementCategory2}</div>
              </div>
            ) : (<></>)
          }
          {
            (selectedRequirementCategory3 != "") ? (
              <div onClick={() => handleSetCategory3(selectedRequirementCategory3)}
              className='flex items-center cursor-pointer'>
                <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                <div className="flex-auto ml-4">{selectedRequirementCategory3}</div>
              </div>
            ) : (<></>)
          }
        </div>
      </div> */}

      {/* Tabs */}
      <div className="top-0 absolute flex h-12 w-full pr-5 pl-5 text-base font-semibold leading-6 text-text-gray-800 bg-white border-b border-solid">
        <ul className="h-full w-max pt-2 text-sm font-medium text-center text-gray-500">
          {/* <li
          className="justify-center cursor-pointer px-2.5 py-1 truncate"></li> */}
          <li 
          onClick={() => {setSelectedTab(""); navigate("")}}
          className="mr-2 flex items-end cursor-pointer">
              <p className={`inline-block h-full p-2 border-b-4 bg-gray-100 truncate w-32 rounded-t-lg ${(selectedTab == "") ? ("text-primary border-primary") : ("border-transparent hover:text-gray-600 hover:border-gray-300")}`}>
              {/* {(selectedRequirementCategory3) ? (selectedRequirementCategory3) : (selectedRequirementCategory2) ? (selectedRequirementCategory2) : (selectedRequirementCategory1) ? (selectedRequirementCategory1) : ("All categories")} */}
              Overview
              </p>
          </li>
        </ul>
        <ul className="flex overflow-x-auto w-full h-full pt-2 text-sm w-full font-medium text-center text-gray-500">
          {
            openRequirements.map((requirement: any, index: number) => {

              return (
                <li ref={(selectedTab == requirement.id) ? (ref) : (undefined)}
                onClick={() => {setSelectedTab(requirement.id); navigate(`?requirementId=${requirement.id}`);}}
                className={`flex items-end justify-between mr-2 p-2 border-b-4 ${(selectedTab == requirement.id) ? ("text-primary border-primary") : ("border-transparent hover:text-gray-600 hover:border-gray-300")} cursor-pointer`}>
                  <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                  {requirement.title}
                  </p>
                  <div onClick={(e) => {handleCloseRequirement(requirement.id); e.stopPropagation();}}
                  className='w-6 h-6 p-1 rounded-full hover:bg-gray-100'>
                    <CrossIcon iconColor='black' />
                  </div>
                </li>
              );
            }) 
          }
        </ul>
      </div>

      <div className='h-full w-full'>

        {
          (selectedTab == "") ? (
            <SelectionRequirementsTable selectedTab={selectedTab} selectionId={selectionId} handleOpenRequirement={handleOpenRequirement} />
          ) : (
            <SelectionRequirementViewer requirementId={selectedTab} selectionId={selectionId} handleOpenRequirement={handleOpenRequirement} handleCloseRequirement={handleCloseRequirement} handleUpdateRequirementTab={handleUpdateRequirementTab} />
          )
        }

      </div>
    </div>
  )
}

export default Index