import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SearchTable from './components/SearchTable';
import FormModal from '../../../../components/FormModal';
import StartSelectionForm from './components/StartSelectionForm';
import { getSelections } from '../../../../data/SelectionPortalRequests';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { InfoIcon, LoadingSpinner, MainButton, PlusIcon } from 'swap-frontend-library';
import SelectionLogo from './components/SelectionLogo';

const SelectionSelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startSelectionFormOpen, setStartSelectionFormOpen] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState<string | null>(null);

  const [selections, setSelections] = useState<any[]>();

  const email = useSelector((state: RootState) => state.user.email);

  const handleFetchSelections = async () => {
    const response = await getSelections(email);
    setSelections(response.data.data);
  }

  useEffect(() => {handleFetchSelections()}, [])

  return (
    <div className='p-4 pt-8 w-full h-full overflow-y-auto'>
      {(startSelectionFormOpen) ? (<FormModal isOpen={startSelectionFormOpen} setOpen={setStartSelectionFormOpen} size="regular"><StartSelectionForm customerSearchCustomerDetailsId={selectedSearch}/></FormModal>) : (<div className="hidden"></div>)}
        <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Software-Selection Projects
          </h1>
        </div>
        <div className='py-4 w-full grow'>
          {
            (typeof selections === "undefined") ? (<LoadingSpinner />) : (selections.length == 0) ? (
              <div className='w-full bg-white border rounded-lg h-64 flex flex-col items-center justify-center'>
                <div className='w-10 h-10 mb-4 text-gray-400'><InfoIcon /></div>
                <p className='text-gray-600'>No software selection project started yet. Carry out a software matching and then start a software selection project.</p>
                
                <div className='mt-8'>
                  <MainButton text={'Start new selection'} func={() => {setSelectedSearch(null); setStartSelectionFormOpen(true);}} icon={<div className='w-6 h-6 text-white'><PlusIcon /></div>} />
                </div>
              </div>
            ) : (
              <div className='grid grid-cols-3 gap-4 max-md:grid-cols-1'>
                {
                  selections.map((value:any, index:number) => {
                    const createdDateParsed : Date = new Date(Date.parse(value.createdDate));
                    const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;

                    return (
                      <>
                      <div onClick={() => {navigate(`/user-area/selection-portal/${value.id}`)}}
                      className="relative flex items-end h-64 w-full bg-white shadow-md cursor-pointer transition-all hover:scale-[1.02] rounded-lg">
                        <div className="p-5 text-lg font-semibold text-left text-gray-900 w-full h-64 truncate flex flex-col items-start justify-end">
                          <SelectionLogo selection={value} />
                          <div className='w-32'>
                            <p className='mt-4'>{value.selectionTitle || value.customerSearchCustomerDetails?.customerSearch.queryUrl ||  "Untitled selection"}</p>
                            <p className="mt-1 text-sm font-normal text-gray-500">
                              {createdDateString}
                            </p>
                          </div>
                        </div>
                      </div>
                      </>
                    )
                  })
                }

                <div onClick={() => {setSelectedSearch(null); setStartSelectionFormOpen(true);}}
                className="relative flex items-center justify-center h-64 w-full bg-white shadow-md cursor-pointer transition-all hover:scale-[1.02] rounded-lg">
                  <div className='w-32 h-32 text-gray-400'><PlusIcon /></div>
                </div>
              </div>
            ) 
          }
        </div>
        <div>
          <SearchTable setSelectedSearch={setSelectedSearch} setStartSelectionFormOpen={setStartSelectionFormOpen} />
        </div>

    </div>
  )
}

export default SelectionSelector