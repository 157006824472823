import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import TableHeader from '../../../../../components/TableHeader';
import TableRow from '../../../../../components/TableRow';
import { fetchCustomerData } from '../../../../../data/UserCustomerRequests';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../components/SearchBar';
import CustomerAccountsTable from './tables/CustomerAccountsTable';
import CustomerDetailsTable from './tables/CustomerDetailsTable';
import CustomerSelectionTable from './tables/CustomerAllSearchesTable';
import ClosedBetaInvitesTable from './tables/ClosedBetaInvitesTable';
import { FormModal } from 'swap-frontend-library';
import AddClosedBetaInviteForm from './forms/AddClosedBetaInviteForm';

interface Customers {
  company: string, 
  user: string,
  createdDate: string
}

const UsersAdmin = () => {
  const { t } = useTranslation();
  const [customerDetailsFormOpen, setCustomerDetailsFormOpen] = useState(false);
  const [customerAccountsFormOpen, setCustomerAccountsFormOpen] = useState(false);

  const [closedBetaInvitesFormOpen, setClosedBetaInvitesFormOpen] = useState(false);

  return (
    <div className='overflow-y-auto h-full'>
      {(closedBetaInvitesFormOpen) ? (<FormModal isOpen={closedBetaInvitesFormOpen} setOpen={setClosedBetaInvitesFormOpen} size="regular"><AddClosedBetaInviteForm /></FormModal>) : (<div className="hidden"></div>)}
      <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              {t("customers")}
          </h1>
      </div>
      <div>
        <CustomerDetailsTable openAdd={setCustomerDetailsFormOpen} />
        <CustomerAccountsTable />
        <CustomerSelectionTable />
        <ClosedBetaInvitesTable openAdd={setClosedBetaInvitesFormOpen} />
      </div>
    </div>
  )
}

export default UsersAdmin;