import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { extraAction } from "../extraAction";

export interface SocketState {
    websocketConnected: boolean;
}

const initialState: SocketState = {
    websocketConnected: true,
};

export const socketSlice = createSlice({
    name: "socket",
    initialState,
    reducers: {
        setWebsocketConnected: (state, connected: PayloadAction<boolean>) => {
            state.websocketConnected = connected.payload;
        }
    }
});

export const { setWebsocketConnected } = socketSlice.actions;

export default socketSlice;