import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { deleteVendorInvite, fetchVendorInviteData } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import DeleteModal from '../../../../../../components/DeleteModal';

interface props {
    openAdd : React.Dispatch<React.SetStateAction<boolean>>;
}

const VendorInvitesTable = ({openAdd} : props) => {
    const { t } = useTranslation();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const [vendorInviteData, setVendorInviteData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const handleFetchVendorInviteData = () => {
        setVendorInviteData([]);
        fetchVendorInviteData().then(data => {setVendorInviteData(data.data); setFilteredData(data.data); setLoaded(true);})
    }

    useEffect(() => {
        handleFetchVendorInviteData();
    }, [])

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteInvite = async (inviteId : string) => {
        await deleteVendorInvite(inviteId);
        handleFetchVendorInviteData();
        setDeleteModalOpen(false);
        setDeleteItem(undefined);
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteInvite(deleteItem.id)} content={
            <div>
                Soll der Invite für die E-Mail Adresse {deleteItem.email} wirklich gelöscht werden?
            </div>
        } /> : <></>}
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            {t("vendorInvite")}s
            <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length : vendorInviteData.length, itemType: `${t("vendorInvite")}s`})}</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    <SearchBar translatedPlaceholderItem={`${t("vendorInvite")}`} originalData={vendorInviteData} setFilteredData={setFilteredData} filterKey={"company"} />
                </div>
            
            <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
                <button
                onClick={() => handleFetchVendorInviteData()}
                type="button"
                data-refresh
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
                </button>
                <button
                onClick={() => openAdd((prev) : boolean => !prev)}
                id="createProductButton"
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                type="button"
                >
                <PlusIcon iconColor='white' />
                {t("add")}
                </button>
            </div>
            </div>
        </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <TableHeader header={[`${t("inviteCode")}`, `${t("registered")}`, `${t("vendorCompany")}`, "E-Mail", `${t("createdOn")}`, `${t("validUntil")}`, ""]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={6} className='h-16'>No invite codes available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, company, email, inviteCode, registered, createdDate, validUntilDate} : any, index : number) => {
                            const createdDateParsed : Date = new Date(Date.parse(createdDate));
                            const validUntilDateParsed : Date = new Date(Date.parse(validUntilDate));
                            const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
                            const validUntilDateString : string = `${validUntilDateParsed.getDate()}.${validUntilDateParsed.getMonth()+1}.${validUntilDateParsed.getFullYear()}`;
                            return (
                                <tbody key={index}>
                                    <TableRow 
                                        rowItems={[index, inviteCode, (registered == "1") ? ("Yes") : ("No"), company, email, createdDateString, validUntilDateString]}
                                        deleteHandler={() => {handleOpenDeleteModal({id, email})}}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

export default VendorInvitesTable