import React, { useEffect, useState } from 'react';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import ArrowLeftIcon from '../../../../../../assets/img/icons/ArrowLeftIcon';
import Calendar from '../../../../../../components/CalendarWidget';
import { getSelectionDetails } from '../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';

interface Phase {
  name: string;
  startDate: Date;
  endDate: Date;
}

const ProjectPhasesWidget = ({ phaseDates, noStyle } : { phaseDates?: any[], noStyle?: boolean }) => {
    const { selectionId } = useOutletContext<any>();
    const today = new Date();
    const [phases, setPhases] = useState<Phase[]>([]);
    
    const phaseColors = {'requirement': 'bg-lime-200', 'scenario': 'bg-amber-200', 'presentation': 'bg-cyan-200', 'contract': 'bg-violet-200'};

    const handleGetSelectionDetails = async () => {
        const response = await getSelectionDetails(selectionId)
        if (response.ok) {
            const projectPhases = response.data.data.projectPhases;
            const phasesParsedDates = Object.keys(projectPhases).map(phase => ({name: phase, startDate:new Date((new Date(projectPhases[phase].from)).setHours(0,0,0,0)), endDate:new Date((new Date(projectPhases[phase].to)).setHours(0,0,0,0))}))
            setPhases(phasesParsedDates);
        }
    }

    useEffect(() => {
        if (typeof phaseDates != "undefined") {
            setPhases(phaseDates);
        } else {
            handleGetSelectionDetails();
        }
    },[phaseDates])

    const isDateInPhase = (date: Date, phase: Phase): boolean => {
        return date >= phase.startDate && date <= phase.endDate;
    };

    const getPhaseForDate = (date: Date): Phase | undefined => {
        return phases.find(phase => isDateInPhase(date, phase));
    };

    const isStartOfPhase = (date: Date): boolean => {
        return phases.some(phase => phase.startDate.toDateString() === date.toDateString());
    };

    const isEndOfPhase = (date: Date): boolean => {
        return phases.some(phase => phase.endDate.toDateString() === date.toDateString());
    };

    return (
        <div className={`h-full w-full ${noStyle ? "" : "mx-auto bg-white rounded-lg shadow-sm px-8 py-8"}`}>
            {
                noStyle ? <></> : (
                <div className="flex gap-3.5 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div className="grow text-2xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                        Schedule
                    </div>
                    {/* <div className="my-auto text-base font-medium leading-6 text-blue-700 whitespace-nowrap">
                        View all
                    </div> */}
                </div>
                )
            }
            <Calendar phaseDates={phases} phaseColors={phaseColors} getPhaseForDate={getPhaseForDate} isStartOfPhase={isStartOfPhase} isEndOfPhase={isEndOfPhase} />

            {
                typeof phases !== "undefined" && typeof phaseColors !== "undefined" ? (
                    <div className="w-fit mx-auto">
                        <div className="grid grid-cols-2 gap-4">
                        {["Requirement", "Scenario", "Presentation", "Contract"].map((phaseName, index) => (
                            <div key={phaseName} className="flex items-center gap-4 w-1/2">
                                <div><div className={`w-4 h-4 ${phaseColors[phaseName.toLowerCase() as keyof typeof phaseColors]} rounded-full`}></div></div>
                                <span className='text-sm'>{phaseName} phase</span>
                            </div>
                        ))}
                        </div>
                    </div>
                ) : (<></>)
            }
        </div>
    );
};

export default ProjectPhasesWidget;
