import React from 'react'

interface props {
    items: any[],
    itemValueKey: string,
    itemLabelKey: string,
    selectedItem: any,
    setSelectedItem: React.Dispatch<React.SetStateAction<any>>,
    label?: string,
    placeholderLabel?: string
}

const SelectorDropdown = ({items, itemLabelKey, itemValueKey, selectedItem, setSelectedItem, label, placeholderLabel} : props) => {
  return (
    <div>
        {typeof label !== "undefined" ? (<label htmlFor="countries" className="block text-sm font-medium text-gray-900">{label}</label>) : (<></>) } 
        <select onChange={(event) => setSelectedItem(event.target.value)}
        id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
            <option value={""} selected disabled hidden>{placeholderLabel || "Choose here"}</option>
            {
                items.map((item : any, index : number) => {
                    return (<option selected={selectedItem == item[itemValueKey]} key={index} value={item[itemValueKey]}>{item[itemLabelKey]}</option>);
                })
            }
        </select>
    </div>
  )
}

export default SelectorDropdown