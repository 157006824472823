import React, { useEffect, useState } from 'react'
import ProjectPhasesWidget from '../../selection-cockpit/features/ProjectPhasesWidget'
import { MainButton } from 'swap-frontend-library';
import { setSelectionProjectPhases } from '../../../../../../data/SelectionPortalRequests';

const SelectionPhaseSettings = ({selection, handleGetSelectionDetails} : {selection:any, handleGetSelectionDetails:() => {}}) => {
    const [p1RequirementStart, setP1RequirementStart] = useState<string>("");
    const [p1RequirementEnd, setP1RequirementEnd] = useState<string>("");
    
    const [p2ScenariosStart, setP2ScenariosStart] = useState<string>("");
    const [p2ScenariosEnd, setP2ScenariosEnd] = useState<string>("");
    
    const [p3PresentationsStart, setP3PresentationsStart] = useState<string>("");
    const [p3PresentationsEnd, setP3PresentationsEnd] = useState<string>("");
    
    const [p4ContractsStart, setP4ContractsStart] = useState<string>("");
    const [p4ContractsEnd, setP4ContractsEnd] = useState<string>("");
    
    const [phaseDates, setPhaseDates] = useState<any[]>();
    
    const [error, setError] = useState<string>("");

    const handleFormatDate = (dateString : string) => {
        const date = new Date(dateString);
        return `${(date.getMonth()+1).toString().padStart(2,"0")}/${date.getDate().toString().padStart(2,"0")}/${date.getFullYear()}`;
    }

    useEffect(() => {
        if (selection.projectPhases == null) return

        setP1RequirementStart(handleFormatDate(selection.projectPhases.requirement.from));
        setP1RequirementEnd(handleFormatDate(selection.projectPhases.requirement.to));
        
        setP2ScenariosStart(handleFormatDate(selection.projectPhases.scenario.from));
        setP2ScenariosEnd(handleFormatDate(selection.projectPhases.scenario.to));
        
        setP3PresentationsStart(handleFormatDate(selection.projectPhases.presentation.from));
        setP3PresentationsEnd(handleFormatDate(selection.projectPhases.presentation.to));
        
        setP4ContractsStart(handleFormatDate(selection.projectPhases.contract.from));
        setP4ContractsEnd(handleFormatDate(selection.projectPhases.contract.to));

        const phasesParsedDates = Object.keys(selection.projectPhases).map(phase => ({name: phase, startDate:new Date((new Date(selection.projectPhases[phase].from)).setHours(0,0,0,0)), endDate:new Date((new Date(selection.projectPhases[phase].to)).setHours(0,0,0,0))}))
        setPhaseDates(phasesParsedDates)
    }, [selection])
    
    useEffect(() => {
        setError("");
    }, [p1RequirementStart, p1RequirementEnd, p2ScenariosStart, p2ScenariosEnd, p3PresentationsStart, p3PresentationsEnd, p4ContractsStart, p4ContractsEnd])
    
    const handleSetPhases = async () => {
        console.log("Requirements: ", p1RequirementStart, p1RequirementEnd)
        console.log("Scenarios: ", p2ScenariosStart, p2ScenariosEnd)
        console.log("Presentations: ", p3PresentationsStart, p3PresentationsEnd)
        console.log("Contracts: ", p4ContractsStart, p4ContractsEnd)
        
        if (
            p1RequirementStart == "" || p1RequirementEnd == "" || 
            p2ScenariosStart == "" || p2ScenariosEnd == "" || 
            p3PresentationsStart == "" || p3PresentationsEnd == "" || 
            p4ContractsStart == "" || p4ContractsEnd == ""
        ) return setError("Please fill out all dates.");

        const p1RequirementStartParsed = new Date(p1RequirementStart + " 12:00:00");
        const p1RequirementEndParsed = new Date(p1RequirementEnd + " 12:00:00");
        const p2ScenariosStartParsed = new Date(p2ScenariosStart + " 12:00:00");
        const p2ScenariosEndParsed = new Date(p2ScenariosEnd + " 12:00:00");
        const p3PresentationsStartParsed = new Date(p3PresentationsStart + " 12:00:00");
        const p3PresentationsEndParsed = new Date(p3PresentationsEnd + " 12:00:00");
        const p4ContractsStartParsed = new Date(p4ContractsStart + " 12:00:00");
        const p4ContractsEndParsed = new Date(p4ContractsEnd + " 12:00:00");

        console.log(p1RequirementStartParsed.getDate(), p1RequirementStartParsed, p1RequirementStartParsed.toISOString())

        if (isNaN(p1RequirementStartParsed.getTime())) return setError("p1s")
        if (isNaN(p1RequirementEndParsed.getTime())) return setError("p1e")
        if (isNaN(p2ScenariosStartParsed.getTime())) return setError("p2s")
        if (isNaN(p2ScenariosEndParsed.getTime())) return setError("p2e")
        if (isNaN(p3PresentationsStartParsed.getTime())) return setError("p3s")
        if (isNaN(p3PresentationsEndParsed.getTime())) return setError("p3e")
        if (isNaN(p4ContractsStartParsed.getTime())) return setError("p4s")
        if (isNaN(p4ContractsEndParsed.getTime())) return setError("p4e")

        const success = await setSelectionProjectPhases(selection.id, p1RequirementStartParsed, p1RequirementEndParsed, p2ScenariosStartParsed, p2ScenariosEndParsed, p3PresentationsStartParsed, p3PresentationsEndParsed, p4ContractsStartParsed, p4ContractsEndParsed);
        if (success) {
            handleGetSelectionDetails();
        }
    }

    const editingLocked = false;
    
    return (
    <div className="mt-4 shadow-md rounded-lg w-full bg-white p-5 flex flex-col gap-4">
        <div className="text-lg font-semibold text-left text-gray-900 bg-white">
            Selection phases
        </div>

        <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1 flex flex-col gap-4'>

                <div>
                    <p className='font-semibold'>1. Requirement phase <span className='text-sm font-light'>(MM/DD/YYYY)</span></p>
                    <div className='flex gap-4'>
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()}
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p1s" ? "border-red-400" : ""}`} 
                            value={p1RequirementStart != null ? p1RequirementStart : ""}
                            placeholder="Start date (MM/DD/YYYY)."
                            onChange={(e) => setP1RequirementStart(e.target.value)}
                        />
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p1e" ? "border-red-400" : ""}`} 
                            value={p1RequirementEnd != null ? p1RequirementEnd : ""}
                            placeholder="End date (MM/DD/YYYY)."
                            onChange={(e) => setP1RequirementEnd(e.target.value)}
                        />
                    </div>
                </div>
                
                <div>
                    <p className='font-semibold'>2. Scenario phase <span className='text-sm font-light'>(MM/DD/YYYY)</span></p>
                    <div className='flex gap-4'>
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p2s" ? "border-red-400" : ""}`} 
                            value={p2ScenariosStart != null ? p2ScenariosStart : ""}
                            placeholder="Start date (MM/DD/YYYY)."
                            onChange={(e) => setP2ScenariosStart(e.target.value)}
                        />
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p2e" ? "border-red-400" : ""}`} 
                            value={p2ScenariosEnd != null ? p2ScenariosEnd : ""}
                            placeholder="End date (MM/DD/YYYY)."
                            onChange={(e) => setP2ScenariosEnd(e.target.value)}
                        />
                    </div>
                </div>
                
                <div>
                    <p className='font-semibold'>3. Presentation phase <span className='text-sm font-light'>(MM/DD/YYYY)</span></p>
                    <div className='flex gap-4'>
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p3s" ? "border-red-400" : ""}`} 
                            value={p3PresentationsStart != null ? p3PresentationsStart : ""}
                            placeholder="Start date (MM/DD/YYYY)."
                            onChange={(e) => setP3PresentationsStart(e.target.value)}
                        />
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p3e" ? "border-red-400" : ""}`} 
                            value={p3PresentationsEnd != null ? p3PresentationsEnd : ""}
                            placeholder="End date (MM/DD/YYYY)."
                            onChange={(e) => setP3PresentationsEnd(e.target.value)}
                        />
                    </div>
                </div>
                
                <div>
                    <p className='font-semibold'>4. Contract phase <span className='text-sm font-light'>(MM/DD/YYYY)</span></p>
                    <div className='flex gap-4'>
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p4s" ? "border-red-400" : ""}`} 
                            value={p4ContractsStart != null ? p4ContractsStart : ""}
                            placeholder="Start date (MM/DD/YYYY)."
                            onChange={(e) => setP4ContractsStart(e.target.value)}
                        />
                        <input 
                            disabled={editingLocked} 
                            // onBlur={() => handleSetPhases()} 
                            className={`w-full bg-gray-100 p-2 border rounded tracking-tight leading-6 text-gray-900 ${error == "p4e" ? "border-red-400" : ""}`} 
                            value={p4ContractsEnd != null ? p4ContractsEnd : ""}
                            placeholder="End date (MM/DD/YYYY)."
                            onChange={(e) => setP4ContractsEnd(e.target.value)}
                        />
                    </div>
                </div>

                <MainButton text={'Save'} func={() => handleSetPhases()} />
                {
                    error != "" ? (
                        <div className='text-center text-sm font-light text-red-400'>
                            {
                                error.length > 3 ? error : "Please make sure that all dates follow the format MM/DD/YYYY."
                            }
                        </div>
                    ) : <></>
                }


            </div>
            <div className="col-span-1">
                <ProjectPhasesWidget phaseDates={phaseDates} noStyle={true} />
            </div>
        </div>

    </div>
  )
}

export default SelectionPhaseSettings