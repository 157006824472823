import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { useOutletContext } from 'react-router-dom';
import { addUserSelection, changeSelectionCustomGroup, changeSelectionEditingRights, changeSelectionParticipantGroup, getUsersSelection } from '../../../../../../data/SelectionPortalRequests';
import { LoadingSpinner, PlusIcon, RefreshIcon, TableHeader, TableRow, TextInput } from 'swap-frontend-library';

const SelectionUsersTable = () => {
    const {t} = useTranslation();
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const { selectionId } = useOutletContext<any>();
  const [userSelection, setUsersSelection] = useState<any[]>();
  const [openAdd, setOpenAdd] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserEmailError, setNewUserEmailError] = useState(false);

  const handleFetchUsersSelection = async () => {
    const data = await getUsersSelection(selectionId);
    console.log(data.data.data);
    setUsersSelection(data.data.data);
  }

  const handleOpenAddUser = () => {
    setNewUserEmail("");
    setNewUserEmailError(false);
    setOpenAdd(prev => !prev);
  }

  const handleSendInvitation = async () => {
    if (newUserEmail == "") { return; }
    const response = await addUserSelection(selectionId, newUserEmail)
    console.log(response)
    if (response.ok) {
      handleOpenAddUser();
      handleFetchUsersSelection();
    } else {
      setNewUserEmailError(true);
    }
  }
  
  const handleChangeEditingRights = async (email : string, newRole : string) => {
    const response = await changeSelectionEditingRights(selectionId, email, newRole)
    if (response.ok) {
      handleFetchUsersSelection();
    }
  }
  
  const handleChangeParticipantGroup = async (email : string, newGroup : string) => {
    const response = await changeSelectionParticipantGroup(selectionId, email, newGroup)
    if (response.ok) {
      handleFetchUsersSelection();
    }
  }

  useEffect(() => {
    handleFetchUsersSelection();
  }, [])

  return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
          {/* {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteVendorAccount(deleteItem.id)} content={
            <div>
              Soll der Account mit der E-Mail Adresse {deleteItem.email} wirklich gelöscht werden?
            </div>
          } /> : <></>} */}
          {
            (typeof userSelection !== "undefined") ? (
              <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                User in Selection
                <p className="mt-1 text-sm font-normal text-gray-500">Ingesamt sind {userSelection.length} User an der Selection beteiligt.</p>
              </div>
            ) : <></>
          }

          <div
              className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
          >
          <div className="w-full mb-1">
              <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                  {/* <SearchBar translatedPlaceholderItem={`${t("vendorAccount")}`} originalData={[]} setFilteredData={} filterKey={"email"} /> */}
                </div>
              
              <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
                  <button
                    onClick={() => handleFetchUsersSelection()}
                    type="button"
                    data-refresh
                    className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                  >
                    <div className='text-white w-4 h-4'><RefreshIcon /></div>
                    {t("refresh")}
                  </button>
                  
                  {
                    projectRole == "selection_admin" ? (
                      <>
                        {
                          (openAdd) ? (
                            <div className='relative'>
                              <label htmlFor="search" className="sr-only">Add user to selection</label>
                              <div className="relative h-full w-64">
                                  <input
                                  onChange={e => {setNewUserEmailError(false); setNewUserEmail(e.target.value);}}
                                  value={newUserEmail}
                                  type="text"
                                  id="search"
                                  className={`bg-gray-50 border ${newUserEmailError ? "border-red-300" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
                                  placeholder={`E-Mail`}
                                  />
                              </div>
                              <button onClick={handleSendInvitation}
                        type="submit" className="text-white absolute end-0.5 bottom-0.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2">Send</button>
                            </div>
                          ) : (<></>)
                        }
                        <button
                          onClick={handleOpenAddUser}
                          id="createProductButton"
                          className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                          type="button"
                          data-drawer-target="drawer-create-product-default"
                          data-drawer-show="drawer-create-product-default"
                          aria-controls="drawer-create-product-default"
                          data-drawer-placement="right"
                        >
                          {openAdd ? "Cancel" : <><div className='text-white w-4 h-4'><PlusIcon /></div>{t("add")}</>}
                        </button>
                      </>
                    ) : <></>
                  }

              </div>
              </div>
          </div>
        </div>

          {
            (typeof userSelection === "undefined") ? (<LoadingSpinner />) : (
              <table className="w-full text-sm text-left text-gray-500">
                  <TableHeader header={["Email", "Editing rights", "Participant role", "Custom group"]} />
                  {
                      userSelection.map((user: any, index: number) => {

                          return (
                              <tbody className="cursor-pointer">
                                  <TableRow rowItems={["", user.user.email, 
                                  <div className='font-bold'>
                                    <select 
                                      value={user.selectionRole}
                                      onChange={(event) => handleChangeEditingRights(user.user.email, event.target.value)}
                                      id="countries" className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${user.selectionRole == "selection_admin" ? "bg-red-100 border-red-300" : "bg-gray-50 border-gray-300"}`} style={{WebkitAppearance: "none"}}>
                                      <option value={"selection_admin"}>Admin</option>
                                      <option value={"selection_user"}>Read</option>
                                    </select>
                                  </div>,
                                  <div className='font-bold'>
                                    <select 
                                      value={user.selectionParticipantGroup}
                                      onChange={(event) => handleChangeParticipantGroup(user.user.email, event.target.value)}
                                      id="countries" className={`border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${user.selectionParticipantGroup == "consultant" ? "bg-blue-100 border-blue-300" : user.selectionParticipantGroup == "vendor" ? "bg-amber-100 border-amber-300" : "bg-gray-50 border-gray-300"}`} style={{WebkitAppearance: "none"}}>
                                      <option value={"seeker"}>Seeker</option>
                                      <option value={"consultant"}>Consultant</option>
                                      <option value={"vendor"}>Vendor</option>
                                    </select>
                                  </div>,
                                  <CustomRoleTextInput user={user} selectionId={selectionId} handleFetchUsersSelection={handleFetchUsersSelection} />
                                  ]} />
                              </tbody>
                          );
                      })
                  }
                </table>
            )
          }

    </div>
  )
}

const CustomRoleTextInput = ({user, selectionId, handleFetchUsersSelection} : {user:any, selectionId:string, handleFetchUsersSelection:any}) => {
  const [customGroup, setCustomGroup] = useState(user.selectionCustomGroup);
  
  const handleChangeCustomGroup = async () => {
    const response = await changeSelectionCustomGroup(selectionId, user.user.email, customGroup)
    if (response.ok) {
      handleFetchUsersSelection();
    }
  }

  return (
    <div className='font-bold'>
      <TextInput textValue={customGroup} setTextValue={setCustomGroup} onBlur={() => handleChangeCustomGroup()} />
    </div>
  )
}

export default SelectionUsersTable