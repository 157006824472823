import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../components/MainButton'
import { useLoaderData, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import TableHeader from '../../../../../components/TableHeader';
import TableRow from '../../../../../components/TableRow';

import "./Index.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { fetchSoftwareFulfillmentData, sendSoftwareFulfillmentData } from '../../../../../data/SoftwareFulfillmentRequests';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import HorizontalNavigationBalls from '../../../../../components/HorizontalNavigationBalls';
import FulfillmentSidebar from './components/FulfillmentSidebar';
import CompletionBar from './components/CompletionBar';
import FulfillmentTable from './components/FulfillmentTable';
import ArrowLeftIcon from '../../../../../assets/img/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../../../assets/img/icons/ArrowRightIcon';

export const loader = async ({ params } : any) : Promise<String> => {
    if (params.softwareFulfillmentId) {
        return params.softwareFulfillmentId;
    } else {
        return "";
    }
}

interface SoftwareProductShort {
    id: string,
    productName: string,
    vendorDetails: any,
    softwareFulfillment: any
}

interface props {
    softwareFulfillmentIdProp : string,
    exitAction: (() => void) | undefined,
}

const Index = ({softwareFulfillmentIdProp, exitAction} : props) => {
    const { t } = useTranslation();

    const location = useLocation();
    const userRole = useSelector((state: RootState) => state.user.role);

    const [softwareProduct, setSoftwareProduct] = useState<SoftwareProductShort>();
    const [softwareFulfillment, setSoftwareFulfillment] = useState<any[]>([]);
    const [filteredSoftwareFulfillment, setFilteredSoftwareFulfillment] = useState<any[]>([]);
    const [softwareFulfillmentVersion, setSoftwareFulfillmentVersion] = useState(0);

    const [uniqueRequirementCategories, setUniqueRequirementCategories] = useState<any>();
    const [visibleCategoryIndex, setVisibleCategoryIndex] = useState(0);
    const [visibleSubCategoryIndex, setVisibleSubCategoryIndex] = useState(0);

    const [topCategories, setTopCategories] = useState<any[]>([]);
    const [currentSubCategories, setCurrentSubCategories] = useState<any[]>([]);

    const [changedRequirementScores, setChangedRequirementScores] = useState<any>({});
    const [redirectLink, setRedirectLink] = useState<string>("/");

    const [totalCompletion, setTotalCompletion] = useState<any>();
    const [topCategoryCompletion, setTopCategoryCompletion] = useState<any>();
    const [subCategoryCompletion, setsubCategoryCompletion] = useState<any>();

    const softwareFulfillmentIdLoader : string = String(useLoaderData());
    let softwareFulfillmentId : string; 
    if (softwareFulfillmentIdProp != "") {
        softwareFulfillmentId = softwareFulfillmentIdProp;
    } else {
        softwareFulfillmentId = softwareFulfillmentIdLoader;
    }

    const filterSoftwareFulfillment = async () => {
        const filtered = softwareFulfillment.filter((obj : any) => {
            let parsedObject = JSON.parse(obj.requirement_category);
            let topCategoryVisible = topCategories[visibleCategoryIndex];
            let subCategoryVisible = currentSubCategories[visibleSubCategoryIndex];

            return parsedObject[0] === topCategoryVisible && parsedObject[1] === subCategoryVisible;
        })
        setFilteredSoftwareFulfillment([...filtered]);
    }

    const handleFetchSoftwareFulfillmentData = async (nextCategoryIdx:number) => {
        const data = await fetchSoftwareFulfillmentData(softwareFulfillmentId, userRole);

        setSoftwareProduct(data.product);
        setSoftwareFulfillment(data.fulfillment);
        setSoftwareFulfillmentVersion(data.fulfillment[0].softwareFulfillment_version)
        if (nextCategoryIdx == 0) {
            const res = data.requirement_categories.map((obj:string, idx:number) => (JSON.parse(obj))).reduce((r : any, a : any) => {
                r[a[0]] = r[a[0]] || {};
                // if (!Object.keys(r[a[0]]).includes(a[1])) {
                r[a[0]][a[1]] = r[a[0]][a[1]] || [];
                r[a[0]][a[1]].push(a.slice(2));
                // }
                return r;
            }, Object.create(null));
            setTopCategories(Object.keys(res));
            setCurrentSubCategories(Object.keys(res[Object.keys(res)[nextCategoryIdx]]));
            setUniqueRequirementCategories(res);
        } else {
            setCurrentSubCategories(Object.keys(uniqueRequirementCategories[topCategories[nextCategoryIdx]]));
        }
    }

    useEffect(() => {
        handleFetchSoftwareFulfillmentData(0);
        handleSetRedirectLink();
    }, [])

    useEffect(() => {filterSoftwareFulfillment(); calculateCompletion();}, [softwareFulfillment, visibleCategoryIndex, visibleSubCategoryIndex])

    const handleSendSoftwareFulfillmentData = async () => {
        sendSoftwareFulfillmentData(softwareFulfillmentId, changedRequirementScores, userRole);
    }

    const handlePageChange = async (next : boolean) => {
        await handleSendSoftwareFulfillmentData();
        setChangedRequirementScores({});

        let nextCategoryIdx = visibleCategoryIndex;
        if (next) {
            if (visibleSubCategoryIndex < currentSubCategories.length-1) {
                setVisibleSubCategoryIndex(prev => prev+=1);
            } else {
                nextCategoryIdx = visibleCategoryIndex+1;
                setVisibleCategoryIndex(prev => {if (prev < topCategories.length-1) { return prev + 1; } else { return prev; }});
                // setCurrentSubCategories(Object.keys(uniqueRequirementCategories[topCategories[visibleCategoryIndex+1]]));

                setVisibleSubCategoryIndex(0);
            }
        } else {
            if (visibleSubCategoryIndex > 0) {
                setVisibleSubCategoryIndex(prev => prev-=1);
            } else {
                // setCurrentSubCategories(Object.keys(uniqueRequirementCategories[topCategories[visibleCategoryIndex-1]]));
                nextCategoryIdx = visibleCategoryIndex-1;
                setVisibleCategoryIndex(prev => {if (prev > 0) {return prev -1;} else {return prev;}})
                setVisibleSubCategoryIndex(0);
            }
        }
        handleFetchSoftwareFulfillmentData(nextCategoryIdx);
    }

    const handlePageChangeCategory = async (categoryIdx: number) => {
        await handleSendSoftwareFulfillmentData();
        setChangedRequirementScores({});
        setVisibleCategoryIndex(categoryIdx);
        setVisibleSubCategoryIndex(0);
        handleFetchSoftwareFulfillmentData(categoryIdx);
    }
    
    const handlePageChangeSubCategory = async (subCategoryIdx: number) => {
        await handleSendSoftwareFulfillmentData();
        setChangedRequirementScores({});
        setVisibleSubCategoryIndex(subCategoryIdx);
        handleFetchSoftwareFulfillmentData(visibleCategoryIndex);
    }

    const handleSetRedirectLink = () => {
        if (userRole == "platform_manager") {
            setRedirectLink("/platform-management/admin-console/software");
        } else {
            const { redirectTo } = queryString.parse(location.search);
            if (typeof redirectTo === "undefined" || redirectTo == null || redirectTo == "") {
                if (typeof exitAction === "undefined") {
                    setRedirectLink("/user-area/settings/software");
                }
            } else {
                if (typeof redirectTo === "string") {
                    setRedirectLink(redirectTo);
                }
            }
        }
    }

    const calculateCompletion = async () => {
        if (softwareFulfillment !== undefined && softwareFulfillment.length > 0) {
            const totalCompletion = [softwareFulfillment.reduce((r : any, a : any) => {
                return r+(a.fulfillmentRequirement_score != -1);
            }, 0), softwareFulfillment.length]
            setTotalCompletion(totalCompletion);

            let subCategoryCompletion : any = {};
            currentSubCategories.forEach((obj:any, idx:number) => {
                const currentItems = softwareFulfillment.filter((fobj:any) => (JSON.parse(fobj.requirement_category)[0] == topCategories[visibleCategoryIndex] && JSON.parse(fobj.requirement_category)[1]) == obj);
                const currentSum = currentItems.reduce((r : any, a : any) => {
                    return r+(a.fulfillmentRequirement_score != -1);
                }, 0)
                subCategoryCompletion[obj] =  [currentSum, currentItems.length];
            });
            setsubCategoryCompletion(subCategoryCompletion);

            let topCategoryCompletion : any = {};
            topCategories.forEach((obj:any, idx:number) => {
                const currentItems = softwareFulfillment.filter((fobj:any) => (JSON.parse(fobj.requirement_category)[0]) == obj);
                const currentSum = currentItems.reduce((r : any, a : any) => {
                    return r+(a.fulfillmentRequirement_score != -1);
                }, 0)
                topCategoryCompletion[obj] =  [currentSum, currentItems.length];
            });
            setTopCategoryCompletion(topCategoryCompletion)
        }
    }

    return (
        <div className="relative pb-36 mt-32 max-lg:pb-24 max-sm:pb-[69px]">
        {(softwareProduct !== undefined && softwareProduct != null && filterSoftwareFulfillment !== undefined && uniqueRequirementCategories !== undefined && totalCompletion !== undefined && subCategoryCompletion !== undefined && topCategoryCompletion !== undefined) ? (
            <div className={`w-full mx-auto px-6 max-lg:px-5 max-sm:px-4 mt-10 max-sm:mt-8`}>

                <div className='text-center'>
                    <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                        {t("softwareFulfillment")}
                    </h1>
                    <div className="text-md text-sm font-light italic text-gray-900 bg-white">
                        {softwareProduct.vendorDetails.company} {softwareProduct.productName}
                    </div>
                    <div className="pl-1 text-sm font-light italic text-gray-900 bg-white">
                        (Requirement Fulfillment Version {String(softwareFulfillmentVersion)})
                    </div>
                </div>
                
                <CompletionBar totalCompletion={totalCompletion} />
                
                <div className='max-xl:w-full max-sm:overflow-x-hidden md:flex md:mt-16 md:pr-16'>
                    
                    <FulfillmentSidebar 
                        topCategories={topCategories}
                        topCategoryCompletion={topCategoryCompletion} 
                        handlePageChangeCategory={handlePageChangeCategory} 
                        visibleCategoryIndex={visibleCategoryIndex} 
                        currentSubCategories={currentSubCategories} 
                        subCategoryCompletion={subCategoryCompletion} 
                        handlePageChangeSubCategory={handlePageChangeSubCategory} 
                        visibleSubCategoryIndex={visibleSubCategoryIndex} />
                    
                    <div className='md:ml-16 md:w-5/6'>
                        
                        <FulfillmentTable 
                            topCategories={topCategories} 
                            visibleCategoryIndex={visibleCategoryIndex}
                            currentSubCategories={currentSubCategories} 
                            visibleSubCategoryIndex={visibleCategoryIndex} 
                            filteredSoftwareFulfillment={filteredSoftwareFulfillment} 
                            changedRequirementScores={changedRequirementScores} 
                            setChangedRequirementScores={setChangedRequirementScores} 
                        />

                        <div className="flex justify-between mt-10">

                            <MainButton text={t("previous")} icon={<ArrowLeftIcon iconColor='currentColor' />} text_location='right' link="" func={() => handlePageChange(false)} style='light' />

                            <div onClick={() => handlePageChange(true)}>
                                { (visibleCategoryIndex == topCategories.length-1 && visibleSubCategoryIndex == currentSubCategories.length-1) ?
                                    ( 
                                        (typeof exitAction === "undefined") ? (
                                            <MainButton text={`${t("finish")}`} text_location='left' icon={<ArrowRightIcon />} icon_color='white' link={redirectLink} />
                                        ) : (
                                            <div onClick={exitAction}>
                                                <MainButton text={`${t("finish")}`} text_location='left' icon={<ArrowRightIcon />} icon_color='white' link={""} /> 
                                            </div>
                                        )
                                    ) :
                                    (<MainButton text={`${t("next")}`} text_location='left' icon={<ArrowRightIcon />} icon_color='white' link={"#"} /> )
                                }
                            </div>

                        </div>
                    </div>
                </div>                
            </div>
        ) : (<LoadingSpinner />)}
    </div>
    )
}

export default Index