import React, { useEffect, useState } from 'react'
import FormModal from '../../../../../components/FormModal';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';

import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import PaymentCheckoutForm from '../../../../../components/PaymentCheckout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import MainButton from '../../../../../components/MainButton';
import { configStripe, createPaymentIntentLead } from '../../../../../data/PaymentRequests';
import { getVendorLeadData } from '../../../../../data/LeadRequests';
import ProductCard from '../../../../../components/ProductCard';

const PayForResultsModal = () => {
    const userEmail = useSelector((state : RootState) => state.user.email)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(true);
    useEffect(() => {if(!modalOpen) {navigate("/user-area/leads")}}, [modalOpen]);

    const [inputPaymentDataComplete, setInputPaymentDataComplete] = useState(false);
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [clientSecret, setClientSecret] = useState("");

    const [checkoutResult, setCheckoutResult] = useState<any>();
    const [delay, setDelay] = useState(false);

    const [handleFetchVendorLeads] = useOutletContext<any>();
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [leadId, setLeadId] = useState(searchParams.get("leadId"));
    const [leadPrice, setLeadPrice] = useState(0);

    useEffect(() => {
        configStripe().then(publishableKey => {
            if (typeof publishableKey !== "undefined") {
                setStripePromise(loadStripe(publishableKey));
            }
        })
    }, [])
    
    useEffect(() => {
        if (leadId != null) {
            createPaymentIntentLead(leadId).then(clientSecret => {
                if (typeof clientSecret !== "undefined") {
                    setClientSecret(clientSecret);
                    console.log(clientSecret)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (leadId != null) {
            getVendorLeadData(leadId).then(data => {
                console.log(data)
                setLeadPrice(data.leadPrice)});
        }
    }, [])

    const timeout = (delay: number) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    useEffect(() => {
        if (checkoutResult) {
            if (checkoutResult.paymentIntent && checkoutResult.paymentIntent.status === "succeeded") {

                // TODO Improve this shit
                setDelay(true);
                timeout(5000).then(() => {
                    navigate("/leads");
                    handleFetchVendorLeads();
                });
            }
        }
    }, [checkoutResult])
    
    return (
    <FormModal isOpen={true} setOpen={setModalOpen} size="regular">
        <div className='max-h-[75vh] overflow-y-auto'>
            <h3 className="mb-4 text-xl font-medium text-gray-900">{t("completeCheckout")}</h3>
            <div className='grid grid-cols-2 gap-4'>
                <div className='p-2 h-fit p-5'>
                    <ProductCard title={t("check.paid")} price={leadPrice} payments={t('oneTimePayment')} checks={[t("check.seeResults"), t("check.stayPrivate"), t("check.oneTimePayment")]}/>
                </div>
                <div className='p-2'>
                    {
                        (!inputPaymentDataComplete) ? (
                            <div className='flex flex-col p-2 gap-2'>
                                <h2 className="text-lg font-medium text-gray-900 mb-4">{t("billingAddress")}</h2>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">E-Mail</label>
                                    <input placeholder={`E-Mail`} value={userEmail} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t("firstname")}</label>
                                    <input placeholder={`${t("firstname")}`} value={""} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t("lastname")}</label>
                                    <input placeholder={`${t("lastname")}`} value={""} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <hr className='m-2'></hr>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t("street")}</label>
                                    <input placeholder={`${t("street")}`} value={""} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t("city")}</label>
                                    <input placeholder={`${t("city")}`} value={""} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t("zip")}</label>
                                    <input placeholder={`${t("zip")}`} value={""} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t("country")}</label>
                                    <input placeholder={`${t("country")}`} value={""} onChange={(event) => {console.log(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                                </div>
                                <div className='text-sm text-gray-500 text-center'>{t("wontBeChargedNextStep")}</div>
                                <div className='m-2 mx-auto' onClick={() => setInputPaymentDataComplete(true)}>
                                    <MainButton text={`${t("next")}`} icon="" link="" />
                                </div>
                            </div>
                        ) : (clientSecret && stripePromise) ? (
                            <>
                                <Elements key={clientSecret} stripe={stripePromise} options={{ clientSecret }}>
                                    <PaymentCheckoutForm setCheckoutResult={setCheckoutResult} />
                                </Elements>
                                {(delay) ? (<LoadingSpinner />) : (<div></div>)}
                            </>
                        ) : (<LoadingSpinner />)
                        
                    }
                    {
                        
                    }
                </div>
            </div>
        </div>
    </FormModal>
  )
}

export default PayForResultsModal