import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { login, logout, setEmail, setRole } from '../../../redux/userSlice';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { checkLogin } from '../../../data/AuthRequests';
import NotificationOverlay from '../notification-overlay/NotificationOverlay';
import Navbar from './features/Navbar';

const Index = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleCheckLoggedIn();
    setLoading(false);
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      handleCheckLoggedIn();
    }
    setLoading(false);
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if (!isLoggedIn) return
    const interval = setInterval(() => {
      console.log("Checking if still logged in...")
      handleCheckLoggedIn();
    // }, 1000);
    }, 5 * 60 * 1000);
  
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  const handleCheckLoggedIn = async () => {
    const response = await checkLogin()
    if (response.authorized) {
      if (!isLoggedIn) {
        dispatch(login());
        dispatch(setEmail(response.data.email));
        dispatch(setRole(response.data.role));
      }
    }  else {
      dispatch(logout());
    }
  }

  return (
    <div className='h-[100dvh]'>
      <Navbar />
      {(!loading && typeof isLoggedIn !== "undefined") ? (
        <div className=''>
          <NotificationOverlay />
          <Outlet />
        </div>
      ) : (
        <div className='mt-32'>
          <LoadingSpinner />
        </div>
      )}
      {/* <div className='absolute bottom-0'>{String(isLoggedIn)}</div> */}
    </div>
  )
}

export default Index