import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropzoneUpload from '../../../../../../components/DropzoneUpload';
import MainButton from '../../../../../../components/MainButton';
import { uploadSoftwareProductFunctionDump } from '../../../../../../data/ProductRequests';

const UploadSoftwareProductFunctions = () => {
    const {t} = useTranslation();
    const [uploadedFile, setUploadedFile] = useState<string | ArrayBuffer | null>();
    const [uploadedJson, setUploadedJson] = useState<any[]>();
    const [ uploadError, setUploadError ] = useState(false);

    useEffect(() => {
        convertJson();
    }, [uploadedFile])

    const convertJson = async () => {
        if (typeof uploadedFile === "string") {
            const convertedFile = await JSON.parse(uploadedFile);
            setUploadedJson(convertedFile);
        }
    }

    const handleUploadRequirements = async () => {
        if (uploadedJson) {
            if (await uploadSoftwareProductFunctionDump(uploadedJson)) {
                handleRemoveFile();
            } else {
                setUploadError(true);
            }
        }
    }

    const handleRemoveFile = () => {
        setUploadedFile(undefined);
        setUploadedJson(undefined);
        setUploadError(false);
    }

    return (
    <div className="relative bg-white overflow-x-auto shadow-md sm:rounded-lg mt-5">
        {
            (!uploadedJson || typeof uploadedJson === "undefined") ? (
                <>
                    <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                        Funktionskataloge importieren
                        <p className="mt-1 text-sm font-normal text-gray-500">Nutzen Sie diese Funktion, um per Upload einer JSON-Datei mehrere Funktionskataloge der Softwareprodukte auf einmal zu importieren. Die JSON-Datei muss entsprechend der vorgegebenen Struktur formatiert werden (siehe Dokumentation).</p>
                    </div>
                    <div className="p-5">
                        <DropzoneUpload setUploadedFile={setUploadedFile} fileType={'JSON'} maxFileSize={'50 mb'} />
                    </div>
                </>
            ) : (
                <div>
                    <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                        <div className='flex'>
                            <p className="mt-1 text-sm font-normal text-gray-500">{t("uploadFilenameRecognized", {filename: ""})}</p>
                            <a href="#" onClick={() => (handleRemoveFile())}
                            className="pl-2 mt-1 text-sm font-normal">{t("remove")}</a>
                        </div>
                        <p className="mt-1 text-sm font-normal text-gray-500">{(uploadedJson.length > 0) ? (`Insgesamt wurden ${uploadedJson.length} Softwareprodukte mit Funktionskatalogen erkannt.`) : ("Es wurden keine Softwareprodukte mit Funktionskatalogen erkennt.")}</p>
                    </div>
                    <div className='flex'>
                        {
                            (uploadedJson.length > 0) ? (
                                (uploadError) ? (<p className='p-5'>{t("uploadRequirementsError")}</p>) : (    
                                    <div className='p-5'
                                    onClick={() => handleUploadRequirements()}>
                                        <MainButton text={t("upload")} icon={''} link={''} />
                                    </div>
                                )
                            ) : (<></>)
                        }
                    </div>
                </div>
            )
        }
    </div>
  )
}

export default UploadSoftwareProductFunctions