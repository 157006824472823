import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon';
import SpeedDial from '../../components/SpeedDial';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import SettingsFilledIcon from '../../../../../../assets/img/icons/SettingsFilledIcon';
import ScenarioModal from './components/ScenarioModal';
import { getSelectionCompanyDescription, setSelectionCompanyDescription } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import TextInput from '../../../../../../components/TextInput';
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon';
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon';
import ScenarioCard from './components/ScenarioCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

interface scenarioOverviewProps {
    handleOpenScenario: (id:string, name:string) => void,
    scenarios: any[],
    selectionId: string,
    handleFetchScenarios: () => void,
}

const ScenarioOverview = ({handleOpenScenario, scenarios, selectionId, handleFetchScenarios} : scenarioOverviewProps) => {
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [addScenarioModalOpen, setAddScenarioModalOpen] = useState(false);
    const [modifyScenarioId, setModifyScenarioId] = useState<string>();
    const [filteredScenarios, setFilteredScenarios] = useState<any[]>([]);
    
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [companyDescription, setCompanyDescription] = useState<string | null>();
    const [newDescription, setNewDescription] = useState<string | null>(null);
    
    const [scenarioAggregations, setScenarioAggregations] = useState<any[]>();
    
    useEffect(() => {
        if (!addScenarioModalOpen) { setModifyScenarioId(undefined); }
    }, [addScenarioModalOpen])
    
    useEffect(() => {
        if (typeof modifyScenarioId !== "undefined") setAddScenarioModalOpen(true);
    }, [modifyScenarioId])
    
    const handleFetchSelectionCompanyDescription = async () => {
        const response = await getSelectionCompanyDescription(selectionId)
        if (response.ok) {
            const desc = response.data.data.companyDescription;
            setCompanyDescription(desc == "" ? null : desc);
        }
    }
    
    const handleSaveNewSelectionCompanyDescription = async () => {
        if (newDescription != companyDescription && newDescription != null) {
            await setSelectionCompanyDescription(selectionId, newDescription);
            handleFetchSelectionCompanyDescription();
        }
        setNewDescription(null);
    }

    // console.log(scenarios);

    useEffect(() => { 
        setFilteredScenarios(scenarios);
        handleFetchSelectionCompanyDescription(); 
    }, [])
    
    useEffect(() => { 
        setFilteredScenarios(scenarios);
    }, [scenarios])

    return (
    <div>

        <div className="flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            {
                (typeof companyDescription === "undefined") ? (<LoadingSpinner />) : (
                    <>
                    <p className='text-sm font-semibold'>Company description</p>

                    <div className='mb-5'>
                        {/* We are anestablished mid-sized enterprise, employing 400 professionals across threestrategic locations: Northern Germany and two sites in Bavaria. Our coreexpertise lies in the production of plastic molds tailored for the automotiveindustry, alongside the specialized fabrication of bespoke molds with limitedproduction runs per variant. Our in-house development team is integral to ouroperations, ensuring a comprehensive value chain from initial concept to finalproduction. We arecurrently in the process of transitioning away from our existing ERP system,ProAlpha. This strategic decision stems from our recognition of the need toexpand our capabilities in the realm of smart service offerings and enhanceindustry-specific functions. Our aim is to place a stronger emphasis onoptimizing the value creation process. To achieve this, we are committed toadvancing the digitalization of our company's processes. This move is not onlyabout replacing an old system but also about embracing technologicaladvancements to foster efficiency, innovation, and growth in our operations. */}
                        {
                            (newDescription != null) ? (
                                <TextInput textValue={newDescription} setTextValue={setNewDescription} rows={4} />
                            ) : (
                                    companyDescription == null  ? ("Please enter a short description of your company...") : (
                                        <>
                                        <div onClick={() => setDescriptionOpen(prev => !prev)}
                                        className={`${descriptionOpen ? "" : "line-clamp-2"}`}>
                                            {companyDescription}
                                        </div>
                                        <div onClick={() => setDescriptionOpen(prev => !prev)} className='w-fit text-primary text-sm italic cursor-pointer hover:underline hover:underline-offset-3'>{descriptionOpen ? "Less" : "More"}</div>
                                        </>

                                )
                            )
                        }
                    </div>

                    {
                        projectRole == "selection_admin" ? (
                            <div className='flex mt-5 space-x-2'>
                                {
                                    newDescription != null ? (
                                        <div className='flex space-x-2'>
                                            <MainButton text="Save" link={''} func={() => handleSaveNewSelectionCompanyDescription()} />
                                            <MainButton text="Cancel" link={''} func={() => setNewDescription(null)} style='light' />
                                        </div>
                                    ) : (
                                        <div>
                                            <MainButton text="Edit" link={''} func={() => setNewDescription(companyDescription == null ? "" : companyDescription)} style='light' />
                                        </div>
                                    )
                                }
                            </div>
                        ) : <></>
                    }

                    </>
                )
            }
        </div>

        <div className='border-t mt-4 pt-4 flex items-center justify-between'>
            <div className='w-64 h-10'><SearchBarFuzzy translatedPlaceholderItem={'scenario'} originalData={scenarios} filterKeys={['title', 'description']} setFilteredData={setFilteredScenarios} /></div>
            {
                projectRole == "selection_admin" ? (
                    <div className='flex space-x-2'>
                        <MainButton height='h-10' text={'Add new scenario'} text_location='right' link={''} func={() => setAddScenarioModalOpen(true)} icon={<div className='w-5 h-5 text-white rotate-45'><CrossIcon iconColor='currentColor' /></div>} />
                    </div>
                ) : <></>
            }
        </div>

        <div className='grid grid-cols-3 gap-4 mt-4'>
            {
                filteredScenarios.sort((a:any,b:any) => a.cleanOrderNumber-b.cleanOrderNumber).map((scenario:any, index:number) => {
                    return (
                        <ScenarioCard index={index} scenario={scenario} handleOpenScenario={handleOpenScenario} setModifyScenarioId={setModifyScenarioId} handleFetchScenarios={handleFetchScenarios} isLastItem={filteredScenarios.length == index+1} setAddScenarioModalOpen={setAddScenarioModalOpen} />
                    )
                })
            }
        </div>
        
        { (addScenarioModalOpen) ? <ScenarioModal addScenarioModalOpen={addScenarioModalOpen} setAddScenarioModalOpen={setAddScenarioModalOpen} scenarios={scenarios} selectionId={selectionId} scenarioId={modifyScenarioId} handleFetchScenarios={handleFetchScenarios} /> : <></> }
        
        <SpeedDial speedDials={projectRole != "selection_admin" ? [] : [
            {tooltip: "Add scenario", onClick:() => setAddScenarioModalOpen(true)},
            {tooltip: "Change order", onClick:() => console.log("Not implemented"), icon:<div className='text-gray-300 w-8 h-8'><RefreshIcon iconColor='currentColor' iconHeight='h-full' iconWidth='w-full'/></div>},
        ]} defaultDials={true} currentContext='scenarios' selectionId={selectionId} />

    </div>
  )
}

export default ScenarioOverview