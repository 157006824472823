import React, { createRef, useEffect, useRef, useState } from 'react';
import UnderConstructionPlaceholder from '../../../../../../components/UnderConstructionPlaceholder'
import SearchBar from '../../../../../../components/SearchBar';
import DocumentCard from '../components/DocumentCard';
import VendorDocumentRow from '../components/VendorDocumentRow';
import { getDocuments, getSelectionRanking, uploadDocument } from '../../../../../../data/SelectionPortalRequests';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import FormModal from '../../../../../../components/FormModal';
import DropzoneUpload from '../../../../../../components/DropzoneUpload';
import MainButton from '../../../../../../components/MainButton';
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon';
import SpeedDial from '../../components/SpeedDial';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import SelectionGuideInjector from '../../components/SelectionGuideInjector';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

const DocumentOverview = () => {
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const { selectionId, selectionPhase } = useOutletContext<any>();
  const [ selectedDocumentType, setSelectedDocumentType ] = useState("proposals");
  const [ softwareProductDocuments, setSoftwareProductDocuments ] = useState<any[]>([]);
  const [ softwareProductDocumentsFiltered, setSoftwareProductDocumentsFiltered ] = useState<any[]>([]);
  const [ softwareProductProposals, setSoftwareProductProposals ] = useState<any[]>([]);
  const [ softwareProductContracts, setSoftwareProductContracts ] = useState<any[]>([]);
  const [ openSoftwareProductDocument, setOpenSoftwareProductDocument ] = useState("");
  
  const [ newVendorDocumentsModalOpen, setNewVendorDocumentsModalOpen ] = useState(false);
  const [ selectionRanking, setSelectionRanking ] = useState<any[]>();
  
  const [ uploadedFile, setUploadedFile ] = useState<string | ArrayBuffer | null>();
  const [ filename, setFilename ] = useState<string>("");
  
  const [ filesAndFilenames, setFilesAndFilenames ] = useState<any[]>([]);
  const [ newSystem, setNewSystem ] = useState<string>();
  const [ uploadStarted, setUploadStarted ] = useState<boolean>(false);
  const [ errorDuringUpload, setErrorDuringUpload ] = useState<boolean>();

  const handleOpenVendorDocuments = (vendorDocumentsId: string) => {
      setOpenSoftwareProductDocument(prev => {if (vendorDocumentsId == prev) {return("")} else {return(vendorDocumentsId)}});
  }

  const handleFetchDocuments = async () => {
      const response = await getDocuments(selectionId);
      setSoftwareProductProposals(response.data.data.results.proposals);
      setSoftwareProductContracts(response.data.data.results.contracts);
      if (selectedDocumentType == "proposals") {
        setSoftwareProductDocuments(response.data.data.results.proposals);
        setSoftwareProductDocumentsFiltered(response.data.data.results.proposals);
      } else {
        setSoftwareProductDocuments(response.data.data.results.contracts);
        setSoftwareProductDocumentsFiltered(response.data.data.results.contracts);
      }
  }

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const type = searchParams.get("type");

    if (type=="proposal") {setSelectedDocumentType("proposals")}
    if (type=="contract") {setSelectedDocumentType("contracts")}
      
    handleFetchDocuments();

  }, [])

  useEffect(() => {
    if (selectedDocumentType == "proposals") {
      setSoftwareProductDocuments(softwareProductProposals);
      setSoftwareProductDocumentsFiltered(softwareProductProposals);
    } else {
      setSoftwareProductDocuments(softwareProductContracts);
      setSoftwareProductDocumentsFiltered(softwareProductContracts);
    }
    setOpenSoftwareProductDocument("");
  }, [selectedDocumentType])    

  const handleGetSelectionRanking = async () => {
    const response = await getSelectionRanking(selectionId);
    if (response.ok) {
      const filteredSystems = response.data.data.results.filter((item:any) => item.included);
      console.log(filteredSystems);
      setSelectionRanking(filteredSystems);
    } else {
      setErrorDuringUpload(true);
    }
  }    

  const handleUploadDocument = async () => {
    if (filesAndFilenames.length > 0 && typeof newSystem !== "undefined") {
      setUploadStarted(true);
      await Promise.all(filesAndFilenames.map(async item => {
        if (await uploadDocument(newSystem, item.filename, selectedDocumentType, item.uploadedFile)) {
          item.uploaded = true;
          setFilesAndFilenames(prev => [...prev.filter(i => i.tempId != item.tempId), item]);
        }
      }));
      setErrorDuringUpload(false);
      handleFetchDocuments();
    }
  }

  const handleRemoveDocumentFromUpload = (tempId: string) => {
    setFilesAndFilenames(filesAndFilenames.filter((item:any) => item.tempId != tempId));
  }
  
  useEffect(() => {
    if (uploadedFile && typeof uploadedFile === "string") {
      if (filesAndFilenames.filter((item:any) => item.filename == filename).length == 0) {
        const tempId = String(Date.now());
        
        setFilesAndFilenames([...filesAndFilenames, {tempId, filename, uploadedFile, uploaded:false}]);
      }
      setUploadedFile(null);
      setFilename("");
    }
  }, [uploadedFile])
  
  useEffect(() => {
    if (newVendorDocumentsModalOpen) {
      handleGetSelectionRanking();
    }

    setFilesAndFilenames([]);
    setNewSystem(undefined);
    setErrorDuringUpload(undefined);
    setUploadStarted(false);
  }, [newVendorDocumentsModalOpen])

  return (
    <div className='flex flex-col h-full relative'>
        <div className="w-full h-16 flex content-center w-full py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
          <div className="flex w-full justify-between rounded-md max-md:flex-wrap max-md:max-w-full">

            <div className='relative flex w-48 bg-gray-50 border border-gray-300 rounded-lg'>
              <div onClick={() => setSelectedDocumentType("proposals")} 
              className={`z-[2] w-24 flex items-center justify-center text-center transitions-all duration-500 ${(selectedDocumentType == "proposals") ? "text-white" : "text-gray-400"}`}>
                Proposals
              </div>
              <div onClick={() => setSelectedDocumentType("contracts")} 
              className={`z-[2] w-24 flex items-center justify-center text-center transitions-all duration-500 ${(selectedDocumentType == "contracts") ? "text-white" : "text-gray-400"}`}>
                Contracts
              </div>
              <div className={`absolute w-24 z-[1] h-full rounded-lg cursor-pointer transitions-all duration-500 bg-primary text-white ${(selectedDocumentType == "proposals") ? "left-0" : "left-24"}`}></div>
            </div>

            <div className='flex'>
              <SearchBarFuzzy translatedPlaceholderItem={`Documents`} originalData={softwareProductDocuments} setFilteredData={setSoftwareProductDocumentsFiltered} filterKeys={["softwareProduct.vendorDetails.company", "selectionSoftwareProductDocument.name"]} />
              <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["cont-nego_prop"]} />
            </div>


          </div>
        </div>

        <div className='grow p-5 overflow-y-auto'>
          {
            softwareProductDocumentsFiltered.map((softwareProductDocument:any, index:number) => {
              return (
                <VendorDocumentRow open={softwareProductDocument.id == openSoftwareProductDocument} softwareProductDocument={softwareProductDocument} handleOpenVendorDocuments={handleOpenVendorDocuments} documentType={selectedDocumentType} handleFetchDocuments={handleFetchDocuments} />
              )
            })
          }

          {
            projectRole == "selection_admin" ? (
            <div onClick={() => setNewVendorDocumentsModalOpen(true)}
            className={`flex items-center justify-center cursor-pointer shadow max-md:max-w-full h-32 mt-2 bg-white opacity-50 hover:opacity-100 transitions-all duration-500 cursor-pointer rounded-lg `}>
              <div className='text-gray-300 w-16 h-16 rotate-45'>
                <CrossIcon iconColor='currentColor' />
              </div>
            </div>
            ) : <></>
          }
          
          {/* <div onClick={() => setNewVendorDocumentsModalOpen(true)}
          className='fixed rounded-full shadow-lg flex items-center justify-center right-5 bottom-5 bg-primary h-24 w-24 hover:scale-[1.05] transitions-all duration-500 cursor-pointer'>
            <div className='text-white w-16 h-16 rotate-45'>
              <CrossIcon iconColor='currentColor' />
            </div>
          </div> */}

          {/* <SpeedDial onMainClick={() => setNewVendorDocumentsModalOpen(true)} /> */}

        </div>


        {
          newVendorDocumentsModalOpen && typeof selectionRanking !== "undefined" ? (
              <FormModal isOpen={newVendorDocumentsModalOpen} setOpen={setNewVendorDocumentsModalOpen} size={''}>
                <div>
                  <h3 className="mb-4 text-xl font-medium text-gray-900">Add documents for new software product</h3>
                  {
                    !uploadStarted ? (
                      <DropzoneUpload setUploadedFile={setUploadedFile} setFilename={setFilename} fileType={'PDF'} maxFileSize={'10 mb'} />
                    ) : (<></>)
                  } 
                  {
                    filesAndFilenames.length > 0 ?  (
                      <div className='mt-2 gap-1 flex flex-wrap'>
                        {
                          filesAndFilenames.sort((a,b) => a.tempId - b.tempId).map((item: any, index: number) => {
                            return (
                              <div id={item.tempId} className={`px-1 space-x-1 flex items-center justify-between text-gray-700 text-sm h-6 ${item.uploaded ? "bg-green-100" : "bg-gray-100"} rounded-lg`}>
                                <p>{item.filename}</p>
                                {
                                  !item.uploaded ? (
                                    <div onClick={() => handleRemoveDocumentFromUpload(item.tempId)}
                                      className='h-4 w-4 cursor-pointer rounded-md hover:bg-gray-200'>
                                      <CrossIcon iconColor='currentColor' />
                                    </div>
                                  ) : (<></>)
                                }
                              </div>
                            )
                          }) 
                        }
                      </div>
                    ) : (<></>)
                  }

                  {
                    !uploadStarted ? (
                      <>  
                        <div className='my-4'>
                            <label htmlFor="countries" className="block text-sm font-medium text-gray-900">Software product</label>
                            <select onChange={(event) => setNewSystem(event.target.value)}
                            id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
                                <option value={undefined} selected disabled hidden>Choose here</option>
                                {
                                    selectionRanking.map((item : any, index : number) => {
                                        return (<option key={index} value={item.id}>{item.softwareProduct.productName} ({item.softwareFulfillment.note})</option>);
                                    })
                                }
                            </select>
                        </div>
                        <MainButton text={'Upload'} link={''} func={handleUploadDocument} />
                      </>
                    ) : (<></>)
                  }
                  {
                    (typeof errorDuringUpload === "undefined") ? (<></>) : 
                    (errorDuringUpload) ? (<div className='w-full text-center text-sm font-medium text-red-400'>An error has occurred during upload. Please try again.</div>) :
                    (<div className='w-full text-center text-sm font-medium text-green-600'>Upload successful. You can now close the window.</div>)
                  }
                </div>
              </FormModal>
          ) : (<></>)
        }

      <SpeedDial speedDials={projectRole != "selection_admin" ? [] : [
        {tooltip: "Add contracts", onClick: () => setNewVendorDocumentsModalOpen(true)},
      ]} defaultDials={true} currentContext='contracts' selectionId={selectionId} />
    </div>
  )
}

export default DocumentOverview