import React, { useEffect, useRef, useState } from 'react'
import { ArrowDownIcon, CheckmarkIcon, InfoIcon, MainButton, MessageIcon, TextInput } from 'swap-frontend-library';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { RootState } from '../../../../../../../../redux/store';

interface props {
    scenario : any, 
    scenarioEvaluations: any, 
    presentationId : string, 
    handleFetchScenarioEvaluations: () => {}
    liveScenarioTaskId: any, 
    setLiveScenarioTaskId: React.Dispatch<React.SetStateAction<any>>
}

const ScenarioList = ({scenario, scenarioEvaluations, presentationId, handleFetchScenarioEvaluations, liveScenarioTaskId, setLiveScenarioTaskId} : props) => {
    const [scenarioDescriptionTextareaHeight, setScenarioDescriptionTextareaHeight] = useState("inherit");
    const scenarioDescriptionTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [sortedUniqueCategories, setSortedUniqueCategories] = useState<any[]>();

    const toggleScenarioDescription = () => {
        if (typeof scenarioDescriptionTextareaRef.current === "undefined" || scenarioDescriptionTextareaRef.current == null) return;
        if (scenarioDescriptionTextareaHeight != "inherit") {
            setScenarioDescriptionTextareaHeight("inherit");
        } else {
            setScenarioDescriptionTextareaHeight(`${scenarioDescriptionTextareaRef.current.scrollHeight}px`);
        }
    }

    const handleGetUniqueScenarioCategories = () => {
        const cateogories = scenario.selectionScenarioTasks.map((item:any)=>item.selectionScenarioTaskCategory);
        const cateogoryIds = scenario.selectionScenarioTasks.map((item:any)=>item.selectionScenarioTaskCategory.id);
        const uniqueCategories = cateogories.filter((item:any, index:number) => (cateogoryIds.indexOf(item.id) === index));
        const newSortedUniqueCategories =  uniqueCategories.sort((a:any, b:any) => a.orderNumber-b.orderNumber)
        setSortedUniqueCategories(newSortedUniqueCategories);
        // console.log(newSortedUniqueCategories)
    }

    useEffect(() => {
        handleGetUniqueScenarioCategories();
    }, [scenario])

    return (
    <div className='p-4'>
        <div className="relative flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            <div className='mb-2'>
                <p className='text-sm font-semibold'>Title</p>
                <p className="w-full rounded text-xl font-bold tracking-tight leading-9 text-gray-900">{scenario.title}</p>
            </div>

            <div>
                <div onClick={() => toggleScenarioDescription()}
                className='flex items-center space-x-4 font-semibold text-lg cursor-pointer mt-2'>
                    <p className='text-sm font-semibold'>Evaluation summary</p>
                    { scenarioDescriptionTextareaHeight != "inherit" ? <div className='rotate-180 w-6 h-6'><ArrowDownIcon /></div> : <div className='rotate-0 w-6 h-6'><ArrowDownIcon /></div>}
                </div>

                <div className='text-left grow w-full text-sm bg-gray-50 rounded-lg border p-2 pt-6 relative'>
                    <div className='absolute right-5 -top-3 rounded-full border-blue-700/90 text-blue-700/90 bg-gradient-to-br from-blue-700/50 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2'>AI generated</div>
                    <textarea ref={scenarioDescriptionTextareaRef} disabled={true} className={`w-full italic space-y-2 rounded bg-transparent tracking-tight leading-2 text-gray-900 ${scenarioDescriptionTextareaHeight == "inherit" ? "line-clamp-2" : ""}`} style={{height: scenarioDescriptionTextareaHeight}} value={scenarioEvaluations.summaries[scenario.id]} />
                    { scenarioDescriptionTextareaHeight == "inherit" ? <p onClick={() => toggleScenarioDescription()} className='font-semibold cursor-pointer'>More</p> : <></>}
                </div>

                {/* <div onClick={() => toggleScenarioDescription()} className='w-fit text-primary text-sm italic cursor-pointer hover:underline hover:underline-offset-3'>{scenarioDescriptionTextareaHeight != "inherit" ? "Less" : "More"}</div> */}
            </div>
        </div>

        <div className='flex flex-col'>
            {
                typeof sortedUniqueCategories === "undefined" ? <></> : sortedUniqueCategories.map((category:any) => (
                    <ScenarioCategoryRow scenarioCategory={category}>
                        {
                            scenario.selectionScenarioTasks.filter((item:any) => item.selectionScenarioTaskCategory.id == category.id).map((task:any) => (
                                <ScenarioTaskRow scenario={scenario} scenarioTask={task} presentationId={presentationId} evaluation={scenarioEvaluations.evaluations[task.id]} handleFetchScenarioEvaluations={handleFetchScenarioEvaluations} liveScenarioTaskId={liveScenarioTaskId} setLiveScenarioTaskId={setLiveScenarioTaskId} />
                            ))
                        }
                    </ScenarioCategoryRow>
                ))
            }
        </div>
    </div>
  )
}

const ScenarioCategoryRow = ({children, scenarioCategory} : {children : any, scenarioCategory : any}) => {
    const [open, setOpen] = useState(true);
    return (
        <div className='flex flex-col gap-2 mt-6 bg-white p-4 rounded-lg shadow'>
            <div onClick={() => setOpen(prev => !prev)} className='flex items-center gap-2 cursor-pointer'>
                <p className='font-semibold'>{scenarioCategory.title}</p>
                <div className={`w-4 h-4 ${open ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
            </div>
            {
                open ? (
                    <div className='flex flex-col gap-2'>
                        <div className={`grid grid-cols-12 gap-4`}><div className='col-span-7'></div><div className='col-span-3 text-center -ml-6 font-light text-sm'>Avg. rating</div><div className='text-center -ml-6 font-light text-sm'>Comments</div></div>
                        {children}
                    </div>
                ) : (<></>)
            }
        </div>
    )
}


interface scenarioTaskRowProps {
    scenario: any
    scenarioTask : any, 
    evaluation: any, 
    presentationId : string, 
    handleFetchScenarioEvaluations: () => {},
    liveScenarioTaskId: any, 
    setLiveScenarioTaskId: React.Dispatch<React.SetStateAction<any>>
}

const ScenarioTaskRow = ({scenario, scenarioTask, evaluation, presentationId, handleFetchScenarioEvaluations, liveScenarioTaskId, setLiveScenarioTaskId} : scenarioTaskRowProps) => {
    const isLive = scenarioTask.id == liveScenarioTaskId?.liveScenarioTaskId;
    const [open, setOpen] = useState(isLive);

    let averageScore = 0;
    if (typeof evaluation !== "undefined") averageScore = evaluation.statistics.sumRatings / evaluation.statistics.numRatings;
 
    return (
        <div onClick={() => setOpen(prev => !prev)} className={`cursor-pointer w-full rounded-lg shadow p-4 flex flex-col gap-4 bg-white`}>
            <div className={`grid grid-cols-12 gap-4`}>
                <p className='col-span-1 flex items-center justify-start font-semibold'>{scenarioTask.cleanOrderNumber}</p>
                <div className={`${open ? "" : "truncate"} col-span-6`}>
                    <span className='font-semibold inline'>{scenarioTask.title}:</span> {scenarioTask.description}
                </div>
                <div className={`relative group col-span-3 flex items-center justify-center space-x-1 items-center`}>
                    {
                        [...Array(6).keys()].map((ithDot: any, index: number) => {
                            return (
                                <div
                                className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(averageScore)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} rounded-full`}>
                                </div>
                            )
                        })
                    }
                    <div className='bg-white pl-4'>
                        Ø {averageScore.toFixed(1)}
                    </div>
                </div>
                <div className={`col-span-1 flex items-center justify-center`}>
                    {
                        (typeof evaluation !== "undefined") ? (
                            <div className='relative w-6 h-6 flex gap-1'>
                                <p>{String(evaluation.statistics.numComments)}</p>
                            </div>
                        ) : (
                            <div className='relative w-6 h-6 flex gap-1'>
                                <p>0</p>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                open ? (
                <div className='border-t mt-4 py-4'>
                    <p className='font-semibold text-sm'>Comments</p> 
                    <div onClick={(e) => {e.stopPropagation()}} className='w-full gap-2 grid grid-cols-4'>
                        {
                            typeof evaluation === "undefined" ? <></> :
                            evaluation.evaluations.filter((item:any) => item.comment != null && item.comment != "").sort((a:any, b:any) => a.rating < b.rating).map((item:any, index:number) => (
                                <div className='h-full mt-2 relative flex flex-col justify-between w-full h-fit bg-white shadow border rounded p-4 max-w-96'>
                                    <div className='text-sm font-light italic'>"{item.comment}"</div>
                                    <div>
                                        <div className={`mt-2 relative group col-span-3 flex items-center justify-start space-x-1 items-center`}>
                                            {
                                                [...Array(6).keys()].map((ithDot: any, index: number) => {
                                                    return (
                                                        <div
                                                        className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(item.rating)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} rounded-full`}>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='bg-white pl-4 text-sm font-light'>
                                                {item.rating?.toFixed(1)}
                                            </div>
                                        </div>
                                        <p className='text-sm font-light mt-2 truncate'>{item.user == null ? "deleted user" : item.user.email}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='py-4 mt-2'>
                        <p className='font-semibold text-sm'>Ratings</p> 
                        <div onClick={(e) => {e.stopPropagation()}} className='w-full mt-2 gap-2 grid grid-cols-4'>
                            {
                                typeof evaluation === "undefined" ? <></> :
                                evaluation.evaluations.sort((a:any, b:any) => a.rating < b.rating).map((item:any, index:number) => (
                                    <div className='h-full mt-2 relative flex flex-col w-full h-fit bg-white shadow border rounded p-4 max-w-96'>
                                        <div className={`mt-2 relative group col-span-3 flex items-center justify-start space-x-1 items-center`}>
                                        {
                                            [...Array(6).keys()].map((ithDot: any, index: number) => {
                                                return (
                                                    <div
                                                    className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(item.rating)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} rounded-full`}>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className='bg-white pl-4 text-sm font-light'>
                                            {item.rating?.toFixed(1)}
                                        </div>
                                    </div>
                                    <p className='text-sm font-light mt-2 truncate'>{item.user == null ? "deleted user" : item.user.email}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                ) : <></>
            }
        </div>
    )
}

export default ScenarioList