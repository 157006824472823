import React, { useEffect, useRef, useState } from 'react'
import ArrowDownIcon from '../../../../../../assets/img/icons/ArrowDownIcon'
import MainButton from '../../../../../../components/MainButton';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import SpeedDial from '../../components/SpeedDial';
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon';
import SearchBar from '../../../../../../components/SearchBar';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import ScenarioTaskModal from './components/ScenarioTaskModal';
import TextInput from '../../../../../../components/TextInput';
import { updateSelectionScenario, setSelectionScenarioTaskStatus, createNotification } from '../../../../../../data/SelectionPortalRequests';
import FilledInfoIcon from '../../../../../../assets/img/icons/FilledInfoIcon';
import QuickMenu from '../../../../../../components/QuickMenu';
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon';
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon';
import ScenarioItemTableRow from './components/ScenarioItemTableRow';
import SettingsFilledIcon from '../../../../../../assets/img/icons/SettingsFilledIcon';
import ScenarioTaskCategoryTab from './components/ScenarioTaskCategoryTab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
// import ScenarioTaskCategoryModal from './components/ScenarioTaskCategoryModal';

interface props {
    scenarioId: string,
    scenario: any,
    handleFetchScenarios: () => void,
}

const ScenarioViewer = ({scenarioId, scenario, handleFetchScenarios} : props) => {
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const scenarioDescriptionTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [scenarioDescriptionTextareaHeight, setScenarioDescriptionTextareaHeight] = useState("inherit");
    const [newDescription, setNewDescription] = useState<string | null>(null);
    const [newScenarioTitle, setNewScenarioTitle] = useState<string | null>(null);
    const [sortedUniqueCategories, setSortedUniqueCategories] = useState<any[]>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [originalScenarioTasksByCategory, setOriginalScenarioTasksByCategory] = useState<any>([]);
    const [filteredScenarioTasks, setFilteredScenarioTasks] = useState<any>([]);

    const [addScenarioTaskModalOpen, setAddScenarioTaskModalOpen] = useState(false);
    const [modifyScenarioTaskId, setModifyScenarioTaskId] = useState<string>();

    useEffect(() => {
        if (!addScenarioTaskModalOpen && projectRole == "selection_admin") { setModifyScenarioTaskId(undefined); }
    }, [addScenarioTaskModalOpen])

    useEffect(() => {
        if (typeof modifyScenarioTaskId !== "undefined" && projectRole == "selection_admin") setAddScenarioTaskModalOpen(true);
    }, [modifyScenarioTaskId])
    
    const [addScenarioTaskCategoryModalOpen, setAddScenarioTaskCategoryModalOpen] = useState(false);

    const [openMenuForId, setOpenMenuForId] = useState<string>();
    const [archivedVisible, setArchivedVisible] = useState(false);

    const handleGetUniqueScenarioCategories = () => {
        const cateogories = scenario.selectionScenarioTasks.map((item:any)=>item.selectionScenarioTaskCategory);
        const cateogoryIds = scenario.selectionScenarioTasks.map((item:any)=>item.selectionScenarioTaskCategory.id);
        const uniqueCategories = cateogories.filter((item:any, index:number) => (cateogoryIds.indexOf(item.id) === index));
        const newSortedUniqueCategories =  uniqueCategories.sort((a:any, b:any) => a.orderNumber-b.orderNumber)
        
        // console.log(newSortedUniqueCategories)
        setSortedUniqueCategories(newSortedUniqueCategories);
        if (typeof selectedCategory === "undefined" && newSortedUniqueCategories.length > 0) {
            setSelectedCategory(newSortedUniqueCategories[0]);
        } else {
            // console.log("uniqueCategories ", uniqueCategories)
            if (uniqueCategories.filter((item:any) => item.id == selectedCategory.id).length == 0) {
                // console.log("selectedCategory 222 ", selectedCategory.title)
                // console.log("newSortedUniqueCategories ", newSortedUniqueCategories)
                setSelectedCategory(newSortedUniqueCategories[0]);
            }
        }
        
    }
    
    useEffect(() => {handleGetUniqueScenarioCategories()}, [])
    useEffect(() => {handleGetUniqueScenarioCategories()}, [scenario])
    useEffect(() => {setOpenMenuForId(undefined)}, [selectedCategory])

    const handleLoadScenarioTasks = () => {
        if (typeof selectedCategory === "undefined") return;
        
        const newScenarioTasks = scenario.selectionScenarioTasks.filter((item:any) => item.selectionScenarioTaskCategory.id == selectedCategory.id);
        setOriginalScenarioTasksByCategory(newScenarioTasks);
        setFilteredScenarioTasks(newScenarioTasks);
    }

    useEffect(handleLoadScenarioTasks, [selectedCategory, scenario])
    
    const handleSaveNewDescription = async () => {
        if (newDescription != scenario.description && newDescription != null) {
            await updateSelectionScenario(scenarioId, undefined, newDescription);
            handleFetchScenarios();
        }
        setNewDescription(null);
    }
    
    const handleSaveNewTitle = async () => {
        if (newScenarioTitle != scenario.title && newScenarioTitle != null && newScenarioTitle != "") {
                await updateSelectionScenario(scenarioId, newScenarioTitle, undefined);
                handleFetchScenarios();
        }
        setNewScenarioTitle(null);
    }

    const handleSetScenarioStatus = async (id: string, status: string) => {
        if (await setSelectionScenarioTaskStatus(id, status)) {
            handleFetchScenarios();
        }
    }
    
    const toggleScenarioDescription = () => {
        if (typeof scenarioDescriptionTextareaRef.current === "undefined" || scenarioDescriptionTextareaRef.current == null) return;
        if (scenarioDescriptionTextareaHeight != "inherit") {
            setScenarioDescriptionTextareaHeight("inherit");
        } else {
            setScenarioDescriptionTextareaHeight(`${scenarioDescriptionTextareaRef.current.scrollHeight}px`);
        }
    }

    const toggleExcluded = () => {
        setArchivedVisible(prev => !prev);
    }

    return (
    <div>
        <div className="relative flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            <div className='mb-2'>
                <p className='text-sm font-semibold'>Title</p>
                {/* <TextInput textValue={scenario.title} setTextValue={setNewDescription} /> */}
                <input 
                disabled={projectRole != "selection_admin"}
                onBlur={() => {handleSaveNewTitle()}} 
                className="w-full rounded text-xl font-bold tracking-tight leading-9 text-gray-900" value={newScenarioTitle != null ? newScenarioTitle : scenario.title} onChange={(e) => setNewScenarioTitle(e.target.value)}/>
            </div>

            <div>
                <div onClick={() => toggleScenarioDescription()}
                className='flex items-center space-x-4 font-semibold text-lg cursor-pointer'>
                    <p className='text-sm font-semibold'>Description</p>
                    { scenarioDescriptionTextareaHeight != "inherit" ? <div className='rotate-180'><ArrowDownIcon /></div> : <div className='rotate-0'><ArrowDownIcon /></div>}
                </div>

                <textarea
                disabled={projectRole != "selection_admin"}
                ref={scenarioDescriptionTextareaRef}
                onBlur={(e) => {handleSaveNewDescription(); e.target.style.height = 'inherit';}} 
                className={`w-full rounded tracking-tight leading-2 text-gray-900`} style={{height: scenarioDescriptionTextareaHeight}} value={newDescription != null ? newDescription : scenario.description} onFocusCapture={(e : any) => {e.target.style.height = `${e.target.scrollHeight}px`;}} onChange={(e) => {setNewDescription(e.target.value)}}/>

                <div onClick={() => toggleScenarioDescription()} className='w-fit text-primary text-sm italic cursor-pointer hover:underline hover:underline-offset-3'>{scenarioDescriptionTextareaHeight != "inherit" ? "Less" : "More"}</div>

            </div>

            <div className='absolute right-5 top-3 flex items-center justify-center space-x-5'>
                               
                <div className='group text-green-400 flex items-center justify-center'>
                    <div className='w-8 h-8 flex items-center'><CheckmarkIcon iconColor='currentColor' /></div>
                    <p className='w-2'>{scenario.scenarioTaskStatuses.completed || 0}</p>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 translate-y-5 group-hover:block hidden'>
                        Scenario was checked by user and is completed.
                    </div>
                </div>
                
                <div className='group w-11 h-10 text-green-400 flex items-center justify-center'>
                    <div className='w-8 h-8 flex items-center'>(<CheckmarkIcon iconColor='currentColor' />)</div>
                    <p className='ml-1 w-2'>{scenario.scenarioTaskStatuses.null || 0}</p>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 translate-y-5 group-hover:block hidden'>
                        Scenario was created manually but not confirmed yet. Manual validation might be necessary.
                    </div>
                </div>

                <div className='group w-10 h-10 text-amber-400 flex items-center justify-center'>
                    <div className='w-8 h-8 flex items-center'><WarningIcon iconColor='currentColor' /></div>
                    <p className='w-2'>{scenario.scenarioTaskStatuses.warning || 0}</p>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 translate-y-5 group-hover:block hidden'>
                        A warning has been recognised or added manually. A manual review is necessary.
                    </div>
                </div>

            </div>
        </div>

        <div className='border-t mt-4 pt-4 flex items-center justify-between'>
            <div className='w-64 h-10'><SearchBarFuzzy translatedPlaceholderItem={'scenario task'} originalData={originalScenarioTasksByCategory} filterKeys={['title', 'description']} setFilteredData={setFilteredScenarioTasks} /></div>
            {
                projectRole == "selection_admin" ? (
                <div className='flex space-x-2'>
                    <MainButton height='h-10' text={'Add new scenario task'} text_location='right' link={''} func={() => setAddScenarioTaskModalOpen(true)} icon={<div className='w-5 h-5 text-white rotate-45'><CrossIcon iconColor='currentColor' /></div>} />
                </div>
                ) : <></>
            }
        </div>

        {
            (typeof sortedUniqueCategories === "undefined") ? (<LoadingSpinner />) : (sortedUniqueCategories.length > 0 || typeof selectedCategory !== "undefined") ? (
                <div className='mt-4'>
                    <ol className="flex overflow-x-auto pt-1 bg-gray-100 items-center w-full text-sm font-medium text-center text-gray-500 border bdorder-l border-r bdorder-t border-gray-200 rounded-t-lg sm:text-base">
                        {
                            sortedUniqueCategories.sort((a:any, b:any) => a.orderNumber-b.orderNumber).map((scenarioCategory:any, index:number) => {
                                return (
                                    <ScenarioTaskCategoryTab setSelectedCategory={setSelectedCategory} scenarioCategory={scenarioCategory} index={index} selectedCategory={selectedCategory} sortedUniqueCategories={sortedUniqueCategories} handleFetchScenarios={handleFetchScenarios} isLastItem={sortedUniqueCategories.length == index+1} />
                                )
                            })
                        }

                        {/* <div className='ml-2'>
                            <AddCategoryWindow />
                        </div> */}
                    </ol>

                    <div className='pb-5 bg-white border-l border-r border-b border-gray-200 rounded-b-lg'>
                        <table className="w-full text-sm text-left text-gray-500">
                            <TableHeader header={["Order", "Title", "Description", "Status", projectRole == "selection_admin" ? "Action" : ""]} />
                            {
                                filteredScenarioTasks.filter((item : any) => (item.scenarioStatus != "archived")).map((scenarioTask: any, index: number) => {

                                    return (
                                        <ScenarioItemTableRow scenarioTask={scenarioTask} openMenuForId={openMenuForId} setOpenMenuForId={setOpenMenuForId} handleSetScenarioStatus={handleSetScenarioStatus} isLastItem={filteredScenarioTasks.length == index+1} handleFetchScenarios={handleFetchScenarios} setModifyScenarioTaskId={setModifyScenarioTaskId} />
                                    );
                                })
                            }

                            {
                                projectRole == "selection_admin" ? (
                                    <>
                                        <td colSpan={5} >
                                            <div onClick={toggleExcluded}
                                            className={`absolute flex items-center relative cursor-pointer my-4 ${archivedVisible ? "del-my-4" : "del-mt-4 del-mb-2"}`}>
                                                <div className='grow my-2 border-t border-gray-400 border-4'>
                                                </div>
            
                                                <div className='mx-4 flex flex-col items-center text-gray-400'>
                                                    {
                                                        (archivedVisible) ? ("Hide archived") : ("See archived")
                                                    }
                                                    
                                                    {/* <ArrowDownIcon iconColor='gray' iconHeight='100px' /> */}
                                                </div>
                                                
                                                <div className='grow my-2 border-t border-gray-400 border-4'>
                                                </div>
                                            </div>
                                        </td>
            
                                        {
                                            (archivedVisible) ? (
                                                filteredScenarioTasks.filter((item : any) => (item.scenarioStatus == "archived")).map((scenarioTask: any, index: number) => {
            
                                                    return (
                                                        <ScenarioItemTableRow scenarioTask={scenarioTask} openMenuForId={openMenuForId} setOpenMenuForId={setOpenMenuForId} handleSetScenarioStatus={handleSetScenarioStatus} isLastItem={filteredScenarioTasks.length == index+1} handleFetchScenarios={handleFetchScenarios} setModifyScenarioTaskId={setModifyScenarioTaskId} />
                                                    );
                                                })
                                            ) : (<></>)
                                        }
                                    </>
                                ) : <></>
                            }


                        </table>
                    </div>
                </div>
            ) : (<></>)
        }

        { (addScenarioTaskModalOpen) ? <ScenarioTaskModal addScenarioTaskModalOpen={addScenarioTaskModalOpen} setAddScenarioTaskModalOpen={setAddScenarioTaskModalOpen} scenarioTasks={scenario.selectionScenarioTasks} scenarioId={scenarioId} scenarioTaskId={modifyScenarioTaskId} handleFetchScenarios={handleFetchScenarios} scenarioTaskCategories={sortedUniqueCategories} /> : <></> }
        {/* { (addScenarioTaskCategoryModalOpen) ? <ScenarioTaskCategoryModal addScenarioTaskModalOpen={addScenarioTaskModalOpen} setAddScenarioTaskModalOpen={setAddScenarioTaskModalOpen} scenarioTasks={originalScenarioTasksByCategory} scenarioId={scenarioId} scenarioTaskCategoryId={selectedCategory.id} scenarioTaskId={modifyScenarioTaskId} handleFetchScenarios={handleFetchScenarios} /> : <></> } */}

        <SpeedDial speedDials={projectRole != "selection_admin" ? [] : [
            {tooltip: "Add scenario task", onClick:() => setAddScenarioTaskModalOpen(true)},
            {tooltip: "Change order", onClick:() => console.log("Not implemented"), icon:<div className='text-gray-300 w-8 h-8'><RefreshIcon iconColor='currentColor' iconHeight='h-full' iconWidth='w-full'/></div>},
        ]} defaultDials={true} currentContext='scenarios' />
    </div>
  )
}

export default ScenarioViewer

// interface addCategoryWindowProps {
//     setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
// }

const AddCategoryWindow = () => {
    const [open, setOpen] = useState(false)
    const [categoryTitle, setCategoryTitle] = useState<string | null>("")
    return (
        <>
            <li
            className={`flex space-x-2 items-center`}>
                { open ? <div className='h-10 items-center flex'><TextInput textValue={categoryTitle} setTextValue={setCategoryTitle} /></div> : <></>}
                { open ? <MainButton height='h-10' text={'Save'} link={''} /> : <></>}
                <div onClick={() => setOpen(prev => !prev)} className='p-2 rounded-md border bg-gray-50 w-32 cursor-pointer hover:shadow'>{ open ? "Close" : "Add category"}</div>
            </li>
        </>
    )
  }