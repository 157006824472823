import React, { useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom';
import { getSelectionPresentation } from '../../../../../../../data/SelectionPortalRequests';
import { ArrowLeftIcon, LoadingSpinner, MessageIcon, ProfileFilledIcon, SettingsFilledIcon } from 'swap-frontend-library';
import SidebarDetails from '../participation-screen/features/SidebarDetails';
import SidebarParticipants from '../participation-screen/features/SidebarParticipants';
import SidebarChat from '../participation-screen/features/SidebarChat';
import ScenarioListWrapper from './features/ScenarioListWrapper';

export const loader = async ({ params } : any) : Promise<String> => {
  if (params.presentationId) {
      return params.presentationId;
  } else {
      return "";
  }
}

const ResultEvaluationScreen = () => {
  const presentationId : string = String(useLoaderData());
  const [meetingItem, setMeetingItem] = useState<any>();
  const [sidebarContent, setSidebarContent] = useState("");
  const [messages, setMessages] = useState<any[]>([]);

  const handleGetSelectionPresentation = async () => {
    // console.log(presentationId);
    const response = await getSelectionPresentation(presentationId);
    if (response.ok) {
      setMeetingItem(response.data.data);

      const oldChatMessages = response.data.data.selectionPresentationChatMessages;
      if (typeof oldChatMessages !== "undefined") setMessages(oldChatMessages);
    }
  }

  useEffect(() => {
    handleGetSelectionPresentation();
  }, [])

  if (typeof meetingItem == "undefined") {
    return (<LoadingSpinner />)
  }

  const dateParsed : Date = new Date(meetingItem.timeFrom);
  const dateString : string = `${dateParsed.getDate().toString().padStart(2, '0')}.${(dateParsed.getMonth()+1).toString().padStart(2, '0')}.${dateParsed.getFullYear()}`;
  
  const timeFrom = `${(new Date(meetingItem.timeFrom)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeFrom)).getUTCMinutes().toString().padStart(2, '0')}`;
  const timeTo = `${(new Date(meetingItem.timeTo)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeTo)).getUTCMinutes().toString().padStart(2, '0')}`;
  
  return (
    <div className='h-full w-full flex flex-col'>
      <div className="relative pl-16 pr-3 w-full h-16 flex items-center gap-3 py-2.5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5">
        <Link to={`../`} className='absolute left-3 items-center justify-start cursor-pointer w-fit hover:bg-gray-50 p-2 rounded text-gray-400'>
            <div className='w-4 h-4'><ArrowLeftIcon/></div>
        </Link>

        <div className='w-full flex items-center justify-between'>
            <div className='flex-1 min-w-0'> 
                <div className='truncate flex items-center'>
                    <span className='font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.vendorDetails.company}</span>
                    <span className='ml-1 font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.productName}</span>
                </div>
                <div className='flex gap-1'>
                    <p className='text-sm font-light'>{meetingItem.title || "Vendor presentation"},</p>
                    <p className='text-sm font-light'>{dateString},</p>
                    <p className='text-sm font-light'>{timeFrom} h - {timeTo} h</p>
                </div>

            </div>

            <div className='flex items-center space-x-2 w-fit flex-shrink-0'>
                {
                    meetingItem.presentationStatus == "planned" ? (<div className='xl:w-56 w-32 h-16 p-2'><div className='border-2 border-dashed border-gray-400 bg-gray-400/10 h-full w-full rounded flex items-center justify-center text-center font-semibold text-gray-400'>Presentation planned</div></div>) :
                    meetingItem.presentationStatus == "completed" ? (<div className='xl:w-56 w-32 h-16 p-2'><div className='border-2 border-dashed border-primary bg-primary/10 h-full w-full rounded flex items-center justify-center text-center font-semibold text-primary'>Presentation completed</div></div>) :
                    meetingItem.presentationStatus == "canceled" ? (<div className='xl:w-56 w-32 h-16 p-2'><div className='border-2 border-dashed border-red-400 bg-red-400/10 h-full w-full rounded flex items-center justify-center text-center font-semibold text-red-400'>Presentation canceled</div></div>)
                    : <></>
                }
                <div onClick={() => {setSidebarContent(prev => (prev == "details") ? ("") : ("details"));}}
                className={`cursor-pointer flex items-center text-gray-400 justify-center h-10 w-10 p-1.5 rounded-full ${sidebarContent == "details" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                    <SettingsFilledIcon />
                    {/* {sidebarContent == "analysis" ? "white" : "currentColor"} */}
                </div>
                <div onClick={() => {setSidebarContent(prev => (prev == "participants") ? ("") : ("participants"));}}
                className={`cursor-pointer flex items-center text-gray-400 border border-transparent justify-center h-10 w-10 p-1 rounded-full ${sidebarContent == "participants" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                    <ProfileFilledIcon />
                </div>
                <div onClick={() => {setSidebarContent(prev => (prev == "chat") ? ("") : ("chat"));}}
                className={`relative cursor-pointer text-gray-400 p-1 flex items-center border border-transparent justify-center h-10 w-10 rounded-full ${sidebarContent == "chat" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                    <MessageIcon />
                    <div></div>
                </div>
            </div>
        </div>
      </div>
      <div className='relative h-full grow flex flex-col overflow-y-hidden'>
          <div className='w-full overflow-y-auto'>
            <ScenarioListWrapper presentationId={meetingItem.id} meetingItem={meetingItem} />
          </div>
          {
              sidebarContent == "" ? (<></>) : (
                  <div className='absolute shadow-lg right-0 h-full flex flex-col bg-white w-fit border-l'>
                      <div className='absolute h-16 text-2xl p-4 font-semibold w-96'>{sidebarContent.slice(0,1).toUpperCase()+sidebarContent.slice(1)}</div>
                      <div className='p-4 pt-16 flex h-full'>
                          {
                              (sidebarContent == "details") ? (<SidebarDetails presentationId={presentationId} meetingItem={meetingItem} handleGetSelectionPresentation={handleGetSelectionPresentation} />) :
                              (sidebarContent == "participants") ? (<SidebarParticipants participants={meetingItem.selectionPresentationParticipant} presentationId={presentationId} handleGetSelectionPresentation={handleGetSelectionPresentation} />) :
                              (sidebarContent == "chat") ? (<SidebarChat messages={messages} presentationId={presentationId} />) : <></>
                          }
                      </div>
                  </div>
              )
          }
      </div>
    </div>
  )
}

export default ResultEvaluationScreen