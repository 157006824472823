import React, { useEffect, useState } from 'react'
import PercentageCircle from '../../../../../../components/PercentageCircle'
import { getImage } from '../../../../../../data/ProductRequests'
import { Link } from 'react-router-dom'

interface props {
    vendorRankingData : any
    index: number
    currentAvailableScore: string
    setSidebarOpenWithRankingData: React.Dispatch<React.SetStateAction<any | undefined>>;
}

const VendorRankingRow = ({vendorRankingData, index, currentAvailableScore, setSidebarOpenWithRankingData} : props) => {
    const softwareProductId = vendorRankingData.softwareProduct.id;
    const [imageSource, setImageSource] = useState("");
    
    const handleFetchImage = async () => {
        getImage(vendorRankingData.softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    }

    useEffect(() => {
        handleFetchImage();
    }, [vendorRankingData])
    // ${(typeof customerSearchSoftwareProductId !== "undefined") ? (`&csspId=${customerSearchSoftwareProductId}`) : ("")}`}
  return (
    // <Link to={(softwareProductId=="") ? ("#") : (`/product/?productId=${softwareProductId}&softwareRankingId=${vendorRankingData.id}`)} target={(softwareProductId=="") ? ("") : ("_blank")} rel="noopener noreferrer" >
    <div onClick={() => setSidebarOpenWithRankingData(vendorRankingData)}
    key={index} className={`flex flex-col cursor-pointer rounded-lg shadow ${vendorRankingData.included ? "bg-white" : "bg-gray-100"} max-md:max-w-full h-32 hover:bg-gray-50`}>
        <div className='flex space-x-4 h-full p-4'>
            
            {/* Ranking */}
            <div className='h-full flex items-center'>
                <div className={`${vendorRankingData.included ? "bg-primary" : "bg-gray-300"} rounded-full w-[45px] h-[45px] flex items-center justify-center text-white text-xl font-bold`}>
                    {index+1}
                </div>
            </div>
            
            {/* Logo */}
            <div className='h-full w-[150px] flex items-center justify-center max-sm:hidden'>
                {
                    (imageSource == "") ? (
                        <div className='h-full w-full p-4 flex items-center text-center justify-center font-bold text-white bg-gray-300 rounded'>
                            {vendorRankingData.softwareProduct.vendorDetails.company}
                        </div>
                    ) : (
                        <img className="max-h-full" src={imageSource} />
                    )
                }
            </div>

            {/* Vendor/System name */}
            <div className='flex grow max-sm:max-w-1/2 items-center h-full'>
                <div>
                    <div className='flex space-x-4 text-lg font-bold'>
                        <p>{vendorRankingData.softwareProduct.productName} </p>
                        {/* <p className='font-light italic'>{vendorRankingData.softwareFulfillment.note}</p> */}
                    </div>
                    <div>
                    {vendorRankingData.softwareProduct.vendorDetails.company}
                    </div>
                </div>
            </div>

            {/* Scores */}
            <div className='flex max-sm:max-w-1/2'>
                <div className={`sm:w-32 border-l px-2 flex flex-col h-full items-center ${(currentAvailableScore == "preselection") ? "" : "max-xl:hidden" }`}>
                    <div className='max-sm:text-xs'>
                        Preselection
                    </div>
                    <div className='flex grow justify-center items-center relative'>
                        <PercentageCircle rating={vendorRankingData.score_preselection} />
                        {(vendorRankingData.score_preselection == null) ? (
                            <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                       ) : (<></>)}
                    </div>
                </div>

                <div className={`sm:w-32 border-l px-2 flex flex-col h-full items-center ${(currentAvailableScore == "requirements") ? "" : "max-xl:hidden" }`}>
                    <div className='max-sm:text-xs'>
                        Requirements
                    </div>
                    <div className='flex grow justify-center items-center relative'>
                        <PercentageCircle rating={vendorRankingData.score_requirements} />
                        {(vendorRankingData.score_requirements == null) ? (
                            <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                       ) : (<></>)}
                    </div>
                </div>
                
                <div className={`sm:w-32 border-l px-2 flex flex-col h-full items-center ${(currentAvailableScore == "webcast") ? "" : "max-xl:hidden" }`}>
                    <div className='max-sm:text-xs'>
                        Webcasts
                    </div>
                    <div className='flex grow justify-center items-center relative'>
                        <PercentageCircle rating={vendorRankingData.score_webcast} />
                        {(vendorRankingData.score_webcast == null) ? (
                            <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                       ) : (<></>)}
                    </div>
                </div>
                
                <div className={`sm:w-32 border-l px-2 flex flex-col h-full items-center ${(currentAvailableScore == "presentation") ? "" : "max-xl:hidden" }`}>
                    <div className='max-sm:text-xs'>
                        Presentations
                    </div>
                    <div className='flex grow justify-center items-center relative'>
                        <PercentageCircle rating={vendorRankingData.score_presentation} />
                       {(vendorRankingData.score_presentation == null) ? (
                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                       ) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    </div>
    // </Link>
  )
}

export default VendorRankingRow