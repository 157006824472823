import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { fetchVendorAccountData } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import { fetchTransactionHistory } from '../../../../../../data/PaymentRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import SearchBar from '../../../../../../components/SearchBar';
import DropdownMenu from '../../../../../../components/DropdownMenu';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';

{/* 
<span
    className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-green-100"
    >Completed</span
>

<span
    className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-red-100"
    >Cancelled</span
>

<span
    className="bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-purple-100"
    >In progress</span
>

<span
    className="bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-orange-100"
    >In review</span
>
*/}


const TransactionHistoryTable = () => {
    const { t } = useTranslation();
    const [transactionHistroy, setTransactionHistroy] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);
    const [dropdownFilterValues, setDropdownFilterValues] = useState<any>()

    const [loadError, setLoadError] = useState(false);

    useEffect(() => {
        handleFetchTransactionHistory();

    }, [])

    const handleFetchTransactionHistory = () => {
        fetchTransactionHistory().then(data => {
            setLoadError(false);
            if (typeof data !== "undefined") {
                setTransactionHistroy(data); 
                setFilteredData(data);
    
                const uniqueStatus = data.map((obj:any, idx:number) => (obj.status)).filter((obj:any, idx:number, arr:any[]) => arr.indexOf(obj) === idx);
                const uniqueOdertype = data.map((obj:any, idx:number) => (obj.order_type)).filter((obj:any, idx:number, arr:any[]) => arr.indexOf(obj) === idx);
                setDropdownFilterValues({uniqueStatus, uniqueOdertype})
            } else {
                setLoadError(true);
            }
        });
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            {t("transactionHistory")}s
            <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length: transactionHistroy.length, itemType: `${t("transaction")}s`})}</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    <SearchBar translatedPlaceholderItem={`${t("transaction")}`} originalData={transactionHistroy} setFilteredData={setFilteredData} filterKey={"id"} />
                </div>
            
            <div className="flex items-center ml-auto space-x-2 sm:space-x-3">

                {
                    (typeof dropdownFilterValues !== "undefined") ? (
                        <>
                            <DropdownMenu options={dropdownFilterValues.uniqueStatus} label="" filteredData={filteredData} filterKey='status' setFilteredData={setFilteredData} originalData={transactionHistroy} />
                            <DropdownMenu options={dropdownFilterValues.uniqueOdertype} label="" filteredData={filteredData} filterKey='order_type' setFilteredData={setFilteredData} originalData={transactionHistroy} />
                        </>
                    ) : (<></>)
                }
                
                <button
                onClick={() => handleFetchTransactionHistory()}
                type="button"
                data-refresh
                className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
                </button>
            </div>
            </div>
        </div>
        </div>
        {/* "id": "pi_3NAza1FFUlLi6sIf0eRfN72g",
            "order_type": "query-order",
            "amount": 19999,
            "amount_received": 19999,
            "currency": "eur",
            "created": "23.5.2023 20:05:05",
            "status": "succeeded" */}

        {(transactionHistroy.length == 0) ? ((loadError) ? (<p className='p-5'>Beim Laden der Transaktionen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>) : (<LoadingSpinner />)) : (
            <>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <TableHeader header={["Stripe Id", `${t("orderType")}`, `${t("status")}`, `${t("amount")}`, `${t("createdOn")}`]} />
                </thead>
                {
                    filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, order_type, amount, amount_received, currency, created, status} : any, index : number) => {
                        return (
                            <tbody>
                                <TableRow rowItems={[
                                    index, 
                                    id, 
                                    String(order_type).replaceAll("-", " ").toLocaleUpperCase(), 
                                    String(status).replaceAll("_", " ").toLocaleUpperCase(), 
                                    `${amount / 100} ${String(currency).toLocaleUpperCase()}`, 
                                    created]} />
                            </tbody>
                        )
                    })
                }
            </table>
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

export default TransactionHistoryTable;