import React, { useEffect, useState } from 'react';
import { addVendorDetails } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';

const AddVendorDetailsForm = () => {
    const { t } = useTranslation();

    const [company, setCompany] = useState("")
    const [companyUrl, setCompanyUrl] = useState("")
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);

    const handleAddVendorDetails = async () => {
        if (await addVendorDetails(company, companyUrl)) { setCreatedSuccessfully(true); }
    }
   
    return (
    <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900">{t("createVendor")}</h3>
        {(createdSuccessfully) ? (<p>{t("createdSuccessfully")}</p>) : (
            <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("companyName")}</label>
                    <input placeholder="Company name" onChange={(event) => {setCompany(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("vendorDescription")}</label>
                    <textarea name="" id="" placeholder={`${t("vendorDescription")}`} rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required></textarea>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("companyUrl")}</label>
                    <input placeholder={`${t("companyUrl")}`} onChange={(event) => {setCompanyUrl(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("headquaterCity")}</label>
                    <input placeholder={`${t("headquaterCity")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" ></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("headquaterStreet")}</label>
                    <input placeholder={`${t("headquaterStreet")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" ></input>
                </div>
                <div className='w-full'><MainButton text={t("save")} link="" func={handleAddVendorDetails} /></div>
            </div>
        )}
    </div>
  )
}

export default AddVendorDetailsForm