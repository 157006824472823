import React, { useState } from 'react'
import LargeUploadIcon from '../assets/img/icons/LargeUploadIcon';
import { useTranslation } from 'react-i18next';

interface props {
    setUploadedFile : React.Dispatch<React.SetStateAction<string | ArrayBuffer | null | undefined>>;
    setFilename? : React.Dispatch<React.SetStateAction<string>>;
    fileType: string
    maxFileSize: string,
}

const DropzoneUpload = ({setUploadedFile, setFilename, fileType, maxFileSize} : props) => {
    const {t} = useTranslation();
    const [dragActive, setDragActive] = useState(false);

    const handleDrag = (e : any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleUpload = async (e : any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        const fileReader = new FileReader();
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (typeof setFilename !== "undefined") setFilename(e.dataTransfer.files[0].name);
            if (fileType == "JSON") { fileReader.readAsText(e.dataTransfer.files[0], "UTF-8"); }
            else if (fileType == "PDF") { fileReader.readAsBinaryString(e.dataTransfer.files[0]); }
        } else if (e.target && e.target.files && e.target.files[0]) {
            if (typeof setFilename !== "undefined") setFilename(e.target.files[0]);
            if (fileType == "JSON") { fileReader.readAsText(e.target.files[0], "UTF-8"); }
            else if (fileType == "PDF") { fileReader.readAsBinaryString(e.dataTransfer.files[0]); }
        } else {
            console.error("Error: Upload file in dropzone failed. No file found.")
        }
        fileReader.onload = e => {
            if (e.target != null) {
                setUploadedFile(e.target.result);
            }
        };
    }

  return (
    <div className="flex items-center justify-center w-full">
        <label onDragOver={handleDrag} onDragLeave={handleDrag} onDropCapture={handleUpload}
        htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-secondary5 ${(dragActive) ? ("bg-sky-50") :("")} hover:bg-secondary6`}>
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <div className='w-10 h-10 mb-3'><LargeUploadIcon iconColor='currentColor' /></div>
                <p className="mb-2 text-wrap px-5 text-sm text-center text-gray-500">{t("uploadDragAndDrop")}</p>
                <p className="text-xs text-gray-500 text-center">{fileType} (max. {maxFileSize})</p>
            </div>
            <input onChange={handleUpload}
            id="dropzone-file" type="file" className="hidden" />
        </label>
    </div> 
  )
}

export default DropzoneUpload