import React, { useEffect, useState } from 'react'
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon'
import ArrowLeftIcon from '../../../../../../assets/img/icons/ArrowLeftIcon'
import { fetchAllSoftwareFulfillments, syncFulfillmentRemoveFromMatcher, syncFulfillmentWithMatcher } from '../../../../../../data/SoftwareFulfillmentRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import SearchBar from '../../../../../../components/SearchBar';

const ExportSoftwareProductFunctions = () => {
  const [softwareFulfillmentData, setSoftwareFulfillmentData] = useState<any>([]);
  
  const [filteredSoftwareFulfillmentsMatching, setFilteredSoftwareFulfillmentsMatching] = useState<any>([]);
  const [filteredSoftwareFulfillmentsPlatform, setFilteredSoftwareFulfillmentsPlatform] = useState<any>([]);
  
  const [softwareFulfillmentIdsMatching, setSoftwareFulfillmentIdsMatching] = useState<any>([]);
  const [softwareFulfillmentIdsPlatform, setSoftwareFulfillmentIdPlatform] = useState<any>([]);
  
  const [softwareFulfillmentMatchingCounts, setSoftwareFulfillmentMatchingCounts] = useState<any>();

  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleReloadSoftwareFulfillment();
  }, [])

  const handleReloadSoftwareFulfillment = async () => {
    setSoftwareFulfillmentData([]);
    const data = await fetchAllSoftwareFulfillments();

    if (typeof data.softwareProductFulfillments === "undefined" || typeof data.softwareProductFulfillmentMatcher === "undefined") {
      setSoftwareFulfillmentData({softwareProductFulfillmentMatcher: [], softwareProductFulfillments:[]})
      return
    } else {
      setSoftwareFulfillmentData(data);
    }

    setFilteredSoftwareFulfillmentsMatching(data.softwareProductFulfillmentMatcher);
    setFilteredSoftwareFulfillmentsPlatform(data.softwareProductFulfillments);

    let tempSoftwareFulfillmentIdPlatform;
    if (typeof data.softwareProductFulfillments === "undefined" || data.softwareProductFulfillments.length == 0) {
      tempSoftwareFulfillmentIdPlatform = []
      setSoftwareFulfillmentIdsMatching([]);
    } else {
      tempSoftwareFulfillmentIdPlatform = data.softwareProductFulfillments.map((item : any) => item.softwareFulfillment_id)
      setSoftwareFulfillmentIdPlatform(tempSoftwareFulfillmentIdPlatform);
    } 

    let tempSoftwareFulfillmentIdMatcher;
    if (typeof data.softwareProductFulfillmentMatcher === "undefined" || data.softwareProductFulfillmentMatcher.length == 0) {
      tempSoftwareFulfillmentIdMatcher = []
      setSoftwareFulfillmentIdsMatching([]);
    } else {
      tempSoftwareFulfillmentIdMatcher = data.softwareProductFulfillmentMatcher.map((item : any) => item.softwareFulfillmentId)
      setSoftwareFulfillmentIdsMatching(tempSoftwareFulfillmentIdMatcher);
    }

    count(data, tempSoftwareFulfillmentIdPlatform, tempSoftwareFulfillmentIdMatcher);

    setSelectedSoftwareFulfillmentPlatform([]);
    setSelectedSoftwareFulfillmentMatcher([]);
    setLoading(false);
  }

  const count = (data: any, platformIds: any, matcherIds: any) => {
    if (typeof data.softwareProductFulfillmentMatcher === "undefined") return;
    const itemsMatchingInDB = data.softwareProductFulfillmentMatcher.map((item : any) => { if (platformIds.includes(item.softwareFulfillmentId)) {return 1;} else {return 0;}}).reduce((partialSum: number, a: number) => partialSum + a, 0);

    setSoftwareFulfillmentMatchingCounts({itemsMatching: matcherIds.length, itemsMatchingInDB});
    console.log({itemsMatching: matcherIds.length, itemsMatchingInDB})
  }

  // Selection functions

  const [selectedSoftwareFulfillmentPlatform, setSelectedSoftwareFulfillmentPlatform] = useState<any[]>([]);
  const [selectedSoftwareFulfillmentMatcher, setSelectedSoftwareFulfillmentMatcher] = useState<any[]>([]);

  const handleSelectItem = (e : any, platform: boolean) => {
    if (e.target.checked) {
      if (platform) {
        console.log("Add " + e.target.value + " to platform selection")
        if (!selectedSoftwareFulfillmentPlatform.includes(e.target.value)) {
          setSelectedSoftwareFulfillmentPlatform(prev => [...prev, e.target.value])
        }
      } else {
        console.log("Add " + e.target.value + " to matcher selection")
        if (!selectedSoftwareFulfillmentMatcher.includes(e.target.value)) {
          setSelectedSoftwareFulfillmentMatcher(prev => [...prev, e.target.value])
        }
      }
    } else {
      if (platform) {
        console.log("Remove " + e.target.value + " from platform selection")
        setSelectedSoftwareFulfillmentPlatform(selectedSoftwareFulfillmentPlatform.filter((item: string) => item !== e.target.value))
      } else {
        console.log("Remove " + e.target.value + " from matcher selection")
        setSelectedSoftwareFulfillmentMatcher(selectedSoftwareFulfillmentMatcher.filter((item: string) => item !== e.target.value))
      }
    }
  }

  const handleSelectAllItems = (e : any, platform: boolean) => {
    if (e.target.checked) {
      if (platform) {
        const selectableItems = softwareFulfillmentData.softwareProductFulfillments.filter((item : any) => !softwareFulfillmentIdsMatching.includes(item.softwareFulfillment_id)).map((item:any) => item.softwareFulfillment_id);
        setSelectedSoftwareFulfillmentPlatform(selectableItems);
      } else {
        setSelectedSoftwareFulfillmentMatcher(softwareFulfillmentIdsMatching);
      }
    } else {
      if (platform) {
        setSelectedSoftwareFulfillmentPlatform([]);
      } else {
        setSelectedSoftwareFulfillmentMatcher([]);
      }
    }
  }


  // Submit functions

  const handleSyncFulfillmentsWithMatcher = async () => {
    if (selectedSoftwareFulfillmentPlatform.length != 0) {
      setLoading(true);
      await syncFulfillmentWithMatcher(selectedSoftwareFulfillmentPlatform);
      handleReloadSoftwareFulfillment();
      setLoading(false);
    }
  }
  
  const handleSyncFulfillmentsWithPlatform = async () => {
    if (selectedSoftwareFulfillmentMatcher.length != 0) {
      setLoading(true);
      await syncFulfillmentRemoveFromMatcher(selectedSoftwareFulfillmentMatcher);
      handleReloadSoftwareFulfillment();
      setLoading(false);
    }

  }

  console.log(softwareFulfillmentData.softwareProductFulfillments)
  console.log(softwareFulfillmentData.softwareProductFulfillmentMatcher)
  console.log(loading)

  return (
    <div className="relative bg-white overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            Funktionskataloge synchronisieren
            <p className="mt-1 text-sm font-normal text-gray-500">Nutzen Sie diese Funktion, um ausgewählte Funktionskataloge an den Matching-Algorithmus zu versenden.</p>
        </div>

        {(typeof softwareFulfillmentData.softwareProductFulfillments === "undefined" || typeof softwareFulfillmentData.softwareProductFulfillmentMatcher === "undefined" || loading) ? (<LoadingSpinner />) : (
          <>
            <div className="flex space-x-5 w-full px-5">
              <div className="text-sm font-semibold text-left text-gray-900 w-full">
                Gespeicherte Funktionskataloge
                <p className='my-1 text-sm font-normal text-gray-500'>
                Funktionskataloge die auf Plattform gespeichert sind. 
                Funktionskataloge die auf der Plattform gespeicher werden, können im Matching verwendet werden (optional). 
                Funktionskataloge die aktuell verwendet werden sind ausgewählt. Um diese Abzuwählen (also nicht mehr im Matching zu verwenden) müssen sie im rechten Fenster entfernt werden.
                Um weitere Funktionskataoge im Matching zu verwenden, wählen Sie die entsprechenden Funktionskataloge aus und bestätigen Sie über den Pfeil nach rechts.
                </p>
              </div>
              
              <div className='w-[30px]'>
              </div>

              <div className="text-sm font-semibold text-left text-gray-900 w-full">
                Verwendete Funktionskataloge im Matching 
                <p className='my-1 text-sm font-normal text-gray-500'>
                Funktionskataloge die im Matching verwendet werden. 
                Funktionskataloge die im Matching verwendet werden müssen auf der Plattform gespeicher sein (erforderlich). 
                Aktuell sind <strong>{softwareFulfillmentMatchingCounts?.itemsMatchingInDB || 0}/{softwareFulfillmentMatchingCounts?.itemsMatching || 0}</strong> im Matcher verwendete Funktionskataloge auf der Plattform gespeichert.
                Wählen Sie hier Funktionskataloge aus die nicht mehr im Matching verwendet werden sollen und bestätigen Sie durch den Pfeil nach links.
                </p>
              </div>
            </div>

            <div className="flex space-x-5 w-full p-5 pb-10">
              <div className='w-full'>
                <div className='w-full p-5 rounded-t-lg border overflow-y-auto flex space-x-3 items-center'>
                  <input id="" onChange={(e) => handleSelectAllItems(e, true)} title="Alle auswählen" type="checkbox" value={""} className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                  <div className='w-full'>
                    <SearchBar translatedPlaceholderItem={'Suche nach Softwareprodukten'} originalData={softwareFulfillmentData.softwareProductFulfillments} setFilteredData={setFilteredSoftwareFulfillmentsPlatform} filterKey="softwareProduct_productName" />
                  </div>
                </div>
                <div className='h-[300px] w-full p-5 border rounded-b-lg overflow-y-auto shadow-inner'>
                  {
                    (filteredSoftwareFulfillmentsPlatform.length == 0) ? (<></>) : (
                      filteredSoftwareFulfillmentsPlatform.map((item: any, index: number) => {
                        const inMatching = softwareFulfillmentIdsMatching.includes(item.softwareFulfillment_id);
                        return (
                        <div key={item.softwareFulfillment_id} className="relative flex items-center group border-b border-secondary6">
                          <input 
                            id={item.softwareFulfillment_id + "a"} 
                            type="checkbox" 
                            checked={inMatching || selectedSoftwareFulfillmentPlatform.includes(item.softwareFulfillment_id)}
                            value={item.softwareFulfillment_id} 
                            disabled={inMatching}
                            onChange={(e) => handleSelectItem(e, true)}
                            className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                          <label htmlFor={item.softwareFulfillment_id + "a"}  className='cursor-pointer ml-3 rounded p-2 w-full '>
                            <div className='text-sm font-light'>{item.vendorDetails_company}</div>
                            <div className="text-sm font-medium text-gray-900">{item.softwareProduct_productName} - Katalogversion {item.softwareFulfillment_version} ({item.softwareFulfillment_note})</div>
                          </label>
                          {/* {item.vendorDetails_company} {item.softwareFulfillment_id} {item.softwareFulfillment_note} */}
                        </div>
                        )})
                    )
                  }
                </div>
              </div>
              
              <div className='h-full my-auto space-y-5'>
                <div onClick={handleSyncFulfillmentsWithMatcher} className='cursor-pointer bg-primary border border-primary text-white hover:bg-white hover:text-primary w-[30px] h-[30px] flex items-center justify-center rounded-full'><ArrowRightIcon iconColor='currentColor' /></div>
                <div onClick={handleSyncFulfillmentsWithPlatform} className='cursor-pointer bg-primary border border-primary text-white hover:bg-white hover:text-primary w-[30px] h-[30px] flex items-center justify-center rounded-full'><ArrowLeftIcon iconColor='currentColor' /></div>
              </div>

              <div className='w-full'>
                <div className='w-full p-5 rounded-t-lg border overflow-y-auto flex space-x-3 items-center'>
                  <input id="" title="Alle auswählen" onChange={(e) => handleSelectAllItems(e, false)} type="checkbox" value={""} className="cursor-pointer w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                  <div className='w-full'>
                    <SearchBar translatedPlaceholderItem={'Suche nach Softwareprodukten'} originalData={softwareFulfillmentData.softwareProductFulfillmentMatcher} setFilteredData={setFilteredSoftwareFulfillmentsMatching} filterKey="productName" />
                  </div>
                </div>
                <div className='h-[300px] w-full p-5 rounded-b-lg border overflow-y-auto shadow-inner'>
                  {
                    (filteredSoftwareFulfillmentsMatching.length == 0) ? (<></>) : (
                      filteredSoftwareFulfillmentsMatching.map((item: any, index: number) => {
                        const inPlatform = softwareFulfillmentIdsPlatform.includes(item.softwareFulfillmentId);
                        return (
                        <div key={item.softwareFulfillmentId} className="relative flex items-center group border-b border-secondary6">
                          <input 
                            id={item.softwareFulfillmentId + "b"} 
                            type="checkbox"
                            value={item.softwareFulfillmentId}
                            checked={selectedSoftwareFulfillmentMatcher.includes(item.softwareFulfillmentId)}
                            onChange={(e) => handleSelectItem(e, false)}
                            className={`w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2`} />
                          <label htmlFor={item.softwareFulfillmentId + "b"} className={`cursor-pointer ml-3 rounded p-2 w-full`}>
                            <div className={`text-sm font-medium ${(inPlatform) ? ("text-gray-900") : ("text-red-500")}`}>{item.productName} - Katalogversion {item.version} ({item.note})</div>
                          </label>
                          {/* {item.vendorDetails_company} {item.softwareFulfillment_id} {item.softwareFulfillment_note} */}
                        </div>
                      )})
                    )
                  }
                </div>
              </div>
            </div>
          </>
            

        )}
          

          
    </div>
  )
}

export default ExportSoftwareProductFunctions