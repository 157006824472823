import React, { useState } from 'react'
import ArrowRightIcon from '../../../../../../../assets/img/icons/ArrowRightIcon'
import { updateSelectionScenarioTaskCategory, updateSelectionScenarioTaskCategoryOrder } from '../../../../../../../data/SelectionPortalRequests'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/store'

interface props {
    setSelectedCategory: React.Dispatch<React.SetStateAction<string>>,
    scenarioCategory: any,
    index: number,
    selectedCategory: any
    sortedUniqueCategories: any[],
    handleFetchScenarios: () => void,
    isLastItem: boolean
}

const ScenarioTaskCategoryTab = ({scenarioCategory, setSelectedCategory, index, sortedUniqueCategories, selectedCategory, handleFetchScenarios, isLastItem} : props) => {
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [dragOverActive, setDragOverActive] = useState(false);
    const [dragOverActiveLast, setDragOverActiveLast] = useState(false);
    const [editScenarioTaskCategoryTitle, setEditScenarioTaskCategoryTitle] = useState(false);
    const [newScenarioTaskCategoryTitle, setNewScenarioTaskCategoryTitle] = useState<string | null>(null);
    
    // A: This scenario cards gets rearranged
    const dragStart = (e : any, id : string) => {
        e.dataTransfer.setData("id", id);
    }

    // B: Some other scenario card gets rearranged and dragged/dropped over this scenario card
    const enterDrop = (e : any, last?: boolean) => {
        e.preventDefault();
        if (last) { setDragOverActiveLast(true); return; }
        setDragOverActive(true);
    }
    
    const leaveDrop = (e : any, last?: boolean) => {
        e.preventDefault();
        if (last) { setDragOverActiveLast(false); return; }
        setDragOverActive(false);
    }
    
    const drop = async (e : any, last?: boolean) => {
        e.preventDefault();

        const id = e.dataTransfer.getData("id");
        setDragOverActive(false);
        setDragOverActiveLast(false);

        if (id != scenarioCategory.id) {
            let newCleanOrderNumber : number;
            if (last) {
                newCleanOrderNumber = scenarioCategory.cleanOrderNumber+1;
            } else {
                newCleanOrderNumber = scenarioCategory.cleanOrderNumber;
            }
            
            if ((await updateSelectionScenarioTaskCategoryOrder(id, newCleanOrderNumber)).ok) {
                handleFetchScenarios();
            }
        }
        
    }

    const handleUpdateScenarioTaskCategoryTitle = async () => {
        if (newScenarioTaskCategoryTitle == null) return;
        if (!editScenarioTaskCategoryTitle) return;
        if ((await updateSelectionScenarioTaskCategory(scenarioCategory.id, newScenarioTaskCategoryTitle)).ok) {
            handleFetchScenarios();
            setEditScenarioTaskCategoryTitle(false);
        }
    }

    return (
    <>
    <li draggable={projectRole == "selection_admin"}

    // A: Gets dragged
    onDragStart={(e) => dragStart(e, scenarioCategory.id)}
        
    // B: Is dropzone
    onDragOver={(e) => enterDrop(e)} 
    onDragLeave={(e) => leaveDrop(e)} 
    onDrop={(e) => drop(e)}
    onClick={() => setSelectedCategory(scenarioCategory)}
    onDoubleClick={() => {if (selectedCategory == scenarioCategory && projectRole == "selection_admin") {setEditScenarioTaskCategoryTitle(true);}}}
    className={`flex ${dragOverActive ? "border-l-4 border-gray-300": "border-t border-l border-r"} items-center h-16 ml-1 px-4 cursor-pointer ${scenarioCategory.id == selectedCategory.id ? "text-primary bg-white rounded-t-lg border-t border-l border-r border-gray-300" : "border-gray-100"}`}>
        <span className="flex items-center justify-center w-5 h-5 me-2 text-xs border border-blue-600 rounded-full shrink-0">
            {scenarioCategory.cleanOrderNumber}
        </span>

        <input 
        readOnly={!editScenarioTaskCategoryTitle}
        onBlur={() => handleUpdateScenarioTaskCategoryTitle()} 
        className="w-fit cursor-pointer bg-transparent" value={newScenarioTaskCategoryTitle != null ? newScenarioTaskCategoryTitle : scenarioCategory.title} onChange={(e) => setNewScenarioTaskCategoryTitle(e.target.value)}/>

        {/* {scenarioCategory.title} */}

        {
            (index+1 == sortedUniqueCategories.length) ? (<></>) : (
                <div className='ms-2 '><ArrowRightIcon iconColor='currentColor' iconHeight='0.75rem' iconWidth='0.75rem'/></div>
            )
        }
    </li>
    {
        isLastItem ? 
            (
                <li>
                    <td
                    onDragOver={(e) => enterDrop(e, true)} 
                    onDragLeave={(e) => leaveDrop(e, true)} 
                    onDrop={(e) => drop(e, true)}
                    className={`w-8 h-16 ${dragOverActiveLast ? "border-l-4 border-gray-300": ""}`}>
                    </td>
                </li>
            ): <></>
    }
    </>
  )
}

export default ScenarioTaskCategoryTab