import React, { useEffect, useState } from 'react'
import SidebarElement from '../../components/SidebarElement'
import { RootState } from '../../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getSelectionPhase, getUserSelectionRole } from '../../../../data/SelectionPortalRequests'
import SearchProcessSection from '../../../../components/SearchProcessSection'
import MainButton from '../../../../components/MainButton'
import { setProjectRole } from '../../../../redux/selectionSlice'
import { ArrowLeftIcon, ArrowRightIcon, CakeChartIcon, CheckmarkIcon, InboxFilledIcon, ListFilledIcon, LoadingSpinner, LockIcon, SettingsFilledIcon } from 'swap-frontend-library'

export const loader = async ({ params } : any) : Promise<String> => {
  if (params.selectionId) {
      return params.selectionId;
  } else {
      return "";
  }
}

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [collapsedSidebar, setCollapsedSidebar] = useState(false);
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [outerSidebarOpen, outerSidebarWidth, setOuterCollapsedSidebar] = useOutletContext<any>();
    const [initializationComplete, setInitializationComplete] = useState(false);
    const [selectionPhase, setSelectionPhase] = useState("");
    const [selectionTaskId, setSelectionTaskId] = useState("");
    const [selectedSelectionPhase, setSelectedSelectionPhase] = useState("");

    const SELECTION_PHASES : any = {
      preselection: 0,
      requirements: 1,
      scenarios: 2,
      presentations: 3,
      contracts: 4,
    }
    
    const SELECTION_PHASES_LINKS : any = {
      requirements: "requirement-manager",
      scenarios: "scenario-manager",
      presentations: "presentation-manager",
      contracts: "contract-manager",
    }

    const selectionId : string = String(useLoaderData());

    useEffect(() => {setOuterCollapsedSidebar(true); setInitializationComplete(true); handleFetchSelectionPhase(); handleFetchUserSelectionRole();}, [])
    
    useEffect(() => {}, [outerSidebarOpen])

    const handleFetchSelectionPhase = async () => {
      if (typeof selectionId === "undefined") return;

      const response = await getSelectionPhase(selectionId);
      if (response.ok) {
        setSelectionPhase(response.data.data.selectionPhase);

        if (typeof response.data.data.selectionSelectionPhaseTask !== "undefined") {
          // console.log(response.data.data.selectionSelectionPhaseTask[0]);
          const selectionSelectionPhaseTask = response.data.data.selectionSelectionPhaseTask[0];
          setSelectionTaskId(selectionSelectionPhaseTask.id);
        }
      }
    }
    
    const handleFetchUserSelectionRole = async () => {
      if (typeof selectionId === "undefined") return;

      const response = await getUserSelectionRole(selectionId);
      if (response.ok) {
        dispatch(setProjectRole(response.data.selectionRole));
      }
    }

    const handleCollapseSidebar = () => {
        setCollapsedSidebar(prev => !prev)
    }
    
    const menuItems = [
        {
          label: "Dashboard",
          icon: <div className='w-6 h-6'><CakeChartIcon /></div>,
          roleLink: {
            "customer:*": ""
          },
          topAlign: true,
        },
        {
          label: "Ranking",
          icon: <div className='w-6 h-6'><ListFilledIcon /></div>,
          roleLink: {
            "customer:*": "vendor-ranking"
          },
          topAlign: true
        },
        {
          label: "Preselection",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 0) ? "bg-primary" : "bg-gray-200"} rounded-full w-full h-full text-white font-bold flex items-center justify-center text-white p-1`}><div><CheckmarkIcon /></div></div>,
          roleLink: {
            "customer:*": ""
          },
          topAlign: true
        },
        {
          label: "Requirements",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 1) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>1</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["requirements"]
          },
          topAlign: true
        },
        {
          label: "Scenarios",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 2) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>2</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["scenarios"]
          },
          topAlign: true
        },
        {
          label: "Presentations",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 3) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>3</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["presentations"]
          },
          topAlign: true
        },
        {
          label: "Contract",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 4) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>4</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["contracts"]
          },
          topAlign: true
        },
        {
          label: t("inbox"),
          icon: <div className='w-6 h-6'><InboxFilledIcon /></div>,
          roleLink: { 
            "vendor": "inbox", 
            "customer:*": "inbox"
          },
          topAlign: false
        },
        {
          label: "Settings",
          icon: <div className='w-6 h-6'><SettingsFilledIcon/></div>,
          roleLink: {
            "customer:selection_admin": "settings"
          },
          topAlign: false
        },
        {
          label: t("Schließen"),
          icon: (collapsedSidebar) ? (<div className='w-4 h-4 text-gray-700'><ArrowRightIcon /></div>) : (<div className='w-4 h-4 text-gray-700'><ArrowLeftIcon /></div>),
          roleLink: {
            "platform_manager": "#", 
            "vendor": "#", 
            "customer": "#"
          },
          onClick: handleCollapseSidebar,
          topAlign: false
        }
    ]

  if (!initializationComplete) {
    return (<LoadingSpinner />)
  } else {
  return (
    <>
    <div className='h-full flex max-sm:hidden'>

      <div className="fixed z-[50] top-0 left-[50%] -translate-x-[50%] mx-auto h-20 flex justify-center items-center">
        <SearchProcessSection processStep={SELECTION_PHASES[selectionPhase]} steps={["Preselection", "Requirements", "Scenarios", "Presentations", "Contract"]} links={["", "requirement-manager", "scenario-manager", "presentation-manager", "contract-manager"]} style='mt-0 w-[854px] timeline max-lg:hidden' />
      </div>

      {/* "-translate-x-full" left-[${-500}px] ${mobileSidebarOpen ? "translate-x-0" : (`-translate-x-{200%}`)} style={{transform: mobileSidebarOpen ? "" : "translateX(-200%)"}} */}
      <aside id="logo-sidebar" className={`z-40 ${(collapsedSidebar) ? ("w-16") : ("w-fit")} h-full translate-x-[-200%] sm:translate-x-0 transition-transform bg-white border-r border-gray-200`}>
        <div className="h-full px-3 pb-4 pt-3 overflow-y-auto bg-white flex flex-col">
          <ul className="space-y-2">
            {
              menuItems.map((value: any, index: number) => {
                let roleLinkKey = "";
                if (Object.keys(value.roleLink).includes(role+":"+projectRole)) {
                  roleLinkKey = role+":"+projectRole;
                } else if (Object.keys(value.roleLink).includes(role+":*")) {
                  roleLinkKey = role+":*";
                }

                if (value.topAlign && roleLinkKey != "") {
                  return (<SidebarElement label={value.label} link={value.roleLink[roleLinkKey]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                }
              })
            }
          </ul>
          <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
            {
              menuItems.map((value: any, index: number) => {
                let roleLinkKey = "";
                if (Object.keys(value.roleLink).includes(role+":"+projectRole)) {
                  roleLinkKey = role+":"+projectRole;
                } else if (Object.keys(value.roleLink).includes(role+":*")) {
                  roleLinkKey = role+":*";
                }

                if (!value.topAlign && roleLinkKey != "") {
                  return (<SidebarElement label={value.label} link={value.roleLink[roleLinkKey]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                }
            }
            )}
          </ul>
          <div className={`grow flex items-end text-gray-300 text-sm text-light gap-1 ${collapsedSidebar ? "hidden": ""}`}>
            <span className='font-semibold'>Role: </span><span>{projectRole == "selection_admin" ? "Project lead" : "Key user"}</span>
          </div>
        </div>
      </aside>

      <div className='h-full w-full overflow-y-hidden'>
        {/* ${(collapsedSidebar) ? ("sm:ml-16") : ("sm:ml-64")}  */}
        <div className={`relative h-full w-full ${(selectedSelectionPhase != selectionPhase) && (selectedSelectionPhase != "") ? ("pt-20 border-4 border-amber-200") : ("")}`}>

          {
            (selectedSelectionPhase != selectionPhase) && (selectedSelectionPhase != "") ? (
              <div className='absolute z-[2] top-0 grid grid-cols-12 space-x-2 bg-amber-100 border-b-4 border-amber-200 h-20 w-full p-2'>
                <div className='col-span-8 lg:col-span-10'>
                  <p className='text-md font-semibold'>Inactive selection phase</p>
                  <p className='line-clamp-2 text-sm'>You are in a completed or not yet started phase of the software selection. To complete the current activities, please return to the active selection phase.</p>
                </div>
                <div className='col-span-4 lg:col-span-2'>
                  <MainButton text={'Resume selection'} icon={<div className='text-white h-6 w-6'><ArrowRightIcon/></div>} link={SELECTION_PHASES_LINKS[selectionPhase]} />
                </div>
              </div>
            ) : (<></>)
          }
          
          <Outlet context={{selectionId, selectionPhase, setSelectionPhase, selectedSelectionPhase, setSelectedSelectionPhase, selectionTaskId, setSelectionTaskId, handleFetchSelectionPhase}} />
        </div>
      </div>
    </div>
    <div className='sm:hidden p-4 h-full flex flex-col justify-center items-center gap-4'>
      <div className='w-10 h-10 text-gray-700'><LockIcon /></div>
      <p className='text-gray-700 text-2xl font-bold text-center'>Sorry.</p>
      <p className='text-gray-700 text-sm font-light text-center'>The selection portal is currently not available for smartphones. For an optimal user experience, please visit the selection portal on a computer or tablet.</p>
      <div className='w-fit'><MainButton text={'Back'} link='/' /></div>
    </div>
    </>
  )
  }
}

export default Index
