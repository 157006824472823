import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { deleteVendorAccount, fetchVendorAccountData } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import DeleteModal from '../../../../../../components/DeleteModal';

interface props {
    openAdd : React.Dispatch<React.SetStateAction<boolean>>;
}

const VendorAccountsTable = ({openAdd} : props) => {
    const { t } = useTranslation();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const [vendorAccountData, setVendorAccountData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    useEffect(() => {
        handleFetchVendorAccountData();
    }, [])

    const handleFetchVendorAccountData = () => {
        setVendorAccountData([]);
        fetchVendorAccountData().then(data => {setVendorAccountData(data.data); setFilteredData(data.data); setLoaded(true);});
    }

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteVendorAccount = async (id: string) => {
        await deleteVendorAccount(id);
        handleFetchVendorAccountData();
        setDeleteModalOpen(false);
        setDeleteItem(undefined);
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteVendorAccount(deleteItem.id)} content={
            <div>
                Soll der Account mit der E-Mail Adresse {deleteItem.email} wirklich gelöscht werden?
            </div>
        } /> : <></>}
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            {t("vendorAccount")}s
            <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length: vendorAccountData.length, itemType: `${t("vendorAccount")}s`})}</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    <SearchBar translatedPlaceholderItem={`${t("vendorAccount")}`} originalData={vendorAccountData} setFilteredData={setFilteredData} filterKey={"email"} />
                </div>
            
            <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
                <button
                onClick={() => handleFetchVendorAccountData()}
                type="button"
                data-refresh
                className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
                </button>
                <button
                onClick={() => openAdd((prev) : boolean => !prev)}
                id="createProductButton"
                className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                type="button"
                data-drawer-target="drawer-create-product-default"
                data-drawer-show="drawer-create-product-default"
                aria-controls="drawer-create-product-default"
                data-drawer-placement="right"
                >
                <PlusIcon iconColor='white' />
                {t("add")}
                </button>
            </div>
            </div>
        </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <TableHeader header={["E-Mail", `${t("vendorCompany")}`, `${t("firstname")}`, `${t("lastname")}`, "Position", `${t("createdOn")}`, ""]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={6} className='h-16'>No vendor accounts available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, email, vendorDetails, createdDate} : any, index : number) => {
                            const date : Date = new Date(Date.parse(createdDate));
                            const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                            return (
                                <tbody>
                                    <TableRow 
                                        rowItems={[index, email, vendorDetails.company, "Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum", dateString]} 
                                        deleteHandler={() => handleOpenDeleteModal({id, email})}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

export default VendorAccountsTable