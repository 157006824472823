import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../../../components/TableHeader';
import NavPagination from '../../../../../../../../components/NavPagination';
import LoadingSpinner from '../../../../../../../../components/LoadingSpinner';
import TableRow from '../../../../../../../../components/TableRow';
import { useTranslation } from 'react-i18next';
import { fetchRequirementCatalogue } from '../../../../../../../../data/SoftwareFulfillmentRequests';
import SearchBar from '../../../../../../../../components/SearchBar';
import RefreshIcon from '../../../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../../../assets/img/icons/PlusIcon';

interface props {
    setRequirementFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedRequirement: React.Dispatch<React.SetStateAction<any>>,
}

const RequirementsTable = ({setRequirementFormOpen, setSelectedRequirement} : props) => {
    const { t } = useTranslation();

    const [ requirementCatalogue, setRequirementCatalogue ] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const handleFetchRequirementCatalogue = () => {
        fetchRequirementCatalogue().then(data => {
            if (data) {
                setRequirementCatalogue(data);
                setFilteredData(data);
                
            }
        })
    }

    useEffect(handleFetchRequirementCatalogue, []);

  return (
    <>
    <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
        {t("requirements")}
    <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length: (typeof requirementCatalogue !== "undefined") ? (requirementCatalogue.length) : (0), itemType: `${t("requirements")}`})}</p>
    </div>
    <div
        className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
    >
    <div className="w-full mb-1">
        <div className="items-center justify-between block sm:flex">
            <div className='sm:w-64 xl:w-96'>
                <SearchBar translatedPlaceholderItem={`${t("requirements")}`} originalData={requirementCatalogue} setFilteredData={setFilteredData} filterKey={"requirement"} />
            </div>
        
        <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
            <button
                onClick={() => handleFetchRequirementCatalogue()}
                type="button"
                data-refresh
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
            </button>
            <button
                onClick={() => {setSelectedRequirement(undefined); setRequirementFormOpen(true);}}
                id="createProductButton"
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                type="button"
                >
            <PlusIcon iconColor='white' />
            {t("add")}
            </button>
        </div>
        </div>
        </div>
    </div>
    <table className="w-full text-sm text-left text-gray-500">


        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <TableHeader header={[`${t("staticReqId")}`, `${t("categorie")} 1`, `${t("requirement")}`]} />
        </thead>
    {
        (typeof filteredData !== "undefined" && filteredData.length > 0) ? (
            filteredData.slice(visibleRows[0]-1,visibleRows[1]).map((obj : any, idx : number) => {
                return (
                    <tbody className='cursor-pointer' onClick={() => {setSelectedRequirement(obj.id); setRequirementFormOpen(true);}}>
                        <TableRow rowItems={[
                            idx, 
                            obj.staticRequirementId, 
                            obj.category,
                            // obj.category_2,
                            // obj.category_3,
                            // obj.category_4,
                            obj.requirement,
                            // obj.top_keywords,
                            // obj.keywords,
                            ]} />
                    </tbody>
                )
            })
        ) : (typeof filteredData !== "undefined" && filteredData.length == 0) ? (
            <p className='p-5'>Keine Anforderungen gespeichert.</p>
        ) : (<LoadingSpinner />)
    }
    </table>
    <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
    </>
  )
}

export default RequirementsTable