import React, { useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import FormModal from '../../../../../../components/FormModal';
import { TextInput } from 'swap-frontend-library';
import { useNavigate } from 'react-router-dom';
import { deleteSelection } from '../../../../../../data/SelectionPortalRequests';

const SelectionDeletion = ({selection} : {selection : any}) => {
    const [deletionActivated, setDeletionActivated] = useState(false);
  return (
    <div className="mt-4 shadow-md rounded-lg w-full bg-white p-5 flex flex-col gap-4">
        <div className="text-lg font-semibold text-left text-gray-900 bg-white">
            Delete selection
        </div>
        <div>
            {
                deletionActivated ? (<SelectionDeletionModal open={deletionActivated} setOpen={setDeletionActivated} selectionId={selection.id} />) : (<></>)
            }

            <div className='w-64'>
                <MainButton additionalStyles={"bg-red-400"} text='Delete' func={() => setDeletionActivated(true)} />
            </div>
            
        </div>
    </div>
  )
}

interface SelectionDeletionModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    selectionId: string
}

const SelectionDeletionModal = ({open, setOpen, selectionId} : SelectionDeletionModalProps) => {
    const [deletionConfimationInput, setDeletionConfirmationInput] = useState("");
    const navigate = useNavigate();

    const handleConfirmDeletion = async () => {
        console.log(deletionConfimationInput)
        if (deletionConfimationInput != "CONFIRM") return
        const response = await deleteSelection(selectionId);
        if (response.ok) {
            navigate("/")
        }
    }

    return (
        <FormModal isOpen={open} setOpen={setOpen}>
            <>
                <h3 className="mb-4 text-xl font-medium text-gray-900">Delete selection project</h3>
                <div className='flex flex-col gap-4 items-center'>
                    <p>The deletion cannot be undone. To confirm this, please enter CONFIRM in the field below and confirm the deletion</p>
                    <TextInput textValue={deletionConfimationInput} setTextValue={setDeletionConfirmationInput} />
                    <div className='w-64'>
                        <MainButton additionalStyles={"bg-red-400"} text='Confirm deletion' func={handleConfirmDeletion} />
                    </div>
                </div>
            </>
        </FormModal>
    )
}

export default SelectionDeletion