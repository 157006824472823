import React, { useEffect, useRef, useState } from 'react'
import { getSelectionDetails, getSelectionLogo, setSelectionCompanyDescription, updateSelectionTitle, uploadSelectionLogo } from '../../../../../../data/SelectionPortalRequests'
import { useOutletContext } from 'react-router-dom';
import { CrossIcon, DropzoneUpload, getImage, LoadingSpinner, UploadIcon } from 'swap-frontend-library';

const SelectionDetails = ({selection, handleGetSelectionDetails} : {selection : any, handleGetSelectionDetails : () => {}}) => {
    const { selectionId } = useOutletContext<any>();
    // const [selection, setSelection] = useState<any>();
    const textbox = useRef<any>();
    const [companyDescription, setCompanyDescription] = useState<string>();
    const [selectionTitle, setSelectionTitle] = useState<string>();
    const [selectionStatus, setSelectionStatus] = useState<string>();
    const [selectionCreatedDate, setSelectionCreatedDate] = useState<string>();
    const [uploadNewLogo, setUploadNewLogo] = useState(false);

    useEffect(() => adjustHeight(), [companyDescription])
    const adjustHeight = () => {
        if (textbox.current == null) return
        textbox.current.style.height = "inherit";
        textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    }
    
    const handleSetSelectionTitle = async () => {
        if (typeof selectionTitle == "undefined") return
        const response = await updateSelectionTitle(selectionId, selectionTitle);
        if (response.ok) {
            handleGetSelectionDetails();
        }
    }
    
    const handleSaveNewSelectionCompanyDescription = async () => {
        if (typeof companyDescription == "undefined") return
        if (await setSelectionCompanyDescription(selectionId, companyDescription)) {
            handleGetSelectionDetails();
        };
    }
    
    const handleUploadSelectionLogo = async (image : File) => {
        if (typeof image == "undefined" || image == null) return
        console.log(image)
        if (await uploadSelectionLogo(selectionId, image, selectionId+"."+image.name.split(".").at(-1))) {
            handleFetchImage();
        };
    }

    const [imageSource, setImageSource] = useState<string | undefined>("");
    
    const handleFetchImage = async () => {
        getSelectionLogo(selection.id, undefined).then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    }

    useEffect(() => {
        handleFetchImage();
        setCompanyDescription(selection.companyDescription);
        setSelectionTitle(selection.selectionTitle);
        setSelectionStatus(selection.selectionStatus);

        const createdDateParsed : Date = new Date(Date.parse(selection.createdDate));
        const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()} ${createdDateParsed.getHours().toString().padStart(2,"0")}:${createdDateParsed.getMinutes().toString().padStart(2,"0")}`;
        setSelectionCreatedDate(createdDateString);
    }, [])

    const editingLocked = false;

    if (typeof selection === "undefined" && typeof companyDescription === "undefined" && typeof selectionTitle === "undefined" && typeof selectionStatus === "undefined" && typeof selectionCreatedDate === "undefined") return <LoadingSpinner />
    return (
        <div className='flex gap-4'>
            <div className="shadow-md rounded-lg w-full bg-white p-5 flex flex-col gap-4">
                <div className="text-lg font-semibold text-left text-gray-900 bg-white">
                    Selection details
                </div>

                <div>
                    <p className='font-semibold'>Project title</p>
                    <input 
                        disabled={editingLocked} 
                        onBlur={() => handleSetSelectionTitle()} 
                        className="w-full rounded tracking-tight leading-6 text-gray-900" 
                        value={selectionTitle != null ? selectionTitle : ""}
                        placeholder="Click here to meeting link."
                        onChange={(e) => setSelectionTitle(e.target.value)}
                    />
                </div>

                <div>
                    <p className='font-semibold'>Company description</p>
                    <textarea 
                        ref={textbox}
                        disabled={editingLocked} 
                        onBlur={() => handleSaveNewSelectionCompanyDescription()} 
                        className="w-full rounded tracking-tight leading-6 text-gray-900" 
                        value={companyDescription != null ? companyDescription : ""}
                        placeholder="Click here to insert your company description."
                        onChange={(e) => setCompanyDescription(e.target.value)}
                    />
                </div>

                <div className='w-full grid grid-cols-2 gap-4'>
                    <div className='w-full'>
                        <p className='font-semibold'>Current phase</p>
                        <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{selection.selectionPhase[0].toUpperCase() + selection.selectionPhase.slice(1)}</div>
                    </div>
                    <div className='w-full'>
                        <p className='font-semibold'>Status</p>
                        <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{selection.selectionStatus[0].toUpperCase() + selection.selectionStatus.slice(1)}</div>
                    </div>
                    <div className='w-full'>
                        <p className='font-semibold'>Started</p>
                        <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{selectionCreatedDate}</div>
                    </div>
                    <div className='w-full'>
                        <p className='font-semibold'>Creation type</p>
                        <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{selection.customerSearchCustomerDetails != null ? ("Created from matching") : ("Created from empty")}</div>
                    </div>
                </div>
            </div>
            <div className="shadow-md rounded-lg w-full bg-white p-5">
                <div className="mb-4 text-lg font-semibold text-left text-gray-900 bg-white">
                    Logo
                </div>
                <div>
                    {
                        typeof imageSource !== "undefined" && !uploadNewLogo ? <img className='h-auto max-h-64 w-full object-contain' src={imageSource} /> :
                        // selection.selectionLogo != null && !uploadNewLogo ? <img className='h-auto max-h-64 w-full object-contain' src={selection.selectionLogo} /> :
                        selection.customerSearchCustomerDetails != null && !uploadNewLogo ? (
                            <>
                            {
                            selection.customerSearchCustomerDetails?.customerSearch.companyLogos.length > 0 ?
                            <img className='h-auto max-h-64 w-full object-contain' src={
                                selection.customerSearchCustomerDetails.customerSearch.companyLogos[0].startsWith("http") ?
                                selection.customerSearchCustomerDetails.customerSearch.companyLogos[0] : "https://"+selection.customerSearchCustomerDetails.customerSearch.companyLogos[0]
                            } /> : <></>
                            }
                            </>
                        ) : <></>
                    }
                    <label htmlFor='input-upload' className="mt-8 cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                        <div className='text-white w-6 h-6'><UploadIcon /></div>
                        Upload
                        <input onChange={(event) => {if (event.target.files) { handleUploadSelectionLogo(event.target.files[0])}}} id='input-upload' type="file" name="file" className="hidden"></input>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default SelectionDetails