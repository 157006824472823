import React, { useState } from 'react'

const LastActivitiesList = () => {
    const [tab, setTab] = useState(1);

    const tabHandler = (tabNum : number) => {
        setTab(tabNum);
    }

    return (
        <div
            className="p-4 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-md sm:p-6"
        >
            <h3
                className="flex items-center mb-4 text-lg font-semibold text-gray-900"
            >
                Activities & Statistics
                <button
                    // data-popover-target="popover-description"
                    // data-popover-placement="bottom-end"
                    type="button"
                    >
                    <svg
                        className="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        ><path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"></path>
                        </svg>
                    {/* <span className="sr-only">Show information</span> */}
                </button>
            </h3>
            {/* <div
                data-popover
                id="popover-description"
                role="tooltip"
                className="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72"
            >
                <div className="p-3 space-y-2">
                    <h3 className="font-semibold text-gray-900">
                        Statistics
                    </h3>
                    <p>
                        Statistics is a branch of applied mathematics that involves the
                        collection, description, analysis, and inference of conclusions from
                        quantitative data.
                    </p>
                    <a
                        href="#"
                        className="flex items-center font-medium text-primary-600 hover:text-primary-700"
                        >Read more <svg
                            className="w-4 h-4 ml-1"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            ><path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </a>
                </div>
                <div data-popper-arrow></div>
            </div> */}
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select tab</label>
                <select
                    id="tabs"
                    className="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 text-sm rounded-t-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                >
                    <option>Statistics</option>
                    <option>Services</option>
                    <option>FAQ</option>
                </select>
            </div>
            <ul
                className="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex"
                id="fullWidthTab"
                data-tabs-toggle="#fullWidthTabContent"
                role="tablist"
            >
                <li className="w-full">
                    <button
                        id="faq-tab"
                        data-tabs-target="#faq"
                        type="button"
                        role="tab"
                        aria-controls="faq"
                        aria-selected="true"
                        className={`inline-block w-full p-4 rounded-tr-lg bg-gray-50 ${(tab == 1) ? ("text-primary") : ("")} hover:bg-gray-100 focus:outline-none`}
                        onClick={() => tabHandler(1)}
                        >Recent Activities
                    </button>
                </li>
                <li className="w-full">
                    <button
                        id="faq-tab"
                        data-tabs-target="#faq"
                        type="button"
                        role="tab"
                        aria-controls="faq"
                        aria-selected="true"
                        className={`inline-block w-full p-4 rounded-tr-lg bg-gray-50 ${(tab == 2) ? ("text-primary") : ("")} hover:bg-gray-100 focus:outline-none`}
                        onClick={() => tabHandler(2)}
                        >Top Vendors
                    </button>
                </li>
                <li className="w-full">
                    <button
                        id="about-tab"
                        data-tabs-target="#about"
                        type="button"
                        role="tab"
                        aria-controls="about"
                        aria-selected="false"
                        className={`inline-block w-full p-4 rounded-tr-lg bg-gray-50 ${(tab == 3) ? ("text-primary") : ("")} hover:bg-gray-100 focus:outline-none`}
                        onClick={() => tabHandler(3)}
                        >Top Customers
                    </button>
                </li>
            </ul>
            <div
                id="fullWidthTabContent"
                className="border-t border-gray-200"
            >
                <div
                    className={`${(tab == 1) ? ("") : ("hidden")} pt-4`}
                    id="about"
                    role="tabpanel"
                    aria-labelledby="about-tab"
                >
                    <ul role="list" className="divide-y divide-gray-200">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/neil-sims.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Process started
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $250
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/bonnie-green.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Market report bought
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $24
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/michael-gough.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Vendor contacted
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $2235
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/thomas-lean.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Selection finished
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $1842
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/lana-byrd.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Contract created
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $1044
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    className={`${(tab == 2) ? ("") : ("hidden")} pt-4`}
                    id="faq"
                    role="tabpanel"
                    aria-labelledby="faq-tab"
                >
                    <ul role="list" className="divide-y divide-gray-200">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center min-w-0">
                                    <img
                                        className="flex-shrink-0 w-10 h-10 rounded-full"
                                        src={'https://via.placeholder.com/50x50?'}
                                        alt="imac image"
                                    />
                                    <div className="ml-3">
                                        <p
                                            className="font-medium text-gray-900 truncate"
                                        >
                                            Vendor A
                                        </p>
                                        <div
                                            className="flex items-center justify-end flex-1 text-sm text-green-500"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    clip-rule="evenodd"
                                                    fill-rule="evenodd"
                                                    d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                                                ></path>
                                            </svg>
                                            2.5%
                                            <span className="ml-2 text-gray-500">vs last month</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $445,467
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center min-w-0">
                                    <img
                                        className="flex-shrink-0 w-10 h-10 rounded-full"
                                        src={'https://via.placeholder.com/50x50?'}
                                        alt="imac image"
                                    />
                                    <div className="ml-3">
                                        <p
                                            className="font-medium text-gray-900 truncate"
                                        >
                                            Vendor A
                                        </p>
                                        <div
                                            className="flex items-center justify-end flex-1 text-sm text-green-500"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    clip-rule="evenodd"
                                                    fill-rule="evenodd"
                                                    d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                                                ></path>
                                            </svg>
                                            12.5%
                                            <span className="ml-2 text-gray-500">vs last month</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $256,982
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center min-w-0">
                                    <img
                                        className="flex-shrink-0 w-10 h-10 rounded-full"
                                        src={'https://via.placeholder.com/50x50?'}
                                        alt="watch image"
                                    />
                                    <div className="ml-3">
                                        <p
                                            className="font-medium text-gray-900 truncate"
                                        >
                                            Vendor A
                                        </p>
                                        <div
                                            className="flex items-center justify-end flex-1 text-sm text-red-600"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    clip-rule="evenodd"
                                                    fill-rule="evenodd"
                                                    d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                                                ></path>
                                            </svg>
                                            1.35%
                                            <span className="ml-2 text-gray-500">vs last month</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $201,869
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center min-w-0">
                                    <img
                                        className="flex-shrink-0 w-10 h-10 rounded-full"
                                        src={'https://via.placeholder.com/50x50?'}
                                        alt="ipad image"
                                    />
                                    <div className="ml-3">
                                        <p
                                            className="font-medium text-gray-900 truncate"
                                        >
                                            Vendor A
                                        </p>
                                        <div
                                            className="flex items-center justify-end flex-1 text-sm text-green-500"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    clip-rule="evenodd"
                                                    fill-rule="evenodd"
                                                    d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                                                ></path>
                                            </svg>
                                            12.5%
                                            <span className="ml-2 text-gray-500">vs last month</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $103,967
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center min-w-0">
                                    <img
                                        className="flex-shrink-0 w-10 h-10 rounded-full"
                                        src={'https://via.placeholder.com/50x50?'}
                                        alt="imac image"
                                    />
                                    <div className="ml-3">
                                        <p
                                            className="font-medium text-gray-900 truncate"
                                        >
                                            Vendor A
                                        </p>
                                        <div
                                            className="flex items-center justify-end flex-1 text-sm text-red-600"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    clip-rule="evenodd"
                                                    fill-rule="evenodd"
                                                    d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                                                ></path>
                                            </svg>
                                            2%
                                            <span className="ml-2 text-gray-500">vs last month</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $98,543
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    className={`${(tab == 3) ? ("") : ("hidden")} pt-4`}
                    id="about"
                    role="tabpanel"
                    aria-labelledby="about-tab"
                >
                    <ul role="list" className="divide-y divide-gray-200">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/neil-sims.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Customer A
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $3320
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/bonnie-green.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Customer A
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $2467
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/michael-gough.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Customer A
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $2235
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/thomas-lean.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Customer A
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $1842
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img
                                        className="w-8 h-8 rounded-full"
                                        src={'/img/demo/users/lana-byrd.png'}
                                        alt="Neil image"
                                    />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="font-medium text-gray-900 truncate">
                                        Customer A
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                        customer@email.com
                                    </p>
                                </div>
                                <div
                                    className="inline-flex items-center text-base font-semibold text-gray-900"
                                >
                                    $1044
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {/* <!-- Card Footer --> */}
            <div className="flex items-center justify-between pt-3 mt-5 border-t border-gray-200 sm:pt-6">
                <div>
                    <button
                        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900"
                        type="button"
                        data-dropdown-toggle="stats-dropdown"
                        >Last 7 days <svg
                            className="w-4 h-4 ml-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            ><path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"></path></svg>
                        </button>
                    {/* <!-- Dropdown menu --> */}
                    <div
                        className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow"
                        id="stats-dropdown"
                    >
                        <div className="px-4 py-3" role="none">
                            <p
                                className="text-sm font-medium text-gray-900 truncate"
                                role="none"
                            >
                                Sep 16, 2021 - Sep 22, 2021
                            </p>
                        </div>
                        <ul className="py-1" role="none">
                            <li>
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem">Yesterday</a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem">Today</a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem">Last 7 days</a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem">Last 30 days</a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem">Last 90 days</a>
                            </li>
                        </ul>
                        <div className="py-1" role="none">
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem">Custom...</a>
                        </div>
                    </div>
                </div>
                <div className="flex-shrink-0">
                    <a
                        href="#"
                        className="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100"
                    >
                        Full Report
                        <svg
                            className="w-4 h-4 ml-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            ><path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5l7 7-7 7"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
  )
}

export default LastActivitiesList