import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowDownIcon from '../../../../assets/img/icons/ArrowDownIcon';

interface props {
    email: string,
    role: string
    align_right?: boolean
}

const UserDropdown = ({email, role, align_right} : props) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
    <>
    <div className='relative'>
        <button onClick={() => {setDropdownOpen(prev => !prev);}} className="flex items-center lg:border-l-2 py-14 px-4 max-w-max text-primary leading-5 max-lg:py-3 max-md:text-sm" type="button">
            User
            <div className='w-4 h-4 mx-1.5'><ArrowDownIcon iconColor='currentColor' /></div>
        </button>

        {
            (dropdownOpen) ? (
                <div className={`absolute ${(align_right) ? ("right-0") : ("")} z-[1001] bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}>
                    <div className="px-4 py-3 text-sm text-gray-900">
                    <div className="font-medium ">{role == "platform_manager" ? t("platformManager") : role == "vendor" ? t("vendor") : t("customer")}</div>
                    <div className="truncate">{email}</div>
                    </div>
                    <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton">
                    <li onClick={(e) => {setDropdownOpen(false)}}>
                        {(role == "platform_manager") ? (
                            <Link onClick={() => setDropdownOpen(false)} to="/user-area" className="block px-4 py-2 hover:bg-gray-100">
                                {t("adminConsole")}
                            </Link>
                        ) : (role == "vendor") ? (
                            <Link onClick={() => setDropdownOpen(false)} to="/user-area" className="block px-4 py-2 hover:bg-gray-100">
                                {t("Dashboard")}
                            </Link>
                        ) : (
                            <Link onClick={() => setDropdownOpen(false)} to="/user-area" className="block px-4 py-2 hover:bg-gray-100">
                                {t("Dashboard")}
                            </Link>
                        )}
                    </li>
                    {/* <li onClick={(e) => {setDropdownOpen(false)}}>
                        <Link to="/user-area/profile-settings" className="block px-4 py-2 hover:bg-gray-100">
                            {t("profileSettings")}
                        </Link>
                    </li> */}
                    </ul>
                    <div onClick={(e) => {setDropdownOpen(false)}}
                    className="py-2">
                        <Link to="/signout" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                            {t("signOut")}
                        </Link>
                    </div>
                </div>
            ) : (
                <div className='hidden' />
            )
        }
    </div>
    {
        (dropdownOpen) ? (
            <div onClick={() => setDropdownOpen(false)}
            className='absolute z-[1000] top-0 left-0 w-screen h-[100dvh]'></div>
        ) : (<></>) 
    }
    </>
  )
}

export default UserDropdown