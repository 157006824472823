import React from 'react'
import { useTranslation } from 'react-i18next'
import LeadsTable from './components/LeadsTable';
import { Outlet } from 'react-router-dom';

const LeadsManager = () => {
    const { t } = useTranslation();
    return (
    <div className='p-4'>
      <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              {t("leads")}
          </h1>
        </div>
        <div>
            <LeadsTable />
        </div>
    </div>
    )
}

export default LeadsManager