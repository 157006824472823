import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import Cookies from 'universal-cookie';

interface props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectionStart = ({setOpen} : props) => {
    const cookies = new Cookies();
    const [ disableModal, setDisableModal ] = useState(false);

    const handleCloseModal = () => {
        console.log("disableModal", disableModal)
        // Expires in 30 day
        if (disableModal) cookies.set('modal-seen:selection-start', true, { path: '/', expires: new Date(Date.now()+(30*86400000)) });
        setOpen(false);
    }
    
    return (
    <div className='min-h-[50vh] flex flex-col justify-between '>
            
        <div className='grow flex items-center'>
            
            <div className='flex flex-col items-center'>
                <img src="/img/graphics/celebration.png" className='h-32 xl:h-64 mb-16' />
                <p className='text-3xl xl:text-5xl font-bold text-center'>Welcome to the selection portal!</p>
                <p className='mt-10 px-16 text-lg text-center'>You've already saved valuable time with us, finding the perfect software match for your company in minutes instead of months.</p>
                <p className='mt-4 px-16 text-lg text-center'>Now, let's fine-tune your requirements together. Invite your team to collaborate, ensuring a comprehensive approach and finding the perfect solution for your needs.</p>

            </div>
            
        </div>

        <div className='my-10 space-y-4 flex flex-col items-center'>
            <div className='gap-2 flex justify-center items-center'>
                {/* <MainButton text={'Tutorial'} additionalStyles='bg-transparent border-2 hover:shadow-amber-400 text-amber-500 border-amber-400 text-gray-900' /> */}
                <MainButton text={'Continue'} func={() => handleCloseModal()} additionalStyles='bg-amber-400 hover:shadow-amber-400' />
            </div>
            <div className='flex items-center space-x-2'>
                <input id='dontShowAgain' type='checkbox' value={String(disableModal)} onChange={() => setDisableModal(prev => !prev)} />
                <label htmlFor='dontShowAgain' className='text-gray-700 cursor-pointer'>Don't show this message again.</label>
            </div>
        </div>

    </div>
  )
}

export default SelectionStart