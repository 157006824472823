import React from 'react'

interface props {
    enabled: boolean,
    setEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    disabled?: boolean;
}

const Toggle = ({enabled, setEnabled, disabled} : props) => {

    return (
        <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled} onChange={(e) => setEnabled(prev => !prev)} disabled={disabled} />
            <div className={`relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] ${disabled ? "after:bg-gray-100" : "after:bg-white"} after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}></div>
        </label>
    )
}

export default Toggle