import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import DeleteModal from '../../../../../../components/DeleteModal';
import InfoIcon from '../../../../../../assets/img/icons/InfoIcon';
import { deleteCustomerDetails, fetchCustomerData } from '../../../../../../data/UserCustomerRequests';
import FormModal from '../../../../../../components/FormModal';
import MainButton from '../../../../../../components/MainButton';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';

interface props {
    openAdd : React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerDetailsTable = ({openAdd} : props) => {
    const { t } = useTranslation();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const [customerData, setCustomerData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const [customerDetailsModalOpen, setCustomerDetailsModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(); 

    const handleFetchCustomerData = () => {
        setCustomerData([]);
        fetchCustomerData().then(data => {setCustomerData(data.data); setFilteredData(data.data); setLoaded(true);});
    }

    useEffect(() => {
        handleFetchCustomerData();
    }, [])

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalValidation, setDeleteModalValidation] = useState("");
    const [deleteModalValidationError, setDeleteModalValidationError] = useState(false);
    const [deleteModalDeletionError, setDeleteModalDeletionError] = useState(0);
    
    const handleOpenDeleteModal = async (deleteItem: any) => {
        if (deleteItem.numVendorLeads > 0) {
            setDeleteModalDeletionError(1);
        } else {
            setDeleteModalDeletionError(0);
        }
        setDeleteModalValidationError(false);
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteCustomer = async (id: string) => {
        if (deleteModalValidation == deleteItem.company && deleteModalDeletionError == 0) {
            if (await deleteCustomerDetails(id)) {
                handleFetchCustomerData();
                setDeleteModalOpen(false);
                setDeleteItem(undefined);
            } else {
                setDeleteModalDeletionError(2);
            }
        } else {
            setDeleteModalValidationError(true);
        }
    }

    const handleOpenCustomerDetailsModal = (customerDetailsId: string) => {
        const customerDetails : any[] = customerData.filter((item : any) => item.id == customerDetailsId);
        
        if (customerDetails.length > 0) {
            const searches = customerDetails[0].customerSearchCustomerDetails.map((item : any) => ({id: item.customerSearch.id, company: item.customerSearch.queryUrl, freeSearch: item.freeSearch}));
            setSelectedCustomer({id: customerDetails[0].id, queryUrl: customerDetails[0].company, searches})
            setCustomerDetailsModalOpen(true);
            console.log(customerDetails[0])
        }

    }

    return (
    <>
        {customerDetailsModalOpen ? (
            <FormModal isOpen={customerDetailsModalOpen} setOpen={setCustomerDetailsModalOpen} size="regular">
                <div>
                    <h3 className="mb-4 text-xl font-medium text-gray-900">{t("customer")}</h3>
                    <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                        <div className='flex items-center'>
                            <div className='w-full'>
                                <label className="block text-sm font-medium text-gray-900">{t("companyName")}</label>
                                <input type="text" name="input" value={selectedCustomer.queryUrl} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled></input>
                            </div>
                        </div>
                        
                        <div className="relative mt-5 shadow-md sm:rounded-lg">
                            <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                                {t("selections")}
                                <p className="mt-1 text-sm font-normal text-gray-500">Der Kunde hat nach diesen URLs gesucht.</p>
                            </div>
                            <table className="w-full text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <TableHeader header={[`${t("queryUrl")}`, `${t("freeSearch")}`]} />
                                </thead>
                                {
                                    selectedCustomer.searches.map(({id, company, freeSearch} : any, index : number) => {
                                        return (
                                            <tbody className="cursor-pointer" onClick={() => window.open(`/search/result?customerSearchId=${id}`)}>
                                                <TableRow
                                                    rowItems={[`${index}`, company, `${freeSearch ? "1": "0"}`]}
                                                />
                                            </tbody>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </FormModal>
        ) : (<></>)}

        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteCustomer(deleteItem.id)} disabled={deleteModalDeletionError > 0} content={
            <div>
                <p className='mb-3'>Soll der Anbieter {deleteItem.company} wirklich gelöscht werden?</p>
                <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                    <InfoIcon iconColor='currentColor' />
                    <div className='ml-3'>
                        <span className="font-medium">Achtung! </span>Damit werden auch zugehörige <span className="font-medium">Accounts</span> und <span className="font-medium">Software-Suchen</span> gelöscht.
                    </div>
                </div>
                <p className='mb-3'>Im den Löschvorgang zu bestätigen, geben Sie unten den Anbieternamen ein ({deleteItem.company}).</p>
                <input placeholder={deleteItem.company} onChange={(event) => {setDeleteModalValidation(event.target.value)}} type="text" name="input" 
                className={`${(deleteModalValidationError) ? ("border-red-500") : ("border-secondary6")} mt-3 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} />
                {deleteModalDeletionError == 1 ? (<div className='w-full'><p className='text-xs italic text-rose-500 text-center'>Löschen nicht möglich. Kunde gehört zu Lead. Bitte löschen Sie zuerst das Lead.</p></div>) : (<></>)}
                {deleteModalDeletionError == 2 ? (<div className='w-full'><p className='text-xs italic text-rose-500 text-center'>Löschen fehlgeschlagen.</p></div>) : (<></>)}
            </div>
        } /> : <></>}

    <div className="relative mt-5 shadow-md sm:rounded-lg">
        
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            {t("customers")}
            <p className="mt-1 text-sm font-normal text-gray-500">{t("totalItems", {length: customerData.length, itemType: `${t("customers")}`})}</p>
        </div>
        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
            <div className="w-full mb-1">
                <div className="items-center justify-between block flex max-sm:flex-col">
                    <div className='max-sm:w-full xl:w-96'>
                        <SearchBar translatedPlaceholderItem={`${t("companyName")}`} originalData={customerData} setFilteredData={setFilteredData} filterKey={"company"} />
                    </div>
                
                <div className="flex items-center h-full max-sm:w-full">
                    <button
                        onClick={() => handleFetchCustomerData()}
                        type="button"
                        data-refresh
                        className="inline-flex max-sm:w-full items-center justify-center h-full w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                        >
                        <RefreshIcon iconColor='white' />
                        {t("refresh")}
                    </button>
                </div>
                </div>
            </div>
        </div>
        {
            (!loaded) ? (<LoadingSpinner />) : (
                <>
                <table className="w-full overflow-x-auto text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <TableHeader header={[`${t("companyName")}`, `${t("customerAccounts")}`, `${t("searches")}`, `${t("feeSearches")}`, `${t("paidSearches")}`, `${t("partOfLeads")}`, `${t("createdOn")}`, ""]} />
                    </thead>
                    {
                        (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={7} className='h-16'>No customers available...</td></tr></tbody>) : (
                            filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, company, numUsers, totalSearches, numFreeSearches, numPaidSearches, numVendorLeads, createdDate} : any, index : number) => {
                                const date : Date = new Date(Date.parse(createdDate));
                                const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                                return (
                                    <tbody className='cursor-pointer' onClick={() => {handleOpenCustomerDetailsModal(id)}}>
                                        <TableRow 
                                            rowItems={[index, company, String(numUsers), String(numFreeSearches+numPaidSearches), String(numFreeSearches), String(numPaidSearches), String(numVendorLeads),  dateString]} 
                                            deleteHandler={() => handleOpenDeleteModal({id, numVendorLeads, company})}
                                        />
                                    </tbody>
                                )
                            })
                        )
                    }
                </table>
                {/* <NavPagination totalItems={1000} visibleRows={[0,10]} setVisibleRows={undefined} handleChange={() => {}} /> */}
                <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
                </>
            )
        }
    </div>
    </>
  )
}

export default CustomerDetailsTable