import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { DownloadIcon, TextInput } from 'swap-frontend-library'
import { RootState } from '../../../../../../../../redux/store';
import { getPresentationEvaluationUser, getPresentationEvaluationUserForDownload, updatePresentationDetails } from '../../../../../../../../data/SelectionPortalRequests';

const SidebarDetails = ({presentationId, meetingItem, handleGetSelectionPresentation} : {presentationId : string, meetingItem : any, handleGetSelectionPresentation : () => {}}) => {
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const textbox = useRef<HTMLTextAreaElement>(null);
  const [agenda, setAgenda] = useState<string | null>(meetingItem.agenda);
  const [videoconferenceLink, setVideoconferenceLink] = useState<string | null>(meetingItem.videoconferenceLink);
  const [videoconferencePassword, setVideoconferencePassword] = useState<string | null>(meetingItem.videoconferencePassword);
  const [presentationStatus, setPresentationStatus] = useState<string | null>(meetingItem.presentationStatus);
  const editingLocked = projectRole != "selection_admin";

  useEffect(() => adjustHeight(), [meetingItem.agenda])
  const adjustHeight = () => {
    if (textbox.current == null) return
    textbox.current.style.height = "inherit";
    textbox.current.style.height = `${textbox.current.scrollHeight}px`;
  }

  const handleSaveDetails = async () => {
    await updatePresentationDetails(presentationId, agenda, videoconferenceLink, videoconferencePassword, presentationStatus);
    handleGetSelectionPresentation();
  }

  useEffect(() => {
    if (presentationStatus == meetingItem.presentationStatus) return
    handleSaveDetails();
  }, [presentationStatus])

  const downloadTxtFile = (csvString: string) => {
    const element = document.createElement("a");
    const file = new Blob([csvString], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = "scenario_evaluation.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const handleDownloadEvaluation = async () => {
    const response = await getPresentationEvaluationUserForDownload(presentationId);
    if (response.ok) {
      downloadTxtFile(response.data.data)
    }
  }

  return (
    <div className='flex flex-col gap-4 w-96'>
        {
          (agenda == null || agenda == "") && projectRole != "selection_admin" ? <></> : (
            <>
              <p className='font-semibold'>Agenda</p>
              <textarea 
                ref={textbox}
                disabled={editingLocked} 
                onBlur={() => handleSaveDetails()} className="w-full rounded tracking-tight leading-6 text-gray-900" 
                value={agenda != null ? agenda : ""}
                placeholder="Click here to insert your agenda."
                onChange={(e) => setAgenda(e.target.value)}
              />
            </>
          )
        }

        <div>
          {
            (videoconferenceLink == null || videoconferenceLink == "") && projectRole != "selection_admin" ? <></> : (
            <div className='mt-4'>
              <p className='font-semibold'>Videoconference link</p>
              <input 
                disabled={editingLocked} 
                onBlur={() => handleSaveDetails()} className="w-full rounded tracking-tight leading-6 text-gray-900" 
                value={videoconferenceLink != null ? videoconferenceLink : ""}
                placeholder="Click here to meeting link."
                onChange={(e) => setVideoconferenceLink(e.target.value)}
              />
            </div>
            )
          }
          {
            (videoconferencePassword == null || videoconferencePassword == "") && projectRole != "selection_admin" ? <></> : (  
            <div className='mt-4'>
              <p className='font-semibold'>Videoconference password</p>
              <input 
                disabled={editingLocked} 
                onBlur={() => handleSaveDetails()} className="w-full rounded tracking-tight leading-6 text-gray-900" 
                value={videoconferencePassword != null ? videoconferencePassword : ""}
                placeholder="Click here to insert meeting password."
                onChange={(e) => setVideoconferencePassword(e.target.value)}
              />
            </div>
            )
          }

          {
            projectRole == "selection_admin" ? (
            <div className='mt-4'>
              <p className='font-semibold'>Presentation status</p>
              <select 
                value={meetingItem.presentationStatus}
                onChange={(event) => setPresentationStatus(event.target.value)}
                id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
                <option value={"planned"}>Planned</option>
                <option value={"live"}>Live</option>
                <option value={"completed"}>Completed</option>
                <option value={"canceled"}>Canceled</option>
              </select>
            </div>
            ) : <></>
          }
          
          <div className='mt-4'>
            <p className='font-semibold'>Download own evaluation</p>
            <div 
            onClick={() => handleDownloadEvaluation()}
            className='mt-2 w-10 h-10 p-2 rounded-full bg-gray-100 hover:bg-primary hover:text-white active:translate-y-2 transition-all cursor-pointer'>
              <DownloadIcon />
            </div>
          </div>

        </div>

    </div>
  )
}

export default SidebarDetails