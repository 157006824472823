import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface UserState {
    loggedIn: boolean | undefined;
    role: string;
    email: string;
}

const initialState: UserState = {
    loggedIn: undefined,
    role: "",
    email: "",
  };

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state) => {
            if (!state.loggedIn) {
                state.loggedIn = true;
                console.log("User successfully logged in.");
            } else {
                console.log("User already logged in.");
            }
        },
        logout: (state) => {
            if (state.loggedIn || typeof state.loggedIn === "undefined") {
                state.loggedIn = false;
                state.email = "";
                state.role = ""
                console.log("User logged out.");
            } else {
                console.log("User already logged out.");
            }
        },
        setRole: (state, role: PayloadAction<string>) => {
            state.role = role.payload;
        },
        setEmail: (state, email: PayloadAction<string>) => {
            state.email = email.payload;
        },
    }
})

export const { login, logout, setEmail, setRole } = userSlice.actions;

export default userSlice.reducer;
