import React, { useEffect } from 'react';
import MainButton from '../../../../components/MainButton';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { logout } from '../../../../redux/userSlice';

import { logoutUser } from '../../../../data/AuthRequests';

import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Cookies from 'universal-cookie';

const Index = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log(process.env.REACT_APP_SELECTION_PORTAL)
    useEffect(() => {
        logoutUser().then(res => {if(res) (dispatch(logout()))});
    }, [])
    
    return (
    <div className='w-full h-[100dvh] flex items-center justify-center sm:pt-20'>
        <div className="sm:shadow-card rounded-lg sm:p-20 container px-6 sm:w-2/3 text-center">
            <h1 className="text-5xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40 mb-8">{t("successfullyLoggedOut")}</h1>
            <p className=''>{t("thankYouMessage")}</p>
            <p>{t("contactSupport")}</p>
            <div className="text-center mt-8">
                <h2 className="text-lg font-medium">{t("continueUsingServices")}</h2>
                <div className='mx-auto max-w-max flex gap-4'>
                    <MainButton link='/signin' text={`${t("signIn")}`} />
                    <MainButton text={`Startseite`} func={() => {window.location.href = `${process.env.REACT_APP_LANDING_PAGE}`}} style='light'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index