import React, { useEffect, useState } from 'react'
import { fetchFactConfig, fetchUpdateFactConfig } from '../../../../../../../data/MatchingConfigRequests';
import PlusIcon from '../../../../../../../assets/img/icons/PlusIcon';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner';
import TableHeader from '../../../../../../../components/TableHeader';
import TableRow from '../../../../../../../components/TableRow';
import MainButton from '../../../../../../../components/MainButton';

const Index = () => {
  const [ factConfig, setFactConfig ] = useState<any>();

  useEffect(() => {
    handleFetchFactConfig();
  }, [])

  const handleFetchFactConfig = () => {
    fetchFactConfig().then(data => {
      if (data) {
        setFactConfig(data.questions);
      }
    })
  }

  const handleUpdateQuestions = (e : any, key: string, data: string) => {
    let temp = factConfig;
    if (data != "active") {
      temp[key][data] = e.target.value ;
    } else {
      temp[key][data] = Number(!factConfig[key][data]);
    }
    setFactConfig({...temp});
  }

  const handleAddNewQuestion = () => {
    const max_key = Math.max(...Object.keys(factConfig).map(((item:any, idx:number) => Number(item))));

    if (factConfig[max_key].information == "" || factConfig[max_key].question == "") {
      return;
    }

    const new_information = {information: "", question: ""};
    setFactConfig((prev : any) => ({...prev, [max_key+1]: new_information}));
  }

  const handleSaveQuestions = async () => {
    await fetchUpdateFactConfig(factConfig);
    handleFetchFactConfig();
  };

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">

          <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            Basis-Informationen
            <p className="mt-1 text-sm font-normal text-gray-500">Wählen Sie welche der folgenden Basis-Informationen über die Unternehmen extrahiert und für das Matching berücksichtigt werden sollen.</p>
          </div>
          <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200">

            <ul>
              {
                (typeof factConfig === "undefined") ? (<></>) : (
                Object.keys(factConfig).map((key: string, index: number) => {

                  const fact : any = factConfig[key];

                  if (!fact.basic) {
                    return;
                  }

                  return (
                    <li key={`option-${index}`} className="float-left m-1">
                      <input type="checkbox" id={`option-${index}`} checked={fact.active} onChange={(e) => handleUpdateQuestions(e, key, "active")} className="hidden peer" />
                      <label htmlFor={`option-${index}`} className="inline-flex items-center justify-between w-full p-2 text-gray-500 border rounded-lg bg-gray-50 border-gray-300 text-gray-600 cursor-pointer peer-checked:border-gray-700 peer-checked:text-gray-600 hover:bg-gray-50">
                        <div className="flex flex-col sm:flex-row gap-2">
                          <div className="w-5 h-5"><PlusIcon iconColor='gray' /></div>
                          <div className="w-full text-sm">{fact.information}</div>
                        </div>
                      </label>
                    </li>
                  );
                })
              )}
            </ul>
            <div className='float-none clear-both'></div>

          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
          <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            Detail-Informationen
            <p className="mt-1 text-sm font-normal text-gray-500">Editieren Sie die Detail-Informationen die über Unternehmen extrahiert werden und für das Matching berücksichtigt werden sollen oder fügen Sie weitere gewünschte Detail-Informationen hinzu.</p>
          </div>
          <div className="p-4 bg-white border-b space-y-6 border-gray-200 ">
            
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <TableHeader header={["Information", "Details", ""]} />
              </thead>
              {
                  (typeof factConfig === "undefined") ? (<></>) : (
                    Object.keys(factConfig).map((key : any, idx : number) => {
                      const fact : any = factConfig[key];

                      if (fact.basic) {
                        return;
                      }

                      return (
                        <tbody >
                          <tr className="bg-white border-b">
                            <td className="px-6 py-4 w-1/4">
                              <input placeholder={`Wert eingeben...`} value={fact.information} onChange={(e) => handleUpdateQuestions(e, key, "information")} type="text" name="input" className='w-full'/>
                            </td>
                            <td className="px-6 py-4">
                              <input placeholder={`Wert eingeben...`} value={fact.question} onChange={(e) => handleUpdateQuestions(e, key, "question")} type="text" name="input" className='w-full'/>
                            </td>
                            <td className="px-6 py-4 w-1/12">
                              <MainButton text={'Löschen'} link={''} style='light' func={() => {
                                const temp = factConfig;
                                delete temp[key];
                                setFactConfig({...temp})
                              }} />
                            </td>
                          </tr>
                        </tbody>
                      )
                    })
                  )
              }
            </table>
            <MainButton text={'Neue Information hinzufügen'} link={''} style='light' func={handleAddNewQuestion}/>
          </div>
        </div>
      </div>

      <div className='flex mt-6 justify-end'>
        <MainButton text={'Speichern'} link={''} func={handleSaveQuestions}/>

      </div>

    </div>
  )
}

export default Index