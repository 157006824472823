import React, { useState } from 'react';
import Chart from "react-apexcharts";

interface props {
  title: string;
  bigVal: string;
  changeVal: string;
}

const BigNumberSmallChartCard = ({title, bigVal, changeVal} : props) => {
    const [options, setOptions] = useState({
        colors: ['#1A56DB', '#FDBA8C'],
        chart: {
          height: '140px',
          fontFamily: 'Inter, sans-serif',
          foreColor: '#4B5563',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            borderRadius: 3,
          },
        },
        tooltip: {
          shared: false,
          intersect: false,
          style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
          },
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 1,
            },
          },
        },
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent'],
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          floating: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
      });
    const [series, setSeries] = useState([
        {
          name: 'Quantity',
          color: '#1A56DB',
          data: [
            { x: '01 Feb', y: 170 },
            { x: '02 Feb', y: 180 },
            { x: '03 Feb', y: 164 },
            { x: '04 Feb', y: 145 },
            { x: '05 Feb', y: 194 },
            { x: '06 Feb', y: 170 },
            { x: '07 Feb', y: 155 },
          ],
        },
      ],)
  return (
    <div
    className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:flex sm:p-6"
  >
    <div className="w-full">
      <h3 className="text-base font-normal text-gray-500">
        {title}
      </h3>
      <span
        className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl"
        >{bigVal}</span
      >
      <p
        className="flex items-center text-base font-normal text-gray-500"
      >
        <span
          className="flex items-center mr-1.5 text-sm text-green-500"
        >
          <svg
            className="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
            ></path>
          </svg>
          {changeVal}
        </span>
        Since last month
      </p>
    </div>
    <div className="w-full" id="new-products-chart">
      <Chart 
        type='bar'
        options={options}
        series={series}
      />
    </div>
  </div>
  )
}

export default BigNumberSmallChartCard;