import React, { useState } from 'react'
import MainButton from '../../../../../components/MainButton'
import { startNewSelection } from '../../../../../data/SelectionPortalRequests'
import { useNavigate } from 'react-router-dom'
import { ArrowRightIcon, EyeClosedIcon, EyeOpenIcon, TextInput } from 'swap-frontend-library'

interface props {
  customerSearchCustomerDetailsId: string | null
}

const StartSelectionForm = ({customerSearchCustomerDetailsId} : props) => {
  const navigate = useNavigate();

  const [ textInput, setTextInput ] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async () => {
    // Reset the error state
    setError(false);
    
    console.log(customerSearchCustomerDetailsId)
        
    if (textInput) {
      // setError(true);
      let resp = await startNewSelection(customerSearchCustomerDetailsId, textInput);
      
      if (!resp.ok) return setError(true);
      navigate(`/user-area/selection-portal/${resp.selectionId}`);
    }
  };

  return (
    <div>
      <div className='my-8 flex flex-col gap-2'>
        <div className="md:max-w-[800px] flex flex-col items-center text-center md:p-16 p-2">
          <div className="lg:text-4xl text-2xl font-semibold mt-4">
              Welcome to the{" "}
              <span className="lg:text-4xl text-2xl font-semibold px-4 bg-primary text-white rounded-full inline">
                  closed-beta
              </span>{" "}
              of the find-your-SOFTWARE Selection Portal
          </div>
          <p className="lg:text-md lg:mt-16 mt-4 md:mx-12">
              We are pleased that you are interested in testing our product. Please enter the single-use code you have received for activating the find-your-SOFTWARE Selection Portal below. We are looking forward to your feedback.
          </p>
        </div>

        <div className="relative h-20 mt-4 w-full border rounded-full flex items-center justify-between bg-white border-secondary6">
            <input
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        setError(false);
                        handleSubmit();
                    }
                }}
                onChange={(event) => {
                    setError(false);
                    setTextInput(event.target.value);
                }}
                maxLength={4}
                className="pl-4 sm:pr-48 pr-32 h-full w-full bg-transparent rounded-full placeholder:text-secondary4 placeholder:text-sm placeholder:font-normal font-semibold text-lg"
                type={showPassword ? "text" : "password"}
                name="search"
                id="search"
                placeholder="Insert your invite code"
            />
            <div className='absolute right-2 flex items-center gap-2'>
                <button
                    type="button"
                    className="w-2/12 transform text-secondary4"
                    onClick={() => setShowPassword(prev => !prev)}
                >
                    {showPassword ? (
                        <div className='w-[30px] mx-auto'><EyeOpenIcon /></div>
                        ) : (
                        <div className='w-[30px] mx-auto'><EyeClosedIcon /></div>  
                    )}
                </button>

                <button
                  className="h-16 sm:w-36 max-sm:px-2 text-white hover:text-primary3 border border-transparent hover:border-primary3 bg-primary hover:bg-white flex items-center justify-center rounded-full gap-4 font-semibold transition-all"
                  onClick={handleSubmit}
                >
                  Enter
                  <div className="h-6 w-6">
                    <ArrowRightIcon />
                  </div>
                </button>
            </div>
        </div>
        <div className="w-full">
          {error && (
            <p className="text-xs italic text-rose-500 text-center">
              Unfortunately, the invite code you entered does not work. Please try again or send us an e-mail to receive a new invite code.
            </p>
          )}
        </div>

      </div>
      {/* <MainButton text={'Start selection'} link={''} func={handleAddProductData} /> */}
    </div>
  )
}

export default StartSelectionForm