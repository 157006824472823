import React, { useEffect, useState } from 'react'
import ParticipantIcon from '../../presentation-overview/components/ParticipantIcon'
import { updatePresentationParticipant, getUsersSelection, notifyParticipantsViaEmail } from '../../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { CheckmarkIcon, LoadingSpinner, MainButton } from 'swap-frontend-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { socket } from '../../../../../../../../_socket/socket';

const SidebarParticipants = ({participants, presentationId, handleGetSelectionPresentation} : {participants: string[], presentationId: string, handleGetSelectionPresentation: () => {}}) => {
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  const [usersSelection, setUsersSelection] = useState<any[]>();
  const [notified, setNotified] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState<string[]>([]);
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const editingLocked = projectRole != "selection_admin";
  
  const handleFetchUsersSelection = async () => {
    const data = await getUsersSelection(selectionId);
    // console.log(data.data.data)
    setUsersSelection(data.data.data);
  }
  
  const handleAddParticipant = async (userId: string) => {
    if (editingLocked) return;
    const response = await updatePresentationParticipant(presentationId, userId, false);
    if (response.ok) {
      handleGetSelectionPresentation();
    } 
  }
  
  const handleDeleteParticipant = async (userId: string) => {
    if (editingLocked) return;
    const response = await updatePresentationParticipant(presentationId, userId, true);
    if (response.ok) {
      handleGetSelectionPresentation();
    } 
  }
  
  const handleNotifyPresentationParticipantsViaEmail = () => {
    if (editingLocked) return;
    notifyParticipantsViaEmail(presentationId)
    setNotified(true);
  }

  useEffect(() => {
    handleFetchUsersSelection();
  }, [])

  useEffect(() => {
    const handleTrackUserActivity = (data : any) => {
      if (data.joined != null && data.joined) console.log(`Websocket ${data.id} joined presentation ${data.presentationId}`);
      if (data.joined != null && !data.joined) console.log(`Websocket ${data.id} left presentation ${data.presentationId}`);
      socket.emit("get connected users", { presentationId });
    }

    const handleUpdateConnectedUsers = (data : any) => {
      console.log("connected ", data.connectedUsers)
      setConnectedUsers(data.connectedUsers);
    }

    socket.on("someone joined or left presentation", handleTrackUserActivity);
    socket.on("receive connected users", handleUpdateConnectedUsers);

    // socket.emit("test room", { presentationId });
    socket.emit("get connected users", { presentationId });
    
    return () => {
        socket.off("someone joined or left presentation");
    }
  }, [])

  return (
    <div className='flex flex-col gap-4 p-4 w-96'>
      <div className='flex flex-col gap-4 mb-8 overflow-y-auto'>
        {
            participants.sort((a : any, b : any) => (a.user.email < b.user.email ? -1 : 1)).map((participant : any) => {
              return (
                <div onClick={() => handleDeleteParticipant(participant.user.id)}
                className='flex items-center gap-4 hover:bg-gray-50 cursor-pointer rounded-r-full rounded-l-full'>
                    <ParticipantIcon name={participant.user.email.replace("@", " ")} checked={connectedUsers.includes(participant.user.email)} />
                    <p className='font-semibold text-nowrap'>{participant.user.email}</p>
                </div>
              )
          })
        }
        {
            (typeof usersSelection === "undefined") ? <LoadingSpinner /> : (
              usersSelection.filter(selectionUser => !participants.map((participant : any) => participant.user.id).includes(selectionUser.user.id)).sort((a : any, b : any) => (a.user.email < b.user.email ? -1 : 1)).map(selectionUser => (
                  <div onClick={() => handleAddParticipant(selectionUser.user.id)}
                  className='opacity-30 hover:opacity-100 flex items-center gap-4 hover:bg-gray-50 cursor-pointer rounded-r-full rounded-l-full'>
                      <ParticipantIcon name={selectionUser.user.email.replace("@", " ")} checked={false} />
                      <p className='font-semibold text-nowrap'>{selectionUser.user.email}</p>
                  </div>
              ))
            )
        }
      </div>

      {
        (editingLocked) ? <></> : (!notified) ? (
          <MainButton text={'Notify'} func={handleNotifyPresentationParticipantsViaEmail} />
        ) : (
          <div className='text-green-500 font-semibold flex gap-4'>
            <div className='w-6 h-6'><CheckmarkIcon /></div>
            <p>Notifications sent to participants!</p>
          </div>
        )
      }
    </div>
  )
}

export default SidebarParticipants