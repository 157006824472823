import React, { useEffect, useRef, useState } from 'react'
import { getNumberSoftwareProductsInSelection } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../../redux/store';
import { useSelector } from 'react-redux';

interface props {
    selectionId: string
}

const SoftwareProductsWedge = ({selectionId} : props) => {
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const navigate = useNavigate();

    const [totalSoftwareProducts, setTotalSoftwareProducts] = useState<number>();
    const [progressWidth, setProgressWidth] = useState(0);
    const [progressStatusPercent, setProgressStatusPercent] = useState<number>(0);

    const ref = useRef<any>(0);

    const handleSetProgressWidth = () => {
        // console.log('width', ref.current ? ref.current.offsetWidth : 0);
        setProgressWidth(ref.current ? ref.current.offsetWidth : 0);
    }
    useEffect(() => {
        handleSetProgressWidth()
    }, [ref.current]);

    useEffect(() => {
        window.addEventListener('resize', handleSetProgressWidth);
        return () => {
            window.removeEventListener('resize', handleSetProgressWidth);
    };
    }, []);

    const handleFetchData = async () => {
        const response = await getNumberSoftwareProductsInSelection(selectionId);
        if (response.ok) {
            // console.log(response.data.data)
            const numberOfIncludedSoftwareProducts = response.data.data.numberOfIncludedSoftwareProducts;
            const numberOfTotalSoftwareProducts = response.data.data.numberOfTotalSoftwareProducts;
            setProgressStatusPercent(1-numberOfIncludedSoftwareProducts/numberOfTotalSoftwareProducts)
            setTotalSoftwareProducts(numberOfTotalSoftwareProducts)
        }
    }
    
    useEffect(() => {handleFetchData()}, []);

    return (
    <div className="relative flex flex-col px-5 pt-5 pb-2.5 bg-white rounded-lg shadow-sm max-md:max-w-full">
        <div className="text-2xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
            Your remaining software selections
        </div>
        {
            (totalSoftwareProducts == 0) ? (
                <div className='absolute top-0 left-0 z-[2] w-full h-full pt-16 px-2 pb-2'>
                    <div className='w-full h-full bg-white/20 backdrop-blur rounded flex flex-col items-center justify-center'>
                        <p className='text-xl font-semibold text-center'>No systems included in shortlist. Please update shortlist.</p>
                        <div className='w-full flex flex-col items-center mt-4'>
                            <div onClick={() => navigate("vendor-ranking")} className="cursor-pointer transition-all hover:text-white hover:bg-primary border group border-primary p-2 rounded-full shadow bg-white rounded z-10 text-center flex gap-2"><p className='group-hover:text-white text-primary font-bold'>View shortlist</p></div> 
                        </div>
                    </div>
                </div>
                ) : <></>
        }
        <div className="w-full relative h-[250px] gap-5 justify-between mt-12 max-md:flex-wrap max-md:max-w-full">
            <div ref={ref} className={`absolute w-full h-[150px] bg-blue-100`}></div>
            <div style={{width: progressStatusPercent*progressWidth}} className={`absolute transitions-all duration-1000 h-[150px] bg-primary`}></div>
            {
                (typeof totalSoftwareProducts === "undefined") ? (<></>) : (
                    <>
                        {
                            (progressWidth > 0) ? (
                            <>
                            <div style={{borderRightWidth: progressWidth}} className={`absolute w-0 h-0 
                            border-t-[0px] border-t-transparent 
                            border-r-white
                            border-b-[150px] border-b-transparent`}>
                            </div>
                            </>
                            ) : (<></>)
                        }
                        <div className="absolute bottom-12 left-0 text-left text-xs"><p className='text-primary font-bold'>{totalSoftwareProducts}</p><p>systems</p></div>
                        
                        {/* <div style={{left: progressStatusPercent*progressWidth-30}} className='absolute bottom-8 flex flex-col items-center'>
                            <div className="border border-primary p-2 rounded-full shadow bg-white rounded z-10 text-center"><p className='text-primary font-bold'>{totalSoftwareProducts-Math.floor(totalSoftwareProducts*progressStatusPercent)}</p><p>Systems</p></div>
                        </div> */}
                        <div className='absolute z-[1] w-full bottom-10 flex flex-col items-center'>
                            <div onClick={() => navigate("vendor-ranking")} className="cursor-pointer transition-all hover:text-white hover:bg-primary border group border-primary p-2 rounded-full shadow bg-white rounded z-10 text-center flex gap-2"><p className='group-hover:text-white text-primary font-bold'>{totalSoftwareProducts-Math.floor(totalSoftwareProducts*progressStatusPercent)}</p><p>systems in shortlist</p></div> 
                        </div>
                        
                        <div className="absolute bottom-12 right-0 text-right text-xs"><p className='text-primary font-bold'>1</p><p>system</p></div>
                    </>
                )
            }
        </div>
    </div>
  )
}

export default SoftwareProductsWedge