import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { login, setEmail, setRole } from '../../../../redux/userSlice';
import { loginUser } from '../../../../data/AuthRequests';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../components/MainButton';
import EyeOpenIcon from '../../../../assets/img/icons/EyeOpenIcon';
import EyeClosedIcon from '../../../../assets/img/icons/EyeClosedIcon';
import ArrowRightIcon from '../../../../assets/img/icons/ArrowRightIcon';

const Index = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [loginError, setLoginError] = useState(false);
    const [notVerifiedError, setNotVerifiedError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const location = useLocation();

    const submitHandler = async () => {
        setLoginError(false);
        setNotVerifiedError(false);

        const res = await loginUser(inputEmail, inputPassword);
        if (res.success) {
            
            dispatch(login());
            console.log(res);
            dispatch(setRole(res.data.role));
            dispatch(setEmail(res.data.email));

            
            const { redirectTo } = queryString.parse(location.search);
            console.log(redirectTo)
            if (typeof redirectTo === "undefined" || redirectTo == null || redirectTo == "") {
                navigate("/user-area")
            } else {
                window.location.href = `${process.env.PUBLIC_URL}${redirectTo}`;
                // navigate(`${redirectTo}`);
            }
            // history.push(redirectTo == null ? "/apps" : redirectTo);

        } else {
            if (res.status == 400) { setLoginError(true); }
            if (res.status == 401) { setLoginError(true); }
            if (res.status == 403) { setNotVerifiedError(true); }
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <div className='w-full h-[100dvh] flex items-center justify-center sm:pt-20'>
            <div className="sm:shadow-card rounded-lg text-secondary1 px-8 py-16 min-w-[400px] max-md:py-16">
                <h2 className="text-2xl font-semibold text-center">{`${t("welcomeBack")}`}</h2>
                <p className="text-base text-center mt-4 mb-10">{`${t("useEmailSignIn")}`}</p>
                {/* <div className="border-b mb-6">
                    <button className="border rounded-md mb-6 outline-none border-secondary6 flex items-center justify-center gap-4 w-full py-3 text-sm hover:bg-primary2 transition-all ease-in-out duration-300"> 
                        <img src="img/icons/google-icon.svg" alt="" className="w-5"></img>
                        {t("signInGoogle")}
                    </button>
                </div> */}

                <form action="" className="flex flex-col gap-6">
                    <div className="">
                        <input onChange={(event) => {setInputEmail(event.target.value)}}
                        type="text" placeholder="E-Mail" className="w-full text-base outline-none border border-secondary6 bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2"></input>
                    </div>

                    <div className="relative flex">
                        <input onChange={(event) => {setInputPassword(event.target.value)}}
                        type={showPassword ? "text" : "password"} placeholder={`${t("password")}`} className={`w-10/12 text-base outline-none border bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
                        <button
                            type="button"
                            className="absolute w-2/12 top-1/2 right-0 transform -translate-y-1/2 text-secondary4"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? (
                                <div className='w-[30px] mx-auto'><EyeOpenIcon iconColor='currentColor' /></div>
                                ) : (
                                <div className='w-[30px] mx-auto'><EyeClosedIcon iconColor='currentColor' /></div>
                                
                            )}
                        </button>
                    </div>
                    <div className='w-full'>
                        <MainButton text={t("signIn")} icon={<ArrowRightIcon />} link="" func={submitHandler} />
                    </div>
                {loginError ? (<div className='w-full'><p className='text-xs italic text-rose-500 text-center'>{t("correctEmailPassword")}</p></div>) : (<p className='hidden'></p>)}
                {notVerifiedError ? (<div className='w-full'><p className='text-xs italic text-rose-500 text-center'>{t("emailNotVerified")}</p></div>) : (<p className='hidden'></p>)}
                </form>
                {/* <div className="text-base mt-8 text-secondary4">{t("forgotEmailPassword")} <Link className="text-primary" to="#">{t("clickHere")}</Link></div> */}
                {/* <div className="text-base mt-2 text-secondary4">{t("noAccount")} <a href={process.env.REACT_APP_LANDING_PAGE + "/signin"} className="text-primary">{t("clickHere")}</a></div> */}
            </div>
        </div>
  )
}

export default Index;