import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { getSelectionRankingFull } from '../../../../../../data/SelectionPortalRequests';
import { ArrowDownIcon, LoadingSpinner, SearchBarFuzzy } from 'swap-frontend-library';
import Toggle from '../../../../../../components/Toggle';
import SelectionRankingSettingRow from './components/SelectionRankingSettingRow';

const SelectionRankingSettings = () => {
    const { selectionId }  = useOutletContext<any>();
    const [expanded, setExpanded] = useState(false);
    const [vendorRanking, setVendorRanking] = useState<any[]>();
    const [vendorRankingFiltered, setVendorRankingFiltered] = useState<any[]>([]);
    
    const handleFetchRanking = async () => {
        const response = await getSelectionRankingFull(selectionId);
        if (response.ok) {
            setVendorRanking(response.data.data.selectionSoftwareProducts);
        }
    }

    useEffect(() => {
        handleFetchRanking();
    }, [])

    useEffect(() => { 
        if (typeof vendorRanking !== "undefined") setVendorRankingFiltered(vendorRanking);
    }, [vendorRanking])

  return (
    <div className="mt-4 shadow-md rounded-lg w-full bg-white p-5 flex flex-col gap-4">
        <div className="text-lg font-semibold text-left text-gray-900 bg-white">
            Selection ranking
        </div>

        <div className={`${expanded ? '' : 'max-h-96 overflow-hidden'}`}>
            {
                (typeof vendorRankingFiltered === "undefined" || typeof vendorRanking === "undefined") ? (<LoadingSpinner />) : (
                    <>
                    <div>
                        <SearchBarFuzzy translatedPlaceholderItem={'system'} originalData={vendorRanking} setFilteredData={setVendorRankingFiltered} filterKeys={["softwareProduct.productName", "softwareProduct.vendorDetails.company"]} />
                    </div>
                        <div className='relative h-full w-full grid grid-cols-2 gap-x-8 justify-between divide-y'>
                            {
                                vendorRankingFiltered.map((item:any, index:number) => {
                                    return (
                                        <SelectionRankingSettingRow item={item} selectionId={selectionId} handleFetchRanking={handleFetchRanking} />
                                    )
                                })
                            }
                        </div>     
                    </>
                )
            }
        </div>

        <div onClick={() => setExpanded(prev => !prev)} className='flex items-center justify-center border rounded shadow p-1 cursor-pointer'>
            {
                expanded ? (
                    <div className='w-6 h-6 rotate-180'><ArrowDownIcon /></div>
                ) : (
                    <div className='w-6 h-6'><ArrowDownIcon /></div>
                )
            }
        </div>
    </div>
  )
}

export default SelectionRankingSettings