import React, { useContext, useEffect, useState } from 'react'
import BigLineChartFilter from './selection-portal/selection-cockpit/components/charts/BigLineChartFilter'
import LastActivitiesList from './selection-portal/selection-cockpit/components/charts/LastActivitiesList'
import BigNumberSmallChartCard from './selection-portal/selection-cockpit/components/charts/BigNumberSmallChartCard'
import BigBarChartFitler from './selection-portal/selection-cockpit/components/charts/BigBarChartFilter'
import BigRingChartFilter from './selection-portal/selection-cockpit/components/charts/BigRingChartFilter'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import SelectionSelector from './selection-selector/SelectionSelector'
import useSPNavItems from '../hooks/useSPNavItems'
import { Link } from 'react-router-dom'
import { SettingsFilledIcon } from 'swap-frontend-library'
import ClosedBetaSPCodeGen from '../components/ClosedBetaSPCodeGen'

const Dashboard = () => {
  const role = useSelector((state: RootState) => state.user.role);
  const menu_items = useSPNavItems();
  const [settingsItems, setSettingsItems] = useState<{ label: string; link: string; }[]>([]);

  useEffect(() => {
    const items = menu_items.filter(item => item.label == "Settings")[0].roleLink["platform_manager"]
    if (typeof items !== "string" && typeof items !== "undefined") {
      setSettingsItems(items);
    }
  }, [])

  if (role === "customer") {
    return (<SelectionSelector />)
  } else if (role === "platform_manager") {
    return (
      <div className='p-4 overflow-y-auto h-full'>
          <div className="mb-8 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Dashboard
            </h1>
          </div>

          <div className='grid grid-cols-3 max-md:grid-cols-1 gap-4'>
            {
              menu_items.filter(item => item.label != "Settings").map((value: any, index: number) => {
                if (value.topAlign && Object.keys(value.roleLink).includes(role) && value.label != "Settings") {
                  return (
                    <Link  to={value.roleLink[role]} className='w-full h-64 rounded-lg bg-white shadow border flex flex-col items-center justify-center gap-4 text-gray-700 hover:scale-[1.02] active:scale-[0.98] transition-all'>
                      <div className='w-10 h-10 scale-[2] flex items-center justify-center'>{value.icon}</div>
                      <p className='font-semibold text-xl'>{value.label}</p>
                    </Link>
                )
                }
              })
            }
          </div>
          <div className='grid grid-cols-3 max-md:grid-cols-1 gap-4 mt-4 pt-4 border-t'>
            {
              settingsItems.map((value: any, index: number) => {
                return (
                  <Link  to={value.link} className='w-full h-64 rounded-lg bg-white shadow border flex flex-col items-center justify-center gap-4 text-gray-700 hover:scale-[1.02] active:scale-[0.98] transition-all'>
                    <div className='w-10 h-10'><SettingsFilledIcon /></div>
                    <p className='font-semibold text-xl'>{value.label}</p>
                  </Link>
                )
              })
            }
          </div>
          <div className='mt-4 pt-4 border-t'>
            <ClosedBetaSPCodeGen />
          </div>
      </div>
    )
  } else {
    return (
      <div className='p-4'>
          <div className="mb-4 col-span-full xl:mb-2">
              <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                Dashboard
              </h1>
          </div>
  
          <div className="grid gap-4 xl:grid-cols-3">
            <BigLineChartFilter title={"Sales This Week"} />
            <LastActivitiesList />
          </div>
        <div className="grid grid-cols-1 my-4 xl:grid-cols-1 xl:gap-4">
          <div
            className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-3"
          >
            <BigNumberSmallChartCard title="Registered Vendors" bigVal="323" changeVal='+12,4%' />
            <BigNumberSmallChartCard title="Registered Users" bigVal="643" changeVal='+34,7%' />
            <BigNumberSmallChartCard title="Registered Products" bigVal="243" changeVal='+10,7%' />
            
          </div>
        </div>
        <div className="grid grid-cols-1 my-4 xl:grid-cols-1 xl:gap-4">
          <div className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-2">
            <BigBarChartFitler title={"Traffic by user type"} />
            <BigRingChartFilter title={"Traffic by device"} />
          </div>
          </div>
        </div>
    )
  }
}

export default Dashboard