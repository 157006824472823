import React, { useEffect, useRef, useState } from 'react'
import MessageBubble from '../../../../requirement-manager/components/MessageBubble'
import { socket } from '../../../../../../../../_socket/socket'
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';

const SidebarChat = ({messages, presentationId} : {messages:any[], presentationId:string}) => {
    const email = useSelector((state: RootState) => state.user.email);
    const [newMessage, setNewMessage] = useState("");
    const ref = useRef<any>();

    const handleSendMessage = () => {
        socket.emit("send message", { presentationId, chatMessage: {timestamp: new Date(), sender: email, message: newMessage} });
        setNewMessage("");
    }

    useEffect(() => {
        ref.current.scrollIntoView({behaviour: "smooth"});
    }, [messages])
    
    return (
    <div className='relative flex flex-col h-full w-96'>
        <div className='w-full h-full overflow-y-auto space-y-2 py-2'>
            {
                messages.map((chatMessage : any, index:number) =>(
                    <MessageBubble messageEmail={chatMessage.sender} ownEmail={email} text={chatMessage.message} date={chatMessage.timestamp} />        
                ))
            }
            <div ref={ref}></div>
        </div>

        <div className='flex items-center h-20 border-t px-4 bg-white w-full'>
            <div className='w-full'>   
                <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div className="relative">
                    <input 
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => (e.key === 'Enter') ? (handleSendMessage()) : (null)}
                    value={newMessage}
                    className="pr-24 block w-full p-4 text-sm text-gray-900 border border-gray-300 focus:border-gray-500 rounded-lg bg-gray-50" placeholder="Message..." required />
                    <button 
                        onClick={() => handleSendMessage()}
                    type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2">Send</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SidebarChat