import React, { useEffect, useState } from 'react'
import { getSelectionPhaseTasks } from '../../../../../../data/SelectionPortalRequests'
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import { SELECTION_PHASE_TASK_ROUTES } from './NextStepsWidget';
import { useNavigate } from 'react-router-dom';

interface props {
    selectionId: string
}

const TaskListWidget = ({ selectionId } : props) => {
    const [currentPhase, setCurrentPhase] = useState<string | undefined>();
    const [knownTaskList, setKnownTaskList] = useState<any[] | undefined>();
    const [restTaskList, setRestTaskList] = useState<any[] | undefined>();

    const handleGetTaskList = async () => {
        const response = await getSelectionPhaseTasks(selectionId);
        if (response.ok) {
            setCurrentPhase(response.data.data.selectionPhase);
            setKnownTaskList(response.data.data.selection.selectionSelectionPhaseTask);
            setRestTaskList(response.data.data.allSelectionPhaseTasks);
        }
    }

    useEffect(() => {
        handleGetTaskList();
    }, [])

    return (
        <div className="h-full flex flex-col grow self-stretch px-8 py-8 w-full bg-white rounded-lg shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full">
            {
                (typeof knownTaskList === "undefined" || typeof restTaskList === "undefined") ? (<LoadingSpinner />) : (
                    <>
                        <div className="flex gap-3.5 mb-4 justify-between max-md:flex-wrap max-md:max-w-full">
                            <div className="grow text-2xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                                Task list
                            </div>
                            <div className="my-auto text-base font-medium leading-6 text-blue-700 whitespace-nowrap">
                                View all
                            </div>
                        </div>

                        <div className='h-full py-2 border-b border-t overflow-y-auto pr-4'>
                            {
                                knownTaskList.map((item:any, index:number) => <TaskListItem phase={PHASE_DICT[item.selectionPhaseTask.selectionPhase as keyof typeof PHASE_DICT]} link={SELECTION_PHASE_TASK_ROUTES[item.selectionPhaseTask.selectionPhase as keyof typeof SELECTION_PHASE_TASK_ROUTES]} title={item.selectionPhaseTask.title} completed={item.completed} index={index} isActiveTask={true} />)
                            }
                            
                            {
                                restTaskList.map((item:any, index:number) => <TaskListItem phase={PHASE_DICT[item.selectionPhase as keyof typeof PHASE_DICT]} link={SELECTION_PHASE_TASK_ROUTES[item.selectionPhase as keyof typeof SELECTION_PHASE_TASK_ROUTES]} title={item.title} completed={false} index={index} isActiveTask={false} />)
                            }
                            
                        </div>
                    </>
                )
            }
                    

        </div>
  )
}

const PHASE_DICT = {
    requirements: "Requirement phase",
    scenarios: "Scenario phase",
    presentations: "Presentation phase",
    contracts: "Contract phase"
}

const TaskListItem = ({ phase, link, title, completed, index, isActiveTask } : { phase: string, link: string, title: string, completed: boolean, isActiveTask: boolean, index: number }) => {
    const navigate = useNavigate();
    let dateString = ""
    if (isActiveTask) {
        const today = new Date();
        const dueDate = new Date(new Date().setDate(today.getDate() + (index+1)**2))
    
        dateString = `${dueDate.getDate()}.${dueDate.getMonth()+1}.${dueDate.getFullYear()}`;
    }

    // `Due date: ${dateString == "" ? "tbd" : dateString}`

    return (
    <div onClick={() => {if (!completed && isActiveTask) navigate(link)}}
    className={`flex gap-5 justify-between items-center p-3 max-md:flex-wrap max-md:max-w-full hover:bg-gray-50 transition-all rounded-lg border ${!completed && isActiveTask ? "border-primary cursor-pointer" : "border-transparent"} ${!completed && !isActiveTask ? "opacity-40" : ""}`}>
        
        { completed ? <div className='w-6 h-6 rounded-full border border-green-600 p-1 flex items-center justify-center'><CheckmarkIcon /></div> : <div className={`w-6 h-6 rounded-full border ${isActiveTask ? 'border-amber-600 text-amber-600' : 'border-gray-600 text-gray-600'} p-1 flex items-center justify-center`}>{isActiveTask ? "!" : ""}</div> }

        <div className="flex flex-col flex-1 justify-center self-stretch max-md:max-w-full">
            <div className="text-base font-semibold leading-6 text-gray-900 max-md:max-w-full">
                { title }
            </div>
            <div className="text-xs leading-5 text-gray-500 max-md:max-w-full">
                {phase} { completed ? "- Completed" : "" }
            </div>
        </div>

        { !completed && isActiveTask ? <div className='w-6 h-6 rounded-full p-1 flex items-center justify-center'><ArrowRightIcon iconColor='currentColor' /></div> : <></> }

    </div>
    )
}

export default TaskListWidget