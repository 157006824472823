import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../../components/LoadingSpinner';
import TableRow from '../../../../components/TableRow';
import TableHeader from '../../../../components/TableHeader';
import VendorAccountsTable from './object-settings/tables/VendorAccountsTable';
import VendorDetailsTable from './object-settings/tables/VendorDetailsTable';
import VendorInvitesTable from './object-settings/tables/VendorInvitesTable';
import AddVendorDetailsForm from './object-settings/forms/AddVendorDetailsForm';
import AddVendorAccountForm from './object-settings/forms/AddVendorAccountForm';
import AddVendorInviteForm from './object-settings/forms/AddVendorInviteForm.tsx';
import FormModal from '../../../../components/FormModal';

const VendorsAdmin = () => {
    const [vendorDetailsFormOpen, setVendorDetailsFormOpen] = useState(false);
    const [vendorAccountsFormOpen, setVendorAccountsFormOpen] = useState(false);
    const [vendorInvitesFormOpen, setVendorInvitesFormOpen] = useState(false);

    return (
    <div className='h-full overflow-y-auto'>
        {(vendorDetailsFormOpen) ? (<FormModal isOpen={vendorDetailsFormOpen} setOpen={setVendorDetailsFormOpen} size="regular"><AddVendorDetailsForm /></FormModal>) : (<div className="hidden"></div>)}
        {(vendorAccountsFormOpen) ? (<FormModal isOpen={vendorAccountsFormOpen} setOpen={setVendorAccountsFormOpen} size="regular"><AddVendorAccountForm /></FormModal>) : (<div className="hidden"></div>)}
        {(vendorInvitesFormOpen) ? (<FormModal isOpen={vendorInvitesFormOpen} setOpen={setVendorInvitesFormOpen} size="regular"><AddVendorInviteForm /></FormModal>) : (<div className="hidden"></div>)}
        <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
            Vendors
          </h1>
        </div>
        <div>
            {/* Vendor table */}
            <VendorDetailsTable openAdd={setVendorDetailsFormOpen} />

            {/* Vendor users table */}
            <VendorAccountsTable openAdd={setVendorAccountsFormOpen} />

            {/* Vendor invite table */}
            <VendorInvitesTable openAdd={setVendorInvitesFormOpen} />
        </div>
    </div>
  )
}

export default VendorsAdmin;