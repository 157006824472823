import React, { useEffect, useRef, useState } from 'react'
import MessageBubble from './MessageBubble'
import { addRequirementComment, getRequirementComment } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { RootState } from '../../../../../../redux/store';
import { useSelector } from 'react-redux';

interface props {
    requirementId: string
}

const CommentWindow = ({requirementId} : props) => {
    // const ref = useRef<any>();
    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState<any[]>();
    // const [scroll_temporary, setScroll_temporary] = useState(false);
    const email = useSelector((state: RootState) => state.user.email);

    const handleFetchMessages = async () => {
        const response = await getRequirementComment(requirementId);
        if (response.ok) {
            if (typeof messages !== "undefined") {
                console.log(messages.length)
                if (messages.length < response.data.data.length) {
                    // setScroll_temporary(true);
                }
            }
            setMessages(prev => [...response.data.data]);
            // console.log(response);
        }
    }

    useEffect(() => {handleFetchMessages();},[requirementId])
    // useEffect(() => {ref.current.scrollIntoView({ behavior: "smooth" });},[requirementId])
    // useEffect(() => {
    //     if (scroll_temporary) {
    //         ref.current.scrollIntoView({ behavior: "smooth" });
    //         setScroll_temporary(false);
    //     }
    // }, [messages])

    useEffect(() => {
        const interval = setInterval(handleFetchMessages, 5000);
        return () => {
            clearInterval(interval);
        }
    }, [requirementId]);

    const handleSendMessage = async () => {
        // setMessages(prev => [...prev, {userId: "123", userName: "Tim Körppen", text: newMessage, date: "12.02.2024"}]);
        // setNewMessage("");
        const response = await addRequirementComment(requirementId, email, newMessage);
        if (response.ok) {
            setNewMessage("");
            // setScroll_temporary(true);
            await handleFetchMessages();
        }
    }

    

  return (
    <div id="CommentWindow" className='flex flex-col h-full'>
        <div className='grow h-64 overflow-y-auto space-y-4 px-4'>
            { (typeof messages === "undefined") ? (<LoadingSpinner />) : (
                messages.map((message: any, index: number) => {
                    return (
                        <MessageBubble messageEmail={message.user.email} ownEmail={email} text={message.comment} date={message.updatedDate} />
                    )
                }))
            }
            {/* <div ref={ref}></div> */}
        </div>

        <div className='p-4'>   
            <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
            <div className="relative">
                <input 
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => (e.key === 'Enter') ? (handleSendMessage()) : (null)}
                type="search" id="search" value={newMessage}
                className="block w-full p-4 text-sm text-gray-900 border border-gray-300 focus:border-gray-500 rounded-lg bg-gray-50" placeholder="Comment..." required />
                <button onClick={handleSendMessage}
                type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2">Send</button>
            </div>
        </div>
    </div>
  )
}

export default CommentWindow