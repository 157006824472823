import React, { useEffect, useState } from 'react'
import Toggle from '../../../../../../../components/Toggle'
import { changeRankingDisabled, changeRankingInclusion } from '../../../../../../../data/SelectionPortalRequests';
import { LoadingSpinner } from 'swap-frontend-library';

const SelectionRankingSettingRow = ({selectionId, item, handleFetchRanking} : {selectionId: string, item:any, handleFetchRanking: () => {}}) => {
    const [included, setIncluded] = useState<boolean>();
    const [disabled, setDisabled] = useState<boolean>();

    useEffect(() => {
        if (disabled) setIncluded(false);
    }, [disabled])
    
    useEffect(() => {
        setIncluded(item.included);
        setDisabled(item.disabled);
    }, [item])

    const handleChangeInclusion = async (include : boolean | undefined) => {
        if (typeof include === "undefined" || include == item.included) return
        if (await changeRankingInclusion(item.id, include)) {
            await handleFetchRanking();
        }
    }
    
    const handleChangeDisabled = async (disable : boolean | undefined) => {
        // console.log(item.softwareProduct.productName , selectionId, item.softwareFulfillment.id, item.id, disable)
        if (typeof disable === "undefined" || disable == item.disabled) return 
        if (await changeRankingDisabled(selectionId, item.softwareFulfillment.id, item.id, disable)) {
            await handleFetchRanking();
        }
    }

    useEffect( () => {
        handleChangeInclusion(included);
    }, [included])
    
    useEffect( () => {
        if (disabled) handleChangeInclusion(false);
        handleChangeDisabled(disabled);
    }, [disabled])

    return (
    <div className='flex justify-between items-center py-4'>
        <div>
            <div className='flex space-x-4 text-lg font-bold'>
                <p>{item.softwareProduct.productName} </p>
                {/* <p className='font-light italic'>{vendorRankingData.softwareFulfillment.note}</p> */}
            </div>
            <div>
                {item.softwareProduct.vendorDetails.company}
            </div>
        </div>

        {
            typeof included === "undefined" || typeof disabled === "undefined" ? <></> : (
            <div className='flex gap-4'>
                {
                    disabled ? <></> : (
                    <div>
                        <Toggle enabled={included} setEnabled={setIncluded} disabled={disabled} />
                        <p className='text-xs font-light text-center'>Included</p>
                    </div>
                    )
                }
                <div>
                    <Toggle enabled={disabled} setEnabled={setDisabled} />
                    <p className='text-xs font-light text-center'>Disabled</p>
                </div>
            </div>
            )
        }
    </div>
  )
}

export default SelectionRankingSettingRow